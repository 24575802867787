import { PaginationParams } from '@/shared/types';
import { DateRange } from 'react-day-picker';

export abstract class BusinessAreasKey {
  static ALL = ['business-areas'];
  static PAGED = (pagination: PaginationParams) => [
    ...BusinessAreasKey.ALL,
    pagination,
  ];
  static BY_ID = (businessAreaId: number) => [
    ...BusinessAreasKey.ALL,
    'by-id',
    businessAreaId,
  ];
  static BY_COMPANY = (companyId?: number) => [
    ...BusinessAreasKey.ALL,
    'by-company',
    companyId,
  ];

  static BY_COMPANY_PAGED = (
    companyId: number,
    pagination: PaginationParams,
  ) => [...BusinessAreasKey.BY_COMPANY(companyId), pagination];

  static BY_ORGANIZATION = (organizationId: number) => [
    ...BusinessAreasKey.ALL,
    'by-organization',
    organizationId,
  ];

  static BY_ORGANIZATION_PAGED = (
    organizationId: number,
    pagination: PaginationParams,
  ) => [...BusinessAreasKey.BY_ORGANIZATION(organizationId), pagination];
  static BY_USER = (userId: number) => [
    ...BusinessAreasKey.ALL,
    'by-user',
    userId,
  ];

  static BY_USER_PAGED = (userId: number, pagination: PaginationParams) => [
    ...BusinessAreasKey.BY_USER(userId),
    pagination,
  ];

  static ELIGIBLE_PARENT_BUSINESS_AREAS = (
    companyId?: number,
    businessAreaId?: number,
  ) => [
    ...BusinessAreasKey.ALL,
    'eligible-parent-business-areas',
    companyId,
    businessAreaId,
  ];

  static AUTOMATIONS_PERFORMANCE = (
    companyId: number,
    dateRange?: DateRange,
  ) => [
    ...BusinessAreasKey.BY_ID(companyId),
    'automations-performance',
    dateRange,
  ];
}
