import {
  Button,
  Container,
  CustomPageContainer,
  FileUploadIcon,
  Input,
  TrashGreyIcon,
  TrashIcon,
} from '@/components';
import { ChangeTenantModal } from '@/components/ChangeTenantModal';
import { CurrentUserAvatar } from '@/components/CurrentUserAvatar';
import InputWithError from '@/components/InputWithError/InputWithError';
import InputWithTitle from '@/components/InputWithTitle/InputWithTitle';
import { ChangePasswordModal } from '@/components/SideNav/components';
import { Spinner } from '@/components/Spinner';
import { TenantEntry } from '@/components/TenantEntry';
import { TenantContext } from '@/components/TenantProvider';
import { queryClient } from '@/constants/query-client';
import { useGetCurrentUser, useUpdateUser } from '@/modules/Users/queries';
import {
  useDeleteProfileUserPicture,
  useUploadProfileUserPicture,
} from '@/modules/Users/queries/profile-picture';
import { UserKey } from '@/modules/Users/queries/types';
import {
  UserProfileSchema,
  UserProfileSchemaType,
} from '@/modules/Users/types';
import { FILE_TYPES_ACCEPTED } from '@/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const MyAccountInfo = (): ReactElement => {
  const { t } = useTranslation();

  const {
    onTenantIdChange,
    currentSelectedTenant,
    hasMultipleTenants,
    userTenants,
    isLoadingTenantInfo,
  } = useContext(TenantContext);

  const { data: user, isLoading: isLoadingUser } = useGetCurrentUser();

  const { mutate: uploadProfilePicture, isLoading: isUpdatingProfilePicture } =
    useUploadProfileUserPicture();

  const { mutate: deleteProfileImage, isLoading: isDeletingProfilePicture } =
    useDeleteProfileUserPicture();

  const { mutate: updateUser, isLoading: isLoadingUpdateUser } =
    useUpdateUser();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [defaultValues, setDefaultValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);

  const [isChangeTenantModalOpen, setIsChangeTenantModalOpen] = useState(false);

  const handleChangeTenantModalOpen = useCallback(() => {
    setIsChangeTenantModalOpen(true);
  }, []);

  const handleChangeTenantModalClose = useCallback(() => {
    setIsChangeTenantModalOpen(false);
  }, []);

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<UserProfileSchemaType>({
    resolver: zodResolver(UserProfileSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const handleTenantChange = useCallback(
    (tenantId: number) => {
      if (tenantId === currentSelectedTenant?.id) {
        return;
      }
      onTenantIdChange(tenantId);
      toast.success(t('tenant_changed_success'));
    },
    [currentSelectedTenant?.id, onTenantIdChange, t],
  );

  const handleResetFormValues = useCallback(() => {
    setValue('firstName', user?.firstName || '');
    setValue('lastName', user?.lastName || '');
    setValue('email', user?.email || '');
  }, [setValue, user?.email, user?.firstName, user?.lastName]);

  useEffect(() => {
    if (!user) {
      return;
    }

    setDefaultValues({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      email: user.email || '',
    });

    handleResetFormValues();
  }, [user, handleResetFormValues]);

  const [firstName, lastName, email] = watch([
    'firstName',
    'lastName',
    'email',
  ]);

  const hasChanges = useMemo(() => {
    return (
      firstName !== defaultValues?.firstName ||
      lastName !== defaultValues?.lastName ||
      email !== defaultValues?.email
    );
  }, [
    defaultValues?.email,
    defaultValues?.firstName,
    defaultValues?.lastName,
    email,
    firstName,
    lastName,
  ]);

  const handleSuccessRequest = useCallback(
    (toastKeyMsg: string) => {
      queryClient.invalidateQueries(UserKey.CURRENT_USER);
      toast.success(t(toastKeyMsg));
    },
    [t],
  );

  const handleSubmitChanges = useCallback(
    (data: UserProfileSchemaType) => {
      const convertedData = {
        id: user?.id ?? 0,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        status: user?.status,
      };

      updateUser(convertedData, {
        onError: () => {
          toast.error(t('error_updating_user'));
        },
        onSuccess: () => {
          handleSuccessRequest('success_updating_user');
        },
      });
    },
    [handleSuccessRequest, t, updateUser, user?.id, user?.status],
  );

  const handleCloseChangePasswordModal = useCallback(
    () => setOpenChangePasswordModal(false),
    [],
  );

  const handleOpenChangePasswordModal = useCallback(
    () => setOpenChangePasswordModal(true),
    [],
  );

  const handleButtonUploadPictureClick = useCallback(
    () => fileInputRef.current?.click(),
    [],
  );

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];

      const formData = new FormData();
      formData.append('EntityId', (user?.id ?? '').toString());
      formData.append('File', file);

      uploadProfilePicture(formData, {
        onSuccess: () => handleSuccessRequest('success_profile_image_updated'),
        onError: () => toast.error(t('error_updating_profile_image')),
      });
    }
  };

  const handleDeletePictureClick = useCallback(() => {
    if (!user || !user.profileImageFileId) {
      return;
    }

    deleteProfileImage(
      { entityId: user.id, fileId: user.profileImageFileId },
      {
        onSuccess: () => handleSuccessRequest('success_profile_image_deleted'),
        onError: () => toast.error(t('error_deleting_profile_image')),
      },
    );
  }, [deleteProfileImage, handleSuccessRequest, t, user]);

  const isLoadingProfileImage = useMemo(
    () => isUpdatingProfilePicture || isDeletingProfilePicture,
    [isDeletingProfilePicture, isUpdatingProfilePicture],
  );

  const isDisabled = useMemo(() => {
    return isLoadingUser || isLoadingUpdateUser;
  }, [isLoadingUser, isLoadingUpdateUser]);

  const hasImage = useMemo(
    () => !!user?.profileImageFileId,
    [user?.profileImageFileId],
  );

  return (
    <div className="flex w-full flex-col gap-5">
      <CustomPageContainer.Header title={t('my_account_info')} />
      <Container className="flex-row justify-between">
        <div className="flex justify-between">
          <div className="flex items-center gap-5">
            <CurrentUserAvatar size="large" isLoading={isLoadingProfileImage} />
            <Button
              className="h-6 justify-between gap-x-2.5 border-blueNuit p-3 font-black text-blueNuit"
              variant="outline"
              onClick={handleButtonUploadPictureClick}
            >
              <FileUploadIcon />
              {t('upload_profile_picture')}
              <input
                ref={fileInputRef}
                type="file"
                onChange={handleFileChange}
                className="display-none"
                accept={FILE_TYPES_ACCEPTED}
              />
            </Button>
            <Button
              className={`h-6 justify-between gap-x-2.5 ${hasImage ? 'border-black' : 'border-grey'} p-3 font-black`}
              variant="outline"
              onClick={handleDeletePictureClick}
              isDisabled={!hasImage}
            >
              {!hasImage ? <TrashGreyIcon /> : <TrashIcon />}
              {t('delete_profile_picture')}
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-between gap-3">
          {currentSelectedTenant && (
            <TenantEntry tenant={currentSelectedTenant} />
          )}
          {hasMultipleTenants && (
            <Button className="h-14 w-36" onClick={handleChangeTenantModalOpen}>
              {t('change_tenant')}
            </Button>
          )}
        </div>
      </Container>
      <Container>
        <form onSubmit={handleSubmit(handleSubmitChanges)}>
          <div className="mt-2 grid grid-cols-2 gap-4">
            <InputWithTitle title={t('first_name')}>
              <InputWithError
                errorText={
                  errors.firstName?.message
                    ? t(errors.firstName.message)
                    : undefined
                }
                hasError={!!errors.firstName?.message}
              >
                <Input
                  isDisabled={isDisabled}
                  className="w-full bg-white italic"
                  placeHolder={t('first_name')}
                  name="firstName"
                  register={register}
                />
              </InputWithError>
            </InputWithTitle>
            <InputWithTitle title={t('last_name')}>
              <InputWithError
                errorText={
                  errors.lastName?.message
                    ? t(errors.lastName.message)
                    : undefined
                }
                hasError={!!errors.lastName?.message}
              >
                <Input
                  isDisabled={isDisabled}
                  className="w-full bg-white italic"
                  placeHolder={t('last_name')}
                  name="lastName"
                  register={register}
                />
              </InputWithError>
            </InputWithTitle>
            <InputWithTitle title={t('email')}>
              <InputWithError
                errorText={
                  errors.email?.message ? t(errors.email.message) : undefined
                }
                hasError={!!errors.email?.message}
              >
                <Input
                  isDisabled={true}
                  className="w-full bg-white italic"
                  placeHolder={t('email')}
                  name="email"
                  register={register}
                />
              </InputWithError>
            </InputWithTitle>
            <InputWithTitle title={t('password')}>
              <Button
                className="w-fit border-blueNuit px-4 font-black text-blueNuit"
                variant="outline"
                onClick={handleOpenChangePasswordModal}
              >
                {t('change_password')}
              </Button>
            </InputWithTitle>
          </div>
          <div className="mt-8 flex gap-5">
            <Button
              onClick={handleResetFormValues}
              variant="outline"
              className="h-9 w-36"
              isDisabled={!hasChanges}
            >
              {t('discard_changes')}
            </Button>
            <Button
              buttonType="submit"
              className="h-9 w-36"
              isDisabled={!hasChanges || !isValid}
            >
              {t('save_changes')}
              {isDisabled && <Spinner className="ml-4 h-5 w-5" />}
            </Button>
          </div>
        </form>
      </Container>
      <ChangePasswordModal
        isOpen={openChangePasswordModal}
        handleClose={handleCloseChangePasswordModal}
      />
      <ChangeTenantModal
        isOpen={isChangeTenantModalOpen}
        handleSave={handleTenantChange}
        handleClose={handleChangeTenantModalClose}
        isLoading={isLoadingTenantInfo}
        selectedTenantId={currentSelectedTenant?.id}
        tenants={userTenants}
      />
    </div>
  );
};
