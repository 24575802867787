import { usersRepo } from '@/constants/providers';
import { MutationOptions } from '@/core/types/mutation-options';
import { ApiResponse } from '@/shared/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserKey } from './types';

export const useAddAccess = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation<ApiResponse<unknown>, unknown, number>(
    usersRepo.addAccess,
    {
      onSuccess: () => {
        toast.success(t('success_add_access'));
        queryClient.invalidateQueries(UserKey.ALL);
        options.onSuccess?.();
      },
      onError: (error) => {
        toast.error(t('error_changing_access'));
        options.onError?.(error);
      },
      onSettled: options.onSettled,
    },
  );
};
