import { OverflowTooltip, UserAvatar } from '@/components';
import { TableColumn, TableRow } from '@/components/Table/types';
import { nameColumnConfig } from './';

type NameWithAvatarColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
  onClick?: (row: T) => void;
  className?: string;
};

export const nameWithAvatarColumnConfig = <T extends TableRow>({
  options,
  onClick,
  className,
}: NameWithAvatarColumnConfig<T>): TableColumn<T> =>
  nameColumnConfig({
    ...options,
    className: className,
    render: (value, row) => (
      <div
        className="flex items-center gap-2.5 overflow-hidden"
        onClick={() => onClick?.(row)}
      >
        <UserAvatar
          size="small"
          name={value as string}
          imageId={row['profileImageFileId']}
        />
        <OverflowTooltip
          text={value}
          containerClassName="overflow-hidden text-ellipsis"
          textClassName="cursor-pointer overflow-hidden text-ellipsis font-black"
        />
      </div>
    ),
  });
