import { PerformanceCardsContainer } from '@/components/PerformanceCardsContainer';
import { useWorkflowAutomationsPerformance } from '@/modules/Workflows/queries/use-workflow-automations-performance';
import { ReactElement, useState } from 'react';
import { DateRange } from 'react-day-picker';

interface WorkflowPerformanceCardsProps {
  workflowId: number;
}

export const WorkflowPerformanceCards = ({
  workflowId,
}: WorkflowPerformanceCardsProps): ReactElement => {
  const [dateRange, setDateRange] = useState<DateRange>();

  const { data, isLoading } = useWorkflowAutomationsPerformance(
    workflowId,
    dateRange,
  );

  return (
    <PerformanceCardsContainer
      predictedManualTime={data?.predictedManualTime || 0}
      automationRunTime={data?.automationRunTime || 0}
      totalInvestment={data?.totalInvestment || 0}
      totalReturn={data?.totalReturn || 0}
      isLoading={isLoading}
      totalRuns={data?.totalExecutions}
      dateRange={dateRange}
      setDateRange={setDateRange}
    />
  );
};
