import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { CardSkeleton } from '../CardSkeleton';
import { Tooltip } from '../Tooltip';

export interface ContainerProps {
  title?: ReactNode;
  className?: string;
  variant?: 'primary' | 'secondary';
  isLoading?: boolean;
  tooltip?: string;
  actions?: ReactNode;
}

export const Container = ({
  children,
  className,
  title,
  variant = 'primary',
  isLoading,
  tooltip,
  actions,
}: PropsWithChildren<ContainerProps>): ReactElement => {
  return (
    <div
      className={twJoin(
        twMerge('flex min-w-fit flex-col gap-3 rounded-md p-3', className),
        variant === 'primary' ? 'bg-brightGray' : 'bg-transparent',
      )}
    >
      {(!!title || !!actions) && (
        <div className="flex justify-between">
          {title && (
            <Tooltip
              content={tooltip}
              containerClassName="w-fit"
              className="max-w-40"
              enabled={!!tooltip}
            >
              <div className="font-black">{title}</div>
            </Tooltip>
          )}
          {actions}
        </div>
      )}
      {isLoading ? <CardSkeleton /> : children}
    </div>
  );
};
