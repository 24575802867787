import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbItem, Breadcrumbs } from '../Breadcrumbs';
import { Button } from '../Button';
import { ChevronLeft } from '../Icons';

type PageDetailsActionsProps = {
  onBack: () => void;
  breadcrumbs: BreadcrumbItem[];
};

export const PageDetailsActions = ({
  onBack,
  breadcrumbs,
  children,
}: PropsWithChildren<PageDetailsActionsProps>) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col gap-3">
        <Button
          className="items-center gap-1 border border-blueNuit p-1.5 py-1 font-black text-blueNuit"
          variant="outline"
          onClick={onBack}
        >
          <ChevronLeft className="w-2.5" />
          {t('back')}
        </Button>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      {children}
    </div>
  );
};
