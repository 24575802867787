import { Button, Dropdown, SelectDropdownOption } from '@/components';
import { AttachUserToEntityModal } from '@/components/AttachUserToEntityModal';
import { queryClient } from '@/constants';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';
import { useAttachUserOrganization } from '@/modules/Organizations/queries/attach-user-organizations';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { UserKey } from '@/modules/Users/queries/types';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface AttachUserToOrganizationModalProps {
  userId: number;
}

export const AttachUserToOrganizationModal = ({
  userId,
}: AttachUserToOrganizationModalProps): ReactElement => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropdownEntityValue, setDropdownEntityValue] = useState<number>(0);

  const handleModalState = () => setIsOpen(!isOpen);

  const { mutate, isLoading } = useAttachUserOrganization();

  const handleConfirm = (roleId: number) => {
    mutate(
      {
        userProfiles: [
          {
            userId: userId ?? 0,
            profileTypeId: roleId,
          },
        ],
        organizationId: dropdownEntityValue,
      },
      {
        onError: () => {
          toast.error(
            t('error_attaching_user_to_entity', { entity: t('organization') }),
          );
        },
        onSuccess: () => {
          queryClient.invalidateQueries(OrganizationKeys.ALL);
          queryClient.invalidateQueries(UserKey.ALL);
          handleModalState();
          toast.success(
            t('success_attaching_user_to_entity', {
              entity: t('organization'),
            }),
          );
        },
      },
    );
  };

  const { data: organizationsData } = useGetAllOrganizations();
  const entityDropdownOptions = useMemo(
    () =>
      (organizationsData ?? []).map((org) => ({
        label: org.name,
        value: org.id,
      })),
    [organizationsData],
  );

  const handleOnSelectEntityDropdownValue = useCallback(
    (option: SelectDropdownOption<number>) =>
      setDropdownEntityValue(option.value),
    [],
  );

  return (
    <>
      <Button onClick={handleModalState} variant="primary">
        {t('attach_entity', { entity: t('organizations') })}
      </Button>
      <AttachUserToEntityModal
        isOpen={isOpen}
        isLoading={isLoading}
        entityType={'organizations'}
        isConfirmDisabled={!dropdownEntityValue}
        onHandleClose={handleModalState}
        onHandleConfirm={handleConfirm}
      >
        <div className="text-sm font-bold capitalize">
          {t('select_existing_entity', { entity: t('organizations') })}
        </div>
        <Dropdown
          className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
          placeholder={t('select')}
          options={entityDropdownOptions}
          value={dropdownEntityValue}
          onSelect={handleOnSelectEntityDropdownValue}
        />
      </AttachUserToEntityModal>
    </>
  );
};
