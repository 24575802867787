import { Button, Dropdown, SelectDropdownOption } from '@/components';
import { AttachUserToEntityModal } from '@/components/AttachUserToEntityModal';
import { queryClient } from '@/constants';
import {
  CompanyKeys,
  useGetAllCompaniesByOrganization,
} from '@/modules/Companies/queries';
import { useAttachUserCompany } from '@/modules/Companies/queries/attach-user-company';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';
import { UserKey } from '@/modules/Users/queries/types';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface AttachUserToCompanyModalProps {
  userId: number;
}

export const AttachUserToCompanyModal = ({
  userId,
}: AttachUserToCompanyModalProps): ReactElement => {
  const { t } = useTranslation();
  const [dropdownOrganizationValue, setOrganizationDropdownEntityValue] =
    useState<number>();
  const [dropdownCompanyValue, setDropdownCompanyValue] = useState<number>();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleModalState = () => setIsOpen(!isOpen);

  const { mutate, isLoading } = useAttachUserCompany();

  const handleConfirm = (roleId: number) => {
    if (!dropdownCompanyValue) return;
    mutate(
      {
        userProfiles: [
          {
            userId: userId ?? 0,
            profileTypeId: roleId,
          },
        ],
        companyId: dropdownCompanyValue,
      },
      {
        onError: () => {
          toast.error(
            t('error_attaching_user_to_entity', { entity: t('company') }),
          );
        },
        onSuccess: () => {
          queryClient.invalidateQueries(CompanyKeys.ALL);
          queryClient.invalidateQueries(
            UserKey.BY_COMPANY(dropdownCompanyValue),
          );
          handleModalState();
          toast.success(
            t('success_attaching_user_to_entity', {
              entity: t('company'),
            }),
          );
        },
      },
    );
  };

  const { data: organizationData } = useGetAllOrganizations();

  const { data: companiesData } = useGetAllCompaniesByOrganization(
    dropdownOrganizationValue,
  );

  const organizationDropdownOptions = useMemo(
    () => getDropdownArray(organizationData ?? []),
    [organizationData],
  );

  const companiesDropdownOptions = useMemo(
    () => getDropdownArray(companiesData ?? []),
    [companiesData],
  );

  const handleOnSelectOrganizationDropdownValue = useCallback(
    (option: SelectDropdownOption<number>) =>
      setOrganizationDropdownEntityValue(option.value),
    [],
  );

  const handleOnSelectCompanyDropdownValue = useCallback(
    (option: SelectDropdownOption<number>) =>
      setDropdownCompanyValue(option.value),
    [],
  );

  useEffect(() => {
    setDropdownCompanyValue(undefined);
  }, [dropdownOrganizationValue]);

  return (
    <>
      <Button onClick={handleModalState} variant="primary">
        {t('attach_entity', { entity: t('companies') })}
      </Button>
      <AttachUserToEntityModal
        isOpen={isOpen}
        isLoading={isLoading}
        entityType={'companies'}
        onHandleClose={handleModalState}
        onHandleConfirm={handleConfirm}
        isConfirmDisabled={!dropdownCompanyValue}
      >
        <div className="text-sm font-bold capitalize">
          {t('select_existing_entity', { entity: t('organizations') })}
        </div>
        <Dropdown
          className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
          placeholder={t('select')}
          options={organizationDropdownOptions}
          value={dropdownOrganizationValue}
          onSelect={handleOnSelectOrganizationDropdownValue}
        />
        <div className="text-sm font-bold capitalize">
          {t('select_existing_entity', { entity: t('companies') })}
        </div>
        <Dropdown
          className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
          placeholder={t('select')}
          options={companiesDropdownOptions}
          value={dropdownCompanyValue}
          onSelect={handleOnSelectCompanyDropdownValue}
        />
      </AttachUserToEntityModal>
    </>
  );
};
