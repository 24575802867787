import { PerformanceType } from '@/components/PerformanceCard/utils';
import { convertTimeSecondsToString } from '@/utils';
import { roundToDecimal } from './roundToDecimals';

export type CardValueType = 'time' | 'coin';

export type ValueDifference = {
  difference: number;
  timeDifference: string | number;
  performance: PerformanceType;
  efficiency?: number;
};

export const calculateValueDifference = (
  value1: number,
  value2: number,
  type: CardValueType = 'time',
): ValueDifference => {
  const difference = value1 - value2;
  const performance =
    difference > 0
      ? PerformanceType.Good
      : difference < 0
        ? PerformanceType.Bad
        : PerformanceType.Neutral;

  return {
    difference: difference,
    timeDifference:
      type === 'time'
        ? convertTimeSecondsToString(Math.abs(difference))
        : Number(difference),
    performance: performance,
    efficiency: value1 ? +roundToDecimal((difference / value1) * 100) : 0,
  };
};
