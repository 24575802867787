import { queryClient } from '@/constants/query-client';
import { AuthProvider } from 'react-oidc-context';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { App } from './App';
import { VITE_MODE, VITE_SENTRY_DSN } from './constants';
import keycloakConfig from './keycloak';

import './translations/i18n';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { WebStorageStateStore } from 'oidc-client-ts';

if (VITE_MODE !== 'development') {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    environment: VITE_MODE,
    ignoreErrors: ['Non-Error promise rejection captured'],
  });
}

const oidcConfig = {
  authority: `${keycloakConfig.authServerUrl}/realms/${keycloakConfig.realm}`,
  client_id: keycloakConfig.clientId,
  redirect_uri: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

createRoot(document.getElementById('root')!).render(
  <AuthProvider {...oidcConfig}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
      <ToastContainer />
    </React.StrictMode>
  </AuthProvider>,
);
