import { PerformanceType } from '@/components/PerformanceCard/utils';
import { useGetAssociatedTasks } from '@/modules/Automations/queries/use-get-associated-tasks';
import { calculateValueDifference, convertSecondsToTimeString } from '@/utils';
import { roundToDecimal } from '@/utils/roundToDecimals';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export interface PerformanceData {
  id: number;
  name: string;
  labourTimeAutomated: any;
  automationRunTime: any;
  timeEfficiency?: any;
  labourCost: any;
  automationRunCost: any;
  finantialOutcome?: any;
  isParent: boolean;
  timePerformance?: PerformanceType;
  financialPerformance?: PerformanceType;
  rows?: PerformanceData[];
}

export const useGetAssociatedTaskCardsData = (): PerformanceData[] => {
  const { automationId: automationIdParam } = useParams<{
    automationId: string;
  }>();
  const automationId = +automationIdParam!;
  const { data: taskPerformances } = useGetAssociatedTasks(automationId);

  return useMemo(() => {
    if (!taskPerformances || !taskPerformances.length) {
      return [];
    }

    let labourTimeAutomated = 0;
    let automationRunTime = 0;
    let labourCost = 0;
    let automationRunCost = 0;

    let result: PerformanceData[] = [];

    taskPerformances.forEach((task) => {
      const {
        workflowId,
        workflowName,
        predictedManualTimeSeconds,
        runTimeSeconds,
        totalInvestment,
        totalReturn,
        id,
        name,
      } = task;

      const {
        efficiency: taskTimeEfficiency,
        performance: taskTimePerformance,
      } = calculateValueDifference(
        Math.round(predictedManualTimeSeconds ?? 0),
        Math.round(runTimeSeconds ?? 0),
      );

      const {
        difference: taskFinantialOutcome,
        performance: taskFinancialPerformance,
      } = calculateValueDifference(
        totalInvestment ?? 0,
        totalReturn ?? 0,
        'coin',
      );

      let workflowDataPerformance = result.find(
        (data) => data.isParent && data.id === workflowId,
      );

      const mappedTask = {
        id,
        name,
        labourTimeAutomated: convertSecondsToTimeString(
          Number(predictedManualTimeSeconds) || 0,
        ),
        automationRunTime: convertSecondsToTimeString(
          Number(runTimeSeconds) || 0,
        ),
        labourCost: `${roundToDecimal(totalInvestment || 0)}€`,
        automationRunCost: `${roundToDecimal(totalReturn || 0)}€`,
        timeEfficiency: `${roundToDecimal(taskTimeEfficiency || 0)}%`,
        finantialOutcome: `${roundToDecimal(taskFinantialOutcome || 0)}€`,
        timePerformance: taskTimePerformance,
        financialPerformance: taskFinancialPerformance,
        isParent: false,
      };

      labourTimeAutomated += predictedManualTimeSeconds;
      automationRunTime += runTimeSeconds;
      labourCost += totalInvestment;
      automationRunCost += totalReturn;

      if (!workflowDataPerformance) {
        workflowDataPerformance = {
          id: workflowId,
          name: workflowName,
          labourTimeAutomated: predictedManualTimeSeconds,
          automationRunTime: runTimeSeconds,
          labourCost: totalInvestment,
          automationRunCost: totalReturn,
          isParent: true,
          rows: [mappedTask],
        };
        result.push(workflowDataPerformance);
        return;
      }

      workflowDataPerformance.labourTimeAutomated += predictedManualTimeSeconds;
      workflowDataPerformance.automationRunTime += runTimeSeconds;
      workflowDataPerformance.labourCost += totalInvestment;
      workflowDataPerformance.automationRunCost += totalReturn;
      workflowDataPerformance.rows?.push(mappedTask);
    });

    result = result.map((workflow) => {
      const {
        labourTimeAutomated,
        automationRunTime,
        labourCost,
        automationRunCost,
      } = workflow;

      const { efficiency: timeEfficiency, performance: timePerformance } =
        calculateValueDifference(
          Math.round(labourTimeAutomated ?? 0),
          Math.round(automationRunTime ?? 0),
        );

      const {
        difference: finantialOutcome,
        performance: financialPerformance,
      } = calculateValueDifference(
        labourCost ?? 0,
        automationRunCost ?? 0,
        'coin',
      );

      return {
        ...workflow,
        labourTimeAutomated: convertSecondsToTimeString(
          Number(labourTimeAutomated) || 0,
        ),
        automationRunTime: convertSecondsToTimeString(
          Number(automationRunTime) || 0,
        ),
        labourCost: `${roundToDecimal(labourCost || 0)}€`,
        automationRunCost: `${roundToDecimal(automationRunCost || 0)}€`,
        timeEfficiency: `${roundToDecimal(timeEfficiency || 0)}%`,
        finantialOutcome: `${roundToDecimal(finantialOutcome || 0)}€`,
        timePerformance,
        financialPerformance,
      };
    });

    const { efficiency: timeEfficiency, performance: timePerformance } =
      calculateValueDifference(
        Math.round(labourTimeAutomated ?? 0),
        Math.round(automationRunTime ?? 0),
      );

    const { difference: finantialOutcome, performance: financialPerformance } =
      calculateValueDifference(labourCost ?? 0, automationRunCost ?? 0, 'coin');

    const total: PerformanceData = {
      id: 0,
      name: t('total'),
      isParent: true,
      labourTimeAutomated: convertSecondsToTimeString(
        Number(labourTimeAutomated) || 0,
      ),
      automationRunTime: convertSecondsToTimeString(
        Number(automationRunTime) || 0,
      ),
      labourCost: `${roundToDecimal(labourCost || 0)}€`,
      automationRunCost: `${roundToDecimal(automationRunCost || 0)}€`,
      timeEfficiency: `${roundToDecimal(timeEfficiency || 0)}%`,
      finantialOutcome: `${roundToDecimal(finantialOutcome || 0)}€`,
      timePerformance,
      financialPerformance,
    };

    result.push(total);

    return result;
  }, [taskPerformances]);
};
