import { mapPagination } from '@/shared/mappers';
import {
  OrganizationBaseInfo,
  OrganizationModel,
  OrganiztionAutomationsPeformance,
} from '../domain';
import {
  ApiResponseOrganizations,
  OrganizationAutomationPerformanceJson,
  OrganizationBaseInfoJson,
  OrganizationJson,
  PagedOrganizations,
} from '../types';

export const mapOrganizationJsonToOrganizationModel = (
  organizationJson?: OrganizationJson,
): OrganizationModel => ({
  id: organizationJson?.id ?? 0,
  name: organizationJson?.name ?? '',
  description: organizationJson?.description ?? '',
  status: organizationJson?.organizationStatus?.name ?? '',
  usersCount: organizationJson?.usersCount ?? 0,
  companiesCount: organizationJson?.companiesCount ?? 0,
  businessAreasCount: organizationJson?.businessAreasCount ?? 0,
  automationsCount: organizationJson?.automationsCount ?? 0,
  tasksCount: organizationJson?.tasksCount ?? 0,
  processesCount: organizationJson?.processesCount ?? 0,
  workflowsCount: organizationJson?.workflowsCount ?? 0,
  infrastructureCostsCount: organizationJson?.infrastructureCostsCount ?? 0,
  predictedManualTime: organizationJson?.predictedManualTime ?? 0,
  predictedAverageManualTime: organizationJson?.predictedAverageManualTime ?? 0,
  automationRunTime: organizationJson?.automationRunTime ?? 0,
  averageAutomationRunTime: organizationJson?.averageAutomationRunTime ?? 0,
  totalInvestment: organizationJson?.totalInvestment ?? 0,
  totalReturn: organizationJson?.totalReturn ?? 0,
  averageCost: organizationJson?.averageCost ?? 0,
  averageManualCost: organizationJson?.averageManualCost ?? 0,
  profileType: organizationJson?.profileType ?? '',
  canDelete: organizationJson?.canDelete ?? false,
  profileImageFileId: organizationJson?.profileImageFileId,
  totalExecutions: organizationJson?.totalExecutions ?? 0,
});

export const organizationsWithPaginationMapper = (
  organizationJson: ApiResponseOrganizations<PagedOrganizations>,
) => {
  const { data } = organizationJson;
  return {
    items: organizationsMapper(data.organizations.items),
    ...mapPagination(data.organizations),
  };
};

export const organizationsMapper = (
  organizationsJson: Array<OrganizationJson>,
): Array<OrganizationModel> =>
  organizationsJson.map(mapOrganizationJsonToOrganizationModel);

export const organizationBaseInfoMapper = (
  organizationBaseInfoJson: OrganizationBaseInfoJson,
): OrganizationBaseInfo => ({
  id: organizationBaseInfoJson.id ?? 0,
  name: organizationBaseInfoJson.name ?? '',
  description: organizationBaseInfoJson.description ?? '',
  status: organizationBaseInfoJson.status ?? false,
});

export const organizationAutomationsPerformanceMapper = (
  organizationAutomationsPerformanceJson?: OrganizationAutomationPerformanceJson,
): OrganiztionAutomationsPeformance => ({
  automationRunTime:
    organizationAutomationsPerformanceJson?.automationRunTime ?? 0,
  averageCost: organizationAutomationsPerformanceJson?.averageCost ?? 0,
  predictedManualTime:
    organizationAutomationsPerformanceJson?.predictedManualTime ?? 0,
  totalExecutions: organizationAutomationsPerformanceJson?.totalExecutions ?? 0,
  totalInvestment: organizationAutomationsPerformanceJson?.totalInvestment ?? 0,
  totalReturn: organizationAutomationsPerformanceJson?.totalReturn ?? 0,
});
