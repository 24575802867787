import { Button, Modal, SearchAutoComplete } from '@/components';
import { useDebounced } from '@/hooks';
import {
  useGetCurrentUser,
  useGetTenantUsers,
  useUpdateUserAccountAdmin,
} from '@/modules/Users/queries';
import { UserKey } from '@/modules/Users/queries/types';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { List } from './components';

interface AddAdminUserModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const AddAdminUserModal = ({
  isOpen,
  handleClose,
}: AddAdminUserModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounced(searchTerm, 500) as string;

  const [selectedUsers, setSelectedNewAdminUsers] = useState<
    Array<{ id: number; value: string; role?: number }>
  >([]);

  const { data: users, isError } = useGetTenantUsers(
    {
      filter: debouncedSearchTerm,
      isAccountAdmin: false,
    },
    {
      enabled: debouncedSearchTerm?.length !== 0,
    },
  );

  const { data: currUser } = useGetCurrentUser();

  const dropdownUsersOptions = useMemo(() => {
    const filteredUsers = users?.items?.filter(
      (user) =>
        user.id !== currUser?.id &&
        !selectedUsers.some((selectedUser) => selectedUser.id === user.id),
    );
    return (
      filteredUsers?.map((user) => ({
        id: user.id,
        value: `${user.name} - ${user.email}`,
      })) ?? []
    );
  }, [users?.items, currUser?.id, selectedUsers]);

  const { mutate: updateUserAccountAdmin, isLoading } =
    useUpdateUserAccountAdmin();

  useEffect(() => {
    if (isError) {
      toast.error(t('error_getting_users'));
    }
  }, [isError, t]);

  const handleOnClose = useCallback(() => {
    setSelectedNewAdminUsers([]);
    handleClose();
  }, [handleClose]);

  const onSubmit = () => {
    const userIds = selectedUsers.map(({ id }) => id);
    updateUserAccountAdmin(
      { userIds, accountAdmin: true },
      {
        onError: () => {
          toast.error(
            t('error_updating_user_account_admin', {
              action: t('account_admin_add_error_action'),
            }),
          );
        },
        onSuccess: () => {
          handleOnClose();
          queryClient.invalidateQueries(UserKey.ADMIN_USERS);
          toast.success(
            t('success_updating_user_account_admin', {
              action: t('account_admin_add_success_action'),
            }),
          );
        },
      },
    );
  };

  const deleteUser = useCallback((index: number) => {
    setSelectedNewAdminUsers(
      (prevState: Array<{ id: number; value: string }>) =>
        prevState.filter((_, i) => i !== index),
    );
  }, []);

  const handleOnSearchTerm = useCallback((search: string) => {
    setSearchTerm(search);
  }, []);

  const handleOnSelectClick = useCallback(
    (item: { id: number; value: string }) => {
      setSelectedNewAdminUsers(
        (prevState: Array<{ id: number; value: string }>) => [
          ...prevState,
          item,
        ],
      );
      setSearchTerm('');
    },
    [],
  );

  return (
    <Modal
      isOpen={isOpen}
      title={t('new_admin_user')}
      className="h-128 w-128"
      handleClose={handleOnClose}
    >
      <div className="flex h-112 flex-col justify-between">
        <div className="flex flex-col gap-20">
          <div className="mt-5">
            <div className="mb-2 text-sm font-bold capitalize">
              {t('select_user')}
            </div>
            <SearchAutoComplete
              value={searchTerm}
              placeholder={t('select_user')}
              options={dropdownUsersOptions}
              onSelect={handleOnSelectClick}
              onChange={handleOnSearchTerm}
            />
          </div>
          <div className="h-56 overflow-visible overflow-y-auto">
            <List items={selectedUsers} deleteUser={deleteUser} />
          </div>
        </div>
        <div className="flex justify-between">
          <Button
            isDisabled={isLoading}
            onClick={handleOnClose}
            variant="outline"
            className="h-9 w-24"
          >
            {t('cancel')}
          </Button>
          <Button
            isDisabled={isLoading || !selectedUsers.length}
            onClick={onSubmit}
            className="h-9 w-24"
          >
            {t('confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
