import { RefObject, useCallback, useEffect } from 'react';

export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
  shouldListen: boolean,
) => {
  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        shouldListen
      ) {
        callback();
      }
    },
    [callback, shouldListen, ref],
  );

  useEffect(() => {
    if (!shouldListen) {
      return undefined;
    }
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick, shouldListen]);
};
