import { ReactNode } from 'react';

type LabelIconProps = {
  label: string;
  icon: ReactNode;
};

export const LabelIcon = ({ label, icon }: LabelIconProps) => {
  return (
    <div className="flex items-center gap-2.5">
      <span className="font-black text-blueNuit">{label}</span>
      <span className="rounded-md bg-brightGray p-2">{icon}</span>
    </div>
  );
};
