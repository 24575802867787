import { PropsWithChildren, ReactElement } from 'react';
import { SideNavigation } from '..';
import { Header } from './components';

type LoggedLayoutProps = {
  showSideNav?: boolean;
};

export const LoggedLayout = ({
  showSideNav,
  children,
}: PropsWithChildren<LoggedLayoutProps>): ReactElement => {
  return (
    <div className="flex h-screen w-screen overflow-hidden">
      {showSideNav && <SideNavigation />}
      <main className="flex flex-grow flex-col overflow-auto scroll-smooth">
        <Header />
        {children}
      </main>
    </div>
  );
};
