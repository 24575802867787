import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RouterContext } from './router.context';

interface RouterContextValue {
  unauthorizedRedirectTo?: string;
}

export const RouterProvider = ({
  children,
  ...props
}: RouterContextValue & { children: React.ReactNode }) => (
  <Router>
    <RouterContext.Provider value={{ ...props }}>
      {children}
    </RouterContext.Provider>
  </Router>
);
