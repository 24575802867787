import { processesRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { ProcessKey } from './keys';

export const useProcessAutomationsPerformance = (
  processId: number,
  dateRange?: DateRange,
) =>
  useQuery({
    queryKey: ProcessKey.AUTOMATIONS_PERFORMANCE(processId, dateRange),
    queryFn: () =>
      processesRepo.getAutomationsPerformanceById(processId, dateRange),
    cacheTime: 1000 * 60 * 5, // Cache for 5 minutes
  });
