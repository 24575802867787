import { TenantContext } from '@/components';
import { tasksRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { TaskModel } from '../domain';
import { TaskKey } from './keys';

export const useGetTask = (taskId: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<TaskModel>(
    [...TaskKey.BY_ID(taskId), currentSelectedTenant?.id],
    () => tasksRepo.getTask(taskId),
    {
      enabled: !!taskId,
    },
  );
};
