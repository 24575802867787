import { ProcessKey } from '@/modules/Processes/queries';
import { TaskKey, useGetTask, useUpdateTask } from '@/modules/Tasks/queries';
import { TaskSchemaType } from '@/modules/Tasks/types';
import { WorkflowKey } from '@/modules/Workflows/queries';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TaskModal } from '../TaskModal';

interface EditTaskModalProps {
  isOpen: boolean;
  id: number;
  handleClose: () => void;
}

export const EditTaskModal = ({
  isOpen,
  id,
  handleClose,
}: EditTaskModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useUpdateTask();

  const { data: task, isLoading: isLoadingTask } = useGetTask(id);

  const handleConfirm = (data: TaskSchemaType) => {
    const convertedData = new FormData();
    convertedData.append('id', String(data.id ?? task?.id));
    convertedData.append('name', data.taskName ?? task?.name);
    convertedData.append(
      'businessAreaId',
      data.businessAreaId?.toString() ?? task?.businessAreaId,
    );
    convertedData.append(
      'description',
      data.taskDescription ?? task?.description ?? '',
    );
    convertedData.append('period', data.taskPeriodicity ?? task?.period);
    convertedData.append(
      'workload',
      data.labourWorkload?.toString().replace('.', ',') ?? task?.workload,
    );
    convertedData.append(
      'workflowId',
      String(data.workflowId ?? task?.workflowId),
    );
    convertedData.append('taskStageId', '1');
    convertedData.append('status', 'true');
    convertedData.append(
      'workloadTypeId',
      data.labourWorkloadType ?? task?.workloadTypeId,
    );
    convertedData.append('code', task?.code || '');

    const averageCases = data.averageCases ?? task?.averageCases;
    if (averageCases) {
      convertedData.append('averageCases', averageCases.toString());
    }

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_task'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(TaskKey.ALL);
        queryClient.invalidateQueries(WorkflowKey.ALL);
        queryClient.invalidateQueries(ProcessKey.ALL);
        handleClose();
        toast.success(t('success_update_task'));
      },
    });
  };

  return (
    <TaskModal
      handleClose={handleClose}
      type="edit"
      handleOnSubmit={handleConfirm}
      title={t('edit_task')}
      isOpen={isOpen}
      isLoading={isLoading || isLoadingTask}
      taskName={task?.name}
      businessAreaId={task?.businessAreaId}
      workflowId={task?.workflowId}
      organizationId={task?.organizationId}
      companyId={task?.companyId}
      taskDescription={task?.description}
      taskPeriodicity={task?.period}
      labourWorkload={task?.workload}
      labourWorkloadType={task?.workloadType}
      averageCases={task?.averageCases}
    />
  );
};
