import { TenantContext } from '@/components';
import { workflowsRepo } from '@/constants/providers';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { WorkflowKey } from './keys';

export const useGetAllWorkflowsByBusinessArea = (
  businessAreaId: number,
  withProcess: boolean = true,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery<WorkflowModel[]>(
    [
      ...WorkflowKey.BY_BUSINESS_AREA(businessAreaId, withProcess),
      currentSelectedTenant?.id,
    ],
    () =>
      workflowsRepo.getWorkflowsByBusinessAreaId(businessAreaId, withProcess),
    {
      enabled: !!businessAreaId,
    },
  );
};
