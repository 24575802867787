import { PerformanceCardsContainer } from '@/components/PerformanceCardsContainer';
import { useGetCompanyAutomationsPerformance } from '@/modules/Companies/queries/use-get-company-automations-performance';
import { ReactElement, useState } from 'react';
import { DateRange } from 'react-day-picker';

interface CompanyPerformanceCardsProps {
  companyId: number;
}

export const CompanyPerformanceCards = ({
  companyId,
}: CompanyPerformanceCardsProps): ReactElement => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const { data, isLoading } = useGetCompanyAutomationsPerformance(
    companyId,
    dateRange,
  );

  return (
    <PerformanceCardsContainer
      isLoading={isLoading}
      predictedManualTime={data?.predictedManualTime || 0}
      automationRunTime={data?.automationRunTime || 0}
      totalInvestment={data?.totalInvestment || 0}
      totalReturn={data?.totalReturn || 0}
      totalRuns={data?.totalExecutions || 0}
      dateRange={dateRange}
      setDateRange={setDateRange}
    />
  );
};
