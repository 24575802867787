import { SyntheticEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import { SwitchStatus } from '..';
import { TableRow } from '../Table/types';

export const ToggleColumn = <T extends TableRow>({
  onChange,
  value,
  label,
  row,
}: {
  label: string;
  onChange: (checked: boolean, row: T) => void;
  value: boolean;
  row: T;
}) => {
  const handleToggleChange = (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onChange(checked, row);
  };
  return (
    <div
      className="flex items-center gap-2"
      onClick={(event) => event.stopPropagation()}
    >
      <SwitchStatus checked={value} onChange={handleToggleChange} />
      <span
        className={twMerge(
          'text-sm capitalize text-blueNuit',
          !value && 'text-primaryBlue',
        )}
      >
        {label}
      </span>
    </div>
  );
};
