import { ReactElement, ReactNode, useState } from 'react';
import {
  TableColumn,
  TableRow as TableRowInterface,
  TableVariant,
} from '../../types';
import { TableRowCell } from '../TableRowCell';
interface TableRowProps<R extends TableRowInterface> {
  row: R;
  columns: TableColumn<R>[];
  variant: TableVariant;
  onRowClick?: (row: R) => void;
  noOutline?: boolean;
  selected?: boolean;
  showDots?: boolean;
  draggable?: boolean;
  actionCallbackDisassociate?: (id: number) => void;
  actionCallbackEdit?: (id: number) => void;
  renderUnlink?: (id?: number, disabled?: boolean) => ReactNode;
  renderEdit?: (id?: number, disabled?: boolean) => ReactNode;
  highlightRow: boolean;
  disableRow?: boolean;
  depth?: number;
  isTree?: boolean;
  checkDisableRow: (row: { [x: string]: number }) => boolean;
  highlightRows: number[];
  noChildDots?: boolean;
  onRowHoverStyling?: string;
}

export const TableRow = <R extends TableRowInterface>({
  row,
  columns,
  draggable,
  actionCallbackDisassociate,
  actionCallbackEdit,
  renderEdit,
  renderUnlink,
  highlightRow,
  disableRow,
  depth = 0,
  isTree,
  variant,
  noOutline,
  onRowClick,
  selected,
  checkDisableRow,
  highlightRows,
  showDots,
  noChildDots,
  onRowHoverStyling,
}: TableRowProps<R>): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TableRowCell
        row={row}
        cols={columns}
        draggable={draggable}
        highlightRow={highlightRow}
        disableRow={disableRow}
        depth={depth}
        isTree={isTree}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        noOutline={noOutline}
        onRowClick={onRowClick}
        selected={selected}
        variant={variant}
        renderUnlink={renderUnlink}
        onUnlink={actionCallbackDisassociate}
        renderEdit={renderEdit}
        onEdit={actionCallbackEdit}
        showDots={showDots}
        noChildDots={noChildDots}
        onRowHoverStyling={onRowHoverStyling}
      />
      {isOpen &&
        row?.rows?.map((child) => (
          <TableRow
            key={child.id}
            row={child as R}
            columns={columns}
            variant={variant}
            depth={depth + 1}
            onRowClick={onRowClick}
            actionCallbackDisassociate={actionCallbackDisassociate}
            actionCallbackEdit={actionCallbackEdit}
            renderUnlink={renderUnlink}
            renderEdit={renderEdit}
            checkDisableRow={checkDisableRow}
            highlightRows={highlightRows}
            highlightRow={
              !checkDisableRow(row) && highlightRows.includes(row.id as number)
            }
            isTree={isTree}
            noChildDots={noChildDots}
          />
        ))}
    </>
  );
};
