import { infrastructuresRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { InfrastructureCostModel } from '../domain';
import { InfrastructureKeys } from './types';
import { useContext } from 'react';
import { TenantContext } from '@/components';

export const useGetInfrastructureCost = (id: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<InfrastructureCostModel>(
    [InfrastructureKeys.GET, id, currentSelectedTenant?.id],
    () => infrastructuresRepo.getInfrastructureCost(id),
    {
      enabled: !!id,
    },
  );
};
