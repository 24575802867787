type LabelCardProps = {
  label: string;
  value?: string;
};

export const LabelCard = ({ label, value }: LabelCardProps) => {
  return (
    <div className="flex min-w-36 justify-between gap-1 rounded-sm bg-white p-2.5">
      <span className="text-sm font-black">{label}</span>
      <span className="text-sm font-medium">{value}</span>
    </div>
  );
};
