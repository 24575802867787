import { taskMapper } from '@/modules/Tasks/mappers';
import { mapPagination } from '@/shared/mappers';
import { WorkflowAutomationsPeformance, WorkflowModel } from '../domain';
import {
  ApiResponseWorkflows,
  PagedWorkflows,
  WorkflowAutomationsPerformanceJson,
  WorkflowJson,
} from '../types';

export const workflowMapper = (workflowJson: WorkflowJson): WorkflowModel => ({
  id: workflowJson?.id ?? 0,
  name: workflowJson?.name ?? '',
  businessAreaId: workflowJson?.businessAreaId ?? 0,
  status: workflowJson?.status ?? '',
  process: workflowJson?.process ?? '',
  tasksCount: workflowJson?.tasksCount ?? 0,
  processesCount: workflowJson?.processesCount ?? 0,
  runTime: workflowJson?.runTime ?? 0,
  tasks: workflowJson?.tasks?.map(taskMapper) ?? [],
  code: workflowJson?.code ?? '',
  description: workflowJson?.description ?? '',
  processId: workflowJson?.processId ?? 0,
  organizationId: workflowJson?.organizationId ?? 0,
  organization: workflowJson?.organization ?? '',
  company: workflowJson?.company ?? '',
  companyId: workflowJson?.companyId ?? 0,
  businessAreaName: workflowJson?.businessAreaName ?? '',
  processName: workflowJson?.processName ?? '',
  order: workflowJson?.order ?? 0,
  predictedManualTime: workflowJson?.predictedManualTime ?? 0,
  predictedAverageManualTime: workflowJson?.predictedAverageManualTime ?? 0,
  automationRunTime: workflowJson?.automationRunTime ?? 0,
  averageAutomationRunTime: workflowJson?.averageAutomationRunTime ?? 0,
  totalInvestment: workflowJson?.totalInvestment ?? 0,
  totalReturn: workflowJson?.totalReturn ?? 0,
  averageCost: workflowJson?.averageCost ?? 0,
  averageManualCost: workflowJson?.averageManualCost ?? 0,
  lastRunSuccessRate: {
    successes: workflowJson?.executionMetrics?.lastRunTotalSuccessful ?? 0,
    businessErrors:
      workflowJson?.executionMetrics?.lastRunTotalBusinessExceptions ?? 0,
    genericErrors:
      workflowJson?.executionMetrics?.lastRunTotalSystemExceptions ?? 0,
  },
  overallSuccessRate: {
    successes: workflowJson?.executionMetrics?.totalSuccessfulCases ?? 0,
    businessErrors:
      workflowJson?.executionMetrics?.totalBusinessExceptionsCases ?? 0,
    genericErrors:
      workflowJson?.executionMetrics?.totalSystemExceptionsCases ?? 0,
  },
  workflowTimeDistribution: {
    manualTime: workflowJson?.manualTime ?? 0,
    automatedTime: workflowJson?.automatedTime ?? 0,
  },
  estimatedCost: workflowJson?.estimatedCost ?? 0,
  canDelete: workflowJson?.canDelete ?? false,
  totalExecutions: workflowJson?.totalExecutions ?? 0,
});

export const workflowsMapper = (
  workflowsJson: Array<WorkflowJson>,
): Array<WorkflowModel> => workflowsJson.map(workflowMapper);

export const workflowsWithPaginationMapper = (
  workflowsJson: ApiResponseWorkflows<PagedWorkflows>,
) => {
  const { data } = workflowsJson;
  return {
    items: workflowsMapper(data.workflows.items),
    ...mapPagination(data.workflows),
  };
};

export const workflowAutomationsPerformanceMapper = (
  performance: WorkflowAutomationsPerformanceJson,
): WorkflowAutomationsPeformance => ({
  predictedManualTime: performance?.predictedManualTime ?? 0,
  automationRunTime: performance?.automationRunTime ?? 0,
  totalExecutions: performance?.totalExecutions ?? 0,
  totalReturn: performance?.totalReturn ?? 0,
  averageCost: performance?.averageCost ?? 0,
  totalInvestment: performance?.totalInvestment ?? 0,
});
