import { toast } from 'react-toastify';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  EditInfrastructureCostSchema,
  InfrastructureCostJson,
} from '@/modules/Infrastructure/types';
import {
  useGetInfrastructureCost,
  useUpdateInfrastructureCost,
} from '@/modules/Infrastructure/queries';
import { InfrastructureKeys } from '@/modules/Infrastructure/queries/types';
import {
  Button,
  Dropdown,
  FieldInfo,
  Input,
  Modal,
  TextArea,
} from '@/components';
import { EditInfrastructureCostSchemaType } from '@/modules/Infrastructure/types';
import { RecurrenceTypes } from '@/modules/Infrastructure/types/recurrenceTypes';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

interface EditInfrastructureCostModalProps {
  isOpen: boolean;
  id: number;
  handleClose: () => void;
}

export const EditInfrastructureCostModal = ({
  isOpen,
  id,
  handleClose,
}: EditInfrastructureCostModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useUpdateInfrastructureCost();
  const { data: infrastructureCost } = useGetInfrastructureCost(id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<EditInfrastructureCostSchemaType>({
    defaultValues: {
      costTypeId: infrastructureCost?.costTypeId || 0,
      name: infrastructureCost?.name || '',
      amountRecurrence: infrastructureCost?.amountRecurrence || '',
      description: infrastructureCost?.description || '',
    },
    resolver: zodResolver(EditInfrastructureCostSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
  });

  const dropdownCostTypeOptions = getDropdownArray([
    { id: 1, name: 'Virtual Machine' },
    { id: 2, name: 'Physical Server' },
    { id: 3, name: 'UiPath Licenses' },
    { id: 4, name: 'Microsoft Power Automate Licenses' },
    { id: 5, name: 'Other Software License' },
  ]);

  const dropdownRecurrenceOptions = getDropdownArray(
    Object.keys(RecurrenceTypes).map((recurrenceTypes) => ({
      name: t(recurrenceTypes),
      id: recurrenceTypes,
    })),
  );

  const handleConfirm = (data: EditInfrastructureCostSchemaType) => {
    if (!infrastructureCost) return;

    const convertedData: InfrastructureCostJson = {
      id: data.id ?? infrastructureCost?.id ?? 0,
      costTypeId: Number(data.costTypeId) ?? infrastructureCost.costTypeId,
      name: data.name ?? infrastructureCost.name,
      amountRecurrence:
        data.amountRecurrence ?? infrastructureCost.amountRecurrence,
      description: data.description ?? infrastructureCost.description,
      status: true,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_updating_infrastructure_cost'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([InfrastructureKeys.GET]);
        handleClose();
        toast.success(t('success_updating_infrastructure_cost'));
      },
    });
  };

  const onSubmit: SubmitHandler<EditInfrastructureCostSchemaType> = (data) => {
    handleConfirm(data);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('edit_infrastructure_cost')}
      className="h-fit w-128"
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
          <FieldInfo
            title={t('cost_name')}
            errorText={
              errors.name?.message ? t(errors.name.message) : undefined
            }
            hasError={!!errors.name?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('cost_name')}
              name="name"
              register={register}
            />
          </FieldInfo>

          <FieldInfo
            title={t('cost_type')}
            errorText={
              errors.costTypeId?.message
                ? t(errors.costTypeId.message)
                : undefined
            }
            hasError={!!errors.costTypeId?.message}
          >
            <Controller
              name="costTypeId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                  placeholder={t('select_cost_type')}
                  options={dropdownCostTypeOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={Number(value)}
                />
              )}
            />
          </FieldInfo>

          <FieldInfo
            title={t('recurrence')}
            errorText={
              errors.amountRecurrence?.message
                ? t(errors.amountRecurrence.message)
                : undefined
            }
            hasError={!!errors.amountRecurrence?.message}
          >
            <Controller
              name="amountRecurrence"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                  placeholder={t('select_recurrence')}
                  options={dropdownRecurrenceOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>

          <FieldInfo
            title={t('description')}
            errorText={
              errors.description?.message
                ? t(errors.description.message)
                : undefined
            }
            hasError={!!errors.description?.message}
          >
            <TextArea
              isDisabled={isLoading}
              className="h-24 w-full bg-brightGray"
              placeHolder={t('optional')}
              name="description"
              register={register}
            />
          </FieldInfo>
        </div>

        <div className="mt-4 flex justify-between">
          <Button
            isDisabled={isLoading}
            onClick={handleClose}
            variant="outline"
            className="h-9 w-24"
          >
            {t('cancel')}
          </Button>
          <Button
            isDisabled={isLoading}
            buttonType="submit"
            className="h-9 w-24"
          >
            {t('confirm')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
