import {
  add,
  differenceInCalendarDays,
  Duration,
  isToday,
  sub,
} from 'date-fns';

export abstract class DateUtility {
  static today(): Date {
    return new Date();
  }
  static firstDayOfYear(): Date {
    return new Date(new Date().getFullYear(), 0, 1);
  }
  static subtract(date: Date, duraction: Duration): Date {
    return sub(date, duraction);
  }
  static isToday(date: Date): boolean {
    return isToday(date);
  }

  static differenceInDays(to: Date, from: Date): number {
    return differenceInCalendarDays(to, from);
  }

  static isBetween(date: Date, startDate: Date, endDate: Date): boolean {
    return startDate <= date && date <= endDate;
  }

  static isBefore(date: Date, dateToCompare: Date): boolean {
    return this.differenceInDays(date, dateToCompare) <= 0;
  }

  static addDays(date: Date, days: number): Date {
    return add(date, { days });
  }
}
