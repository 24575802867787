import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { companiesRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { CompanyModel } from '../domain';
import { CompanyKeys } from './keys';

export const useGetCompanies = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<CompanyModel[]>,
    any,
    DataWithPagination<CompanyModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<DataWithPagination<CompanyModel[]>>(
    [
      ...CompanyKeys.PAGED({ pageNumber, filter, sortBy, sortOrder }),
      currentSelectedTenant?.id,
    ],
    () => companiesRepo.getCompanies(pageNumber, filter, sortBy, sortOrder),
    { ...options, cacheTime: 1000 * 60 * 5 },
  );
};
