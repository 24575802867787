export enum PerformanceType {
  Good = 'good',
  Bad = 'bad',
  Neutral = 'neutral',
}

export const performanceCardTextColors: Record<PerformanceType, string> = {
  [PerformanceType.Good]: 'text-americanGreen',
  [PerformanceType.Bad]: 'text-redBellPepper',
  [PerformanceType.Neutral]: 'text-trueskyGloxym',
};
