import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { usersRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { UserRateModel } from '../domain/user-rate-model';
import { UserKey } from './types';

export const useGetUserRates = (
  userId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<UserRateModel[]>,
    any,
    DataWithPagination<UserRateModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<DataWithPagination<UserRateModel[]>>(
    [
      ...UserKey.USER_RATES_PAGED(userId, {
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      }),
      currentSelectedTenant?.id,
    ],
    () => usersRepo.getUserRate(userId),
    options,
  );
};
