import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { tasksRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { TaskModel } from '../domain';
import { TaskKey } from './keys';

export const useGetTasksByUser = (
  userId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<TaskModel[]>,
    any,
    DataWithPagination<TaskModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<DataWithPagination<TaskModel[]>>(
    [
      ...TaskKey.BY_USER_PAGED(userId, {
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      }),
      currentSelectedTenant?.id,
    ],
    () =>
      tasksRepo.getTasksByUserId(userId, pageNumber, filter, sortBy, sortOrder),
    options,
  );
};
