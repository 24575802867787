import { processesRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { ProcessModel } from '../domain';
import { ProcessKey } from './keys';
import { TenantContext } from '@/components';
import { useContext } from 'react';

export const useGetProcess = (id: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<ProcessModel>(
    [...ProcessKey.BY_ID(id), currentSelectedTenant?.id],
    () => processesRepo.getProcess(id),
    {
      enabled: !!id,
    },
  );
};
