import { ApiResponse, DataWithPagination } from '@/shared/types';
import { AutomationModel, AutomationOverallPerformanceModel } from '../domain';
import { AutomationTaskPerformance } from '../domain/associated-tasks-model';
import { StageModel } from '../domain/stage-model'; // Assuming there's a StageModel
import { DeleteAutomationDocumentJson, UpdateStageJson } from '../types';

export abstract class AutomationRepository {
  abstract getAllAutomations(
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<AutomationModel[]>>;
  abstract getAutomationStages(): Promise<Array<StageModel>>;
  abstract getAutomation(id: number): Promise<AutomationModel>;
  abstract getAutomationOverallPerformance(
    automationId: number,
  ): Promise<AutomationOverallPerformanceModel>;
  abstract getTasksPerformanceByAutomationId(
    automationId: number,
  ): Promise<Array<AutomationTaskPerformance>>;
  abstract createAutomation(data: FormData): Promise<number>;
  abstract getAutomationsByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<AutomationModel[]>>;
  abstract getAutomationsByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<AutomationModel[]>>;
  abstract getAutomationsByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<AutomationModel[]>>;
  abstract getAutomationsByTask(
    taskId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<AutomationModel[]>>;
  abstract getAutomationsByInfrastructureCost(
    infrastructureCostId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<AutomationModel[]>>;
  abstract updateStage(data: UpdateStageJson): Promise<unknown>;
  abstract addDocument(data: FormData): Promise<unknown>;
  abstract deleteDocument(data: DeleteAutomationDocumentJson): Promise<unknown>;
  abstract enableAutomation(id: number): Promise<ApiResponse<unknown>>;
  abstract disableAutomation(id: number): Promise<ApiResponse<unknown>>;
  abstract deleteAutomation(Id: number): Promise<ApiResponse<unknown>>;
}
