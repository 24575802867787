import { ReactElement, useCallback } from 'react';
import { useGetAssociatedTaskCardsData } from '../../hooks';
import { Table } from '@/components';
import { TableRow } from '@/components/Table/types';
import { useNavigate } from 'react-router-dom';
import { columns } from './utils';
import { t } from 'i18next';

interface AutomationPerformanceCardsProps {
  isLoading?: boolean;
}

export const TaskPerformanceCards = ({
  isLoading,
}: AutomationPerformanceCardsProps): ReactElement => {
  const navigate = useNavigate();
  const tasksByWorkflowId = useGetAssociatedTaskCardsData();

  const navigateToDetails = useCallback(
    (row?: TableRow) => {
      if (!row || !row.id) {
        return;
      }
      const baseUri = row.isParent ? '/workflows' : '/tasks';

      navigate(`${baseUri}/${row.id}`);
    },
    [navigate],
  );

  return (
    <div>
      <div className="mb-8 mt-4 items-center self-end align-middle text-ml font-black text-blueNuit">
        {t('process_automated_tasks')}
      </div>
      <Table
        isLoading={isLoading}
        noOutline
        noChildDots
        isTree
        columns={columns(navigateToDetails)}
        rows={tasksByWorkflowId}
        onRowHoverStyling="transition-colors bg-lightPurple"
      />
    </div>
  );
};
