import {
  AutomationsTab,
  CustomPageContainer,
  ListCheckIcon,
  TabItemProps,
  TableWithFilter,
  UsersTab,
} from '@/components';
import { BreadcrumbItem } from '@/components/Breadcrumbs';
import { LabelIcon } from '@/components/LabelIcon/LabelIcon';
import { PageDetailsActions } from '@/components/PageDetailsActions/PageDetailsActions';
import { TabContainer } from '@/components/TabContainer';
import { TabsWrapper } from '@/components/TabsWrapper';
import { queryClient } from '@/constants';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router/route-paths';
import { AutomationModel } from '@/modules/Automations/domain';
import { getAutomationColumnsNamedMapped } from '@/modules/Automations/pages/AutomationsPage/utils';
import { useGetAutomationsByTask } from '@/modules/Automations/queries';
import { UserModel } from '@/modules/Users/domain';
import { getUsersColumnsNamedMapped } from '@/modules/Users/pages/UsersPage/utils';
import { useGetUsersByTask } from '@/modules/Users/queries';
import { UserKey } from '@/modules/Users/queries/types';
import { HttpStatusCode } from 'axios';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDisableTask, useEnableTask } from '../../queries';
import { useDisassociateUser } from '../../queries/disassociate-user';
import { useGetTask } from '../../queries/get-task';
import { DisassociateUserModel } from '../../types/disassociate-user-model';
import { TaskHeaderRow } from './components/TaskHeaderRow';
import { TaskPerformanceCards } from './components/TaskPerformanceCards';

export const TaskDetailPage = (): ReactElement => {
  const { taskId: taskIdParam } = useParams<{ taskId: string }>();
  const taskId = +taskIdParam!;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: task,
    isLoading: isLoadingTask,
    isError,
    error,
  } = useGetTask(taskId);

  const { mutate } = useDisassociateUser();

  useEffect(() => {
    if (isError && error) {
      if ((error as HttpError<unknown>)?.code === HttpStatusCode.NotFound) {
        navigate(RoutePath.notFound());
      } else {
        toast.error(t('error_get_task_details'));
      }
    }
  }, [isError, error, navigate, t]);

  const [status, setStatus] = useState<boolean>(false);

  useEffect(() => {
    setStatus(task?.status === true);
  }, [task]);

  const { mutate: enableTask } = useEnableTask();
  const { mutate: disableTask } = useDisableTask();

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!task?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        enableTask(task?.id, {
          onError: () => {
            toast.error(t('error_enable_task'));
          },
          onSuccess: () => {
            toast.success(t('success_enable_task'));
          },
        });
      } else {
        disableTask(task?.id, {
          onError: () => {
            toast.error(t('error_disable_task'));
          },
          onSuccess: () => {
            toast.success(t('success_disable_task'));
          },
        });
      }
    },
    [task, enableTask, disableTask, t],
  );

  const tabs: Array<TabItemProps> = useMemo(
    () => [
      { label: t('users'), badgeNumber: task?.usersCount },
      { label: t('automations'), badgeNumber: task?.automationsCount },
    ],
    [t, task],
  );

  const disassociateUserWithTask = useCallback(
    (userId: number) => {
      if (!userId || !taskId) {
        return;
      }
      const convertedData: DisassociateUserModel = {
        userId: userId ?? 0,
        taskId: taskId ? Number(taskId) : 0,
      };

      mutate(convertedData, {
        onError: () => {
          toast.error(t('error_remove_user_from_task'));
        },
        onSuccess: () => {
          toast.success(t('success_remove_user_from_task'));
          queryClient.invalidateQueries(UserKey.BY_TASK(taskId));
        },
      });
    },
    [mutate, t, taskId],
  );

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const tabList = useMemo(() => {
    if (!taskId) {
      return [];
    }
    return [
      {
        index: 0,
        tab: (
          <TabContainer<UserModel>
            parentId={taskId}
            useGetData={useGetUsersByTask}
            getColumnsNamedMapped={getUsersColumnsNamedMapped}
          >
            <UsersTab
              showRole
              taskId={taskId}
              unlinkData={{
                action: disassociateUserWithTask,
                message: t('confirm_user_task_disassociate'),
                canUnlink: true,
              }}
            />
          </TabContainer>
        ),
      },
      {
        index: 1,
        tab: (
          <TabContainer<AutomationModel>
            parentId={taskId}
            useGetData={useGetAutomationsByTask}
            getColumnsNamedMapped={getAutomationColumnsNamedMapped}
          >
            <AutomationsTab canCreate={false} />
          </TabContainer>
        ),
      },
    ];
  }, [disassociateUserWithTask, t, taskId]);

  const breadcrumbs = useMemo<BreadcrumbItem[]>(() => {
    if (!task) return [];
    return [
      {
        label: task.organization,
        href: RoutePath.organizationDetail(task.organizationId.toString()),
      },
      {
        label: task.company,
        href: RoutePath.companyDetail(task.companyId.toString()),
      },
      {
        label: task.businessAreaName,
        href: RoutePath.businessAreaDetail(task.businessAreaId.toString()),
      },
      ...((task.processName && [
        {
          label: task.processName,
          href: RoutePath.processDetail(task.processId.toString()),
        },
      ]) ||
        []),
      ...((task.workflow && [
        {
          label: task.workflow,
          href: RoutePath.workflowDetail(task.workflowId.toString()),
        },
      ]) ||
        []),
      { label: t('tasks'), href: RoutePath.tasks() },
      { label: task.name },
    ];
  }, [task, t]);

  return (
    <CustomPageContainer>
      <PageDetailsActions onBack={routeChange} breadcrumbs={breadcrumbs}>
        <LabelIcon
          label={t('task')}
          icon={<ListCheckIcon className="size-5 text-blueNuit" />}
        />
      </PageDetailsActions>
      <TaskHeaderRow
        task={task}
        handleStatus={handleStatus}
        status={status}
        isLoading={isLoadingTask}
      />
      {task?.automationId && <TaskPerformanceCards taskId={taskId} />}
      <TableWithFilter>
        <TabsWrapper tabs={tabs} tabList={tabList} />
      </TableWithFilter>
    </CustomPageContainer>
  );
};
