import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { automationsRepo } from '@/constants/providers';
import { AutomationModel } from '@/modules/Automations/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { AutomationKey } from './keys';

export const useGetAutomationsByBusinessArea = (
  businessAreaId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<AutomationModel[]>,
    any,
    DataWithPagination<AutomationModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<DataWithPagination<AutomationModel[]>>(
    [
      ...AutomationKey.BY_BUSINESS_AREA_PAGED(businessAreaId, {
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      }),
      currentSelectedTenant?.id,
    ],
    () =>
      automationsRepo.getAutomationsByBusinessArea(
        businessAreaId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
};
