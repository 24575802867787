import { ChangeTenantModal } from '@/components/ChangeTenantModal';
import { TENANT_ID_SELECTED, TenantContext } from '@/components/TenantProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

export const TenantPicker = () => {
  const auth = useAuth();
  const {
    onTenantIdChange: onTenantIdContextChange,
    userTenants,
    isLoadingUserTenants,
  } = useContext(TenantContext);

  const { i18n } = useTranslation();

  useEffect(() => {
    const locale = auth.user?.profile['locale'];

    if (locale) {
      i18n.changeLanguage(locale);
    }
  }, [i18n, auth.user]);

  const [selectedTenantId, setSelectedTenantId] = useState<number | undefined>(
    sessionStorage.getItem(TENANT_ID_SELECTED)
      ? +sessionStorage.getItem(TENANT_ID_SELECTED)!
      : undefined,
  );

  const [isTenantModalOpen, setIsTenantModalOpen] = useState(false);
  const handleModalClose = useCallback(() => setIsTenantModalOpen(false), []);

  const onTenantIdChange = useCallback(
    (tenantId: number) => {
      setSelectedTenantId(tenantId);
      onTenantIdContextChange(tenantId);
      sessionStorage.setItem(TENANT_ID_SELECTED, tenantId.toString());
      handleModalClose();
    },
    [handleModalClose, onTenantIdContextChange],
  );

  useEffect(() => {
    if (!userTenants || isLoadingUserTenants) {
      return;
    }

    if (userTenants.length === 1) {
      onTenantIdChange(userTenants[0].id);
    } else if (userTenants.length > 1 && !selectedTenantId) {
      setIsTenantModalOpen(true);
    }
  }, [isLoadingUserTenants, onTenantIdChange, selectedTenantId, userTenants]);

  return (
    <ChangeTenantModal
      isOpen={isTenantModalOpen}
      handleClose={handleModalClose}
      handleSave={onTenantIdChange}
      isLoading={isLoadingUserTenants}
      selectedTenantId={selectedTenantId}
      tenants={userTenants ?? []}
    />
  );
};
