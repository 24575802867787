import { businessAreasRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { BusinessAreasKey } from './keys';

export const useBusinessAreaAutomationsPerformance = (
  businessAreaId: number,
  dateRange?: DateRange,
) =>
  useQuery({
    queryKey: BusinessAreasKey.AUTOMATIONS_PERFORMANCE(
      businessAreaId,
      dateRange,
    ),
    queryFn: () =>
      businessAreasRepo.getAutomationsPerformanceById(
        businessAreaId,
        dateRange,
      ),
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
