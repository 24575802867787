import { TenantContext } from '@/components';
import { tasksRepo } from '@/constants/providers';
import { TaskModel } from '@/modules/Tasks/domain';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { TaskKey } from './keys';

export const useGetAllTasksWithoutWorkflow = (businessAreaId: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery<TaskModel[]>(
    [
      ...TaskKey.BY_BUSINESS_AREA_WITHOUT_WORKFLOW(businessAreaId),
      currentSelectedTenant?.id,
    ],
    () => tasksRepo.getAllTasksWithoutWorkflow(businessAreaId),
    {
      enabled: !!businessAreaId,
    },
  );
};
