import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type CardContainerProps = {
  className?: string;
};

export const CardContainer = ({
  className,
  children,
}: PropsWithChildren<CardContainerProps>) => {
  return (
    <div
      className={twMerge(
        'flex flex-row items-center justify-between gap-2 rounded-md bg-white px-2 py-3',
        className,
      )}
    >
      {children}
    </div>
  );
};
