import { tasksRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { TaskKey } from './keys';

export const useTaskAutomationsPerformance = (
  taskId: number,
  dateRange?: DateRange,
) =>
  useQuery({
    queryKey: TaskKey.AUTOMATIONS_PERFORMANCE(taskId, dateRange),
    queryFn: () => tasksRepo.getAutomationsPerformanceById(taskId, dateRange),
  });
