import { PerformanceCardsContainer } from '@/components/PerformanceCardsContainer';
import { useTaskAutomationsPerformance } from '@/modules/Tasks/queries/use-get-task-automations-performance';
import { ReactElement, useState } from 'react';
import { DateRange } from 'react-day-picker';

interface TaskPerformanceCardsProps {
  taskId: number;
}

export const TaskPerformanceCards = ({
  taskId,
}: TaskPerformanceCardsProps): ReactElement => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const { data, isLoading } = useTaskAutomationsPerformance(taskId, dateRange);
  return (
    <PerformanceCardsContainer
      predictedManualTime={data?.predictedManualTime || 0}
      automationRunTime={data?.automationRunTime || 0}
      totalInvestment={data?.totalInvestment || 0}
      totalReturn={data?.totalReturn || 0}
      isLoading={isLoading}
      totalRuns={data?.totalExecutions}
      dateRange={dateRange}
      setDateRange={setDateRange}
    />
  );
};
