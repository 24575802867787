import { useGetFileBlob } from '@/modules/Files/mutation/use-get-file-blob';
import { getFirstLastNameLetters } from '@/utils';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { FileUploadIcon, TrashIcon } from '../Icons';
import { Spinner } from '../Spinner';

export type AvatarSize = 'small' | 'medium' | 'medium-large' | 'large';

export interface UserAvatarProps {
  name?: string;
  size?: AvatarSize;
  imageId?: number;
  imageSrc?: string;
  isLoading?: boolean;
  allowEdit?: boolean;
  onUploadClick?: (file: File) => void;
  onDeleteClick?: () => void;
  className?: string;
}

export const UserAvatar = ({
  size = 'medium',
  name,
  imageId,
  imageSrc,
  isLoading,
  allowEdit,
  onUploadClick,
  onDeleteClick,
  className,
}: UserAvatarProps): ReactElement => {
  const styleInfo = useMemo(() => {
    if (size === 'small') {
      return 'size-6';
    }
    if (size === 'medium') {
      return 'size-11 border-2';
    }
    if (size === 'medium-large') {
      return 'size-16 border-none';
    }
    return 'size-20 border-2';
  }, [size]);

  const { data: blob, isLoading: isLoadingGetProfileImage } = useGetFileBlob(
    imageId?.toString(),
  );

  const [profileImageUrl, setProfileImageUrl] = useState<string>();

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!blob) {
      setProfileImageUrl(undefined);
      return;
    }

    const file = new File([blob], 'ProfileImage');
    const fileUrl = URL.createObjectURL(file);

    setProfileImageUrl(fileUrl);
  }, [blob]);

  const isLoadingProfileImage = useMemo(
    () => (isLoadingGetProfileImage && !!imageId) || isLoading,
    [isLoading, isLoadingGetProfileImage, imageId],
  );

  const handleButtonUploadPictureClick = useCallback(
    () => fileInputRef.current?.click(),
    [],
  );

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];

      onUploadClick?.(file);
    }
  };

  const allowOnHover = useMemo(
    () => size !== 'small' && allowEdit,
    [allowEdit, size],
  );

  const profileImageSrc = useMemo(
    () => imageSrc ?? profileImageUrl,
    [imageSrc, profileImageUrl],
  );

  return isLoading || isLoadingProfileImage ? (
    <Spinner className={styleInfo} />
  ) : (
    <div className="relative">
      <div
        className={twMerge(
          'flex items-center justify-center overflow-hidden rounded-full border border-primaryBlue',
          allowOnHover
            ? 'group transition-all duration-300 ease-out hover:bg-black'
            : '',
          styleInfo,
          className,
        )}
      >
        {allowOnHover && (
          <div className="absolute inset-0 flex cursor-pointer flex-col items-center justify-evenly rounded-full bg-black bg-opacity-50 opacity-0 transition-opacity  duration-300 group-hover:opacity-100">
            {imageId ? (
              <>
                <div
                  className="flex h-1/2 w-full items-center justify-center rounded-t-full transition-colors duration-300 hover:bg-gray-700/60"
                  onClick={handleButtonUploadPictureClick}
                >
                  <FileUploadIcon className="text-white" />
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFileChange}
                    className="display-none"
                    accept="image/png, image/jpg, image/jpeg"
                  />
                </div>
                <div
                  className="flex h-1/2 w-full items-center justify-center rounded-b-full transition-colors duration-300 hover:bg-gray-700/60"
                  onClick={onDeleteClick}
                >
                  <TrashIcon className="text-white" />
                </div>
              </>
            ) : (
              <div
                className="flex h-full w-full items-center justify-center rounded-full transition-colors duration-300 hover:bg-gray-700/60"
                onClick={handleButtonUploadPictureClick}
              >
                <FileUploadIcon className="text-white" />
                <input
                  ref={fileInputRef}
                  type="file"
                  onChange={handleFileChange}
                  className="display-none"
                  accept="image/png, image/jpg, image/jpeg"
                />
              </div>
            )}
          </div>
        )}{' '}
        {profileImageSrc ? (
          <img src={profileImageSrc} />
        ) : (
          <div className="font-heading flex h-full w-full items-center justify-center bg-indigo-300 font-bold">
            <span
              className={twJoin(
                'text-primaryBlue',
                size === 'small' && 'text-xs',
                size === 'medium' && 'text-md',
                size === 'medium-large' && 'text-lg',
                size === 'large' && 'text-lg',
              )}
            >
              {getFirstLastNameLetters(name ?? '')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
