import { TenantContext } from '@/components';
import { usersRepo } from '@/constants/providers';
import { QueryOptions, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { UserModel } from '../domain';
import { UserKey } from './types';

export const useGetUsersBaseInfo = (
  searchTerm?: string,
  options?: QueryOptions<Array<UserModel>>,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery<Array<UserModel>>(
    [...UserKey.BASE_INFO, { searchTerm }, currentSelectedTenant?.id],
    () => usersRepo.getAllUsersBaseInfo(searchTerm),
    options,
  );
};
