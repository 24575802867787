import { Button, Dropdown, Modal, SelectDropdownOption } from '@/components';
import { EntityTypes } from '@/constants';
import { queryClient } from '@/constants/query-client';
import { useUpdateRole } from '@/modules/Users/queries';
import { useGetUserProfiles } from '@/modules/Users/queries/get-user-profiles';
import { UserKey } from '@/modules/Users/queries/types';
import { EditUserRoleJson } from '@/modules/Users/types';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface EditRolerModalProps {
  isOpen: boolean;
  handleClose: () => void;
  user?: { id: number; role?: number };
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
}

export const EditRoleModal = ({
  isOpen,
  handleClose,
  user,
  organizationId,
  companyId,
  businessAreaId,
}: EditRolerModalProps): ReactElement => {
  const { t } = useTranslation();
  const { data } = useGetUserProfiles();
  const { mutate } = useUpdateRole();

  const [dropdownRoleValue, setDropdownRoleValue] = useState<number>();

  const roleDropdownOptions = useMemo(
    () => getDropdownArray(data ?? []),
    [data],
  );

  const entityType = useMemo(() => {
    if (organizationId) {
      return EntityTypes.ORGANIZATION;
    }
    if (companyId) {
      return EntityTypes.COMPANY;
    }
    if (businessAreaId) {
      return EntityTypes.BUSINESS_AREA;
    }
  }, [organizationId, companyId, businessAreaId]);

  const onHandleConfirm = useCallback(
    (dropdownRoleValue: number) => {
      const convertedData: EditUserRoleJson = {
        id: user?.id,
        profileTypeId: Number(dropdownRoleValue),
        entityType: entityType,
        entityId: organizationId || companyId || businessAreaId,
      };

      mutate(convertedData, {
        onError: () => {
          toast.error(t('error_updating_user'));
        },
        onSuccess: () => {
          queryClient.invalidateQueries(UserKey.ALL);
          toast.success(t('success_updating_user'));
          handleClose();
        },
      });
    },
    [
      mutate,
      t,
      handleClose,
      user,
      entityType,
      organizationId,
      companyId,
      businessAreaId,
    ],
  );

  useEffect(() => {
    if (user?.role) {
      setDropdownRoleValue(user.role);
    }
  }, [user]);

  const handleConfirm = () => {
    dropdownRoleValue && onHandleConfirm(dropdownRoleValue);
  };

  const handleOnSelectRoleDropdownValue = useCallback(
    (option: SelectDropdownOption<number>) =>
      setDropdownRoleValue(option.value),
    [],
  );

  return (
    <Modal
      isOpen={isOpen}
      title={t('edit_user')}
      className="h-fit w-128"
      handleClose={handleClose}
    >
      <div className="mt-6 flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className="mt-2 flex min-h-72 flex-col overflow-y-auto p-2">
            <Dropdown
              className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
              placeholder={t('role')}
              options={roleDropdownOptions}
              value={dropdownRoleValue}
              onSelect={handleOnSelectRoleDropdownValue}
            />
          </div>
          <div className="mt-8 flex justify-between">
            <Button
              onClick={handleClose}
              variant="outline"
              className="h-9 w-24"
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={handleConfirm}
              className="h-9 w-24"
              isDisabled={!dropdownRoleValue}
            >
              {t('confirm')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
