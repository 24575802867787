import {
  Button,
  Dropdown,
  FieldInfo,
  FilterBusinessAreaFormGroup,
  Input,
  TextArea,
} from '@/components';
import { useGetAllBusinessAreasByCompany } from '@/modules/BusinessAreas/queries';
import { useGetAllCompaniesByOrganization } from '@/modules/Companies/queries';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';
import { TaskSchema, TaskSchemaType } from '@/modules/Tasks/types';
import { LabourWorkloadTypes } from '@/modules/Tasks/types/labourWorkLoadTypes';
import { PeriodicityTypes } from '@/modules/Tasks/types/periodicityTypes';
import { useGetAllWorkflowsByBusinessArea } from '@/modules/Workflows/queries';
import { separateString } from '@/utils';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface TaskFormProps {
  handleOnSubmit: (e: TaskSchemaType) => void;
  handleClose: () => void;
  title: string;
  type: 'create' | 'edit';
  isLoading: boolean;
  isOpen: boolean;
  taskName?: string;
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  workflowId?: number;
  taskDescription?: string;
  taskPeriodicity?: string;
  labourWorkload?: number;
  disableFields?: (keyof TaskSchemaType)[];
  labourWorkloadType?: string;
  averageCases?: number;
}

export const TaskForm = ({
  isOpen,
  type,
  isLoading,
  handleOnSubmit,
  handleClose,
  taskName,
  organizationId,
  companyId,
  businessAreaId,
  workflowId,
  taskDescription,
  taskPeriodicity,
  labourWorkload,
  disableFields,
  labourWorkloadType,
  averageCases,
}: TaskFormProps) => {
  const { t } = useTranslation();
  const {
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm<TaskSchemaType>({
    defaultValues: {
      taskName: taskName || '',
      organizationId: organizationId,
      companyId: companyId,
      businessAreaId: businessAreaId,
      workflowId: workflowId,
      taskDescription: taskDescription || '',
      taskPeriodicity: taskPeriodicity || '',
      labourWorkload: labourWorkload,
      averageCases: averageCases,
      documentation: null,
    },
    resolver: zodResolver(TaskSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const { data: organizations } = useGetAllOrganizations();

  const organization = watch('organizationId');
  const company = watch('companyId');
  const businessArea = watch('businessAreaId');

  const { data: companies } = useGetAllCompaniesByOrganization(organization);
  const { data: businessAreas } = useGetAllBusinessAreasByCompany(company);
  const { data: workflows } = useGetAllWorkflowsByBusinessArea(
    businessArea,
    true,
  );

  const {
    dropdownOrganizationsOptions,
    dropdownCompaniesOptions,
    dropdownBusinessAreasOptions,
    dropdownWorkflowsOptions,
  } = useMemo(() => {
    return {
      dropdownOrganizationsOptions: getDropdownArray(
        organizations?.filter(
          (organization) =>
            organization.status || organization.id === organizationId,
        ) ?? [],
      ),
      dropdownCompaniesOptions: getDropdownArray(
        companies?.filter(
          (company) => company.status || company.id === companyId,
        ) ?? [],
      ),
      dropdownBusinessAreasOptions: getDropdownArray(
        businessAreas?.filter(
          (businessArea) =>
            businessArea.status || businessArea.id === businessAreaId,
        ) ?? [],
      ),
      dropdownWorkflowsOptions: getDropdownArray(
        workflows?.filter(
          (workflow) => workflow.status || workflow.id === workflowId,
        ) ?? [],
      ),
    };
  }, [
    organizations,
    companies,
    businessAreas,
    workflows,
    organizationId,
    companyId,
    businessAreaId,
    workflowId,
  ]);

  const dropdownPeriodicityOptions = useMemo(
    () =>
      getDropdownArray(
        Object.keys(PeriodicityTypes).map((periodicityTypes) => ({
          name: t(periodicityTypes),
          id: periodicityTypes,
        })),
      ),
    [t],
  );

  const dropdownLabourWorkloadTypeOptions = useMemo(
    () =>
      getDropdownArray(
        Object.keys(LabourWorkloadTypes).map((labourWorkloadTypes) => ({
          name: separateString(
            LabourWorkloadTypes[
              labourWorkloadTypes as keyof typeof LabourWorkloadTypes
            ],
          ),
          id: labourWorkloadTypes,
        })),
      ),
    [],
  );

  useEffect(() => {
    clearErrors();

    setValue('taskName', taskName || '');
    organizationId && setValue('organizationId', organizationId);
    companyId && setValue('companyId', companyId);
    businessAreaId && setValue('businessAreaId', businessAreaId);
    setValue('workflowId', workflowId);
    setValue('taskDescription', taskDescription || '');
    setValue('taskPeriodicity', taskPeriodicity || '');
    setValue('labourWorkload', labourWorkload || 0);
    setValue('documentation', null);
    setValue('labourWorkloadType', labourWorkloadType || '');
    setValue('averageCases', averageCases);
  }, [
    clearErrors,
    setValue,
    isOpen,
    taskName,
    organizationId,
    companyId,
    businessAreaId,
    workflowId,
    taskDescription,
    taskPeriodicity,
    labourWorkload,
    labourWorkloadType,
    averageCases,
  ]);

  const onSubmit: SubmitHandler<TaskSchemaType> = (data) => {
    handleOnSubmit(data);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setValue('documentation', file);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2 pb-20">
        <FieldInfo
          title={t('task_name')}
          errorText={
            errors.taskName?.message ? t(errors.taskName.message) : undefined
          }
          hasError={!!errors.taskName?.message}
        >
          <Input
            isDisabled={isLoading}
            className="w-full bg-brightGray italic"
            placeHolder={t('task_name')}
            name="taskName"
            register={register}
          />
        </FieldInfo>
        <FilterBusinessAreaFormGroup<TaskSchemaType>
          organizationOptions={dropdownOrganizationsOptions}
          companyOptions={dropdownCompaniesOptions}
          businessAreaOptions={dropdownBusinessAreasOptions}
          disableFields={disableFields}
          hiddenFields={disableFields}
          errors={errors}
          control={control}
        />
        <FieldInfo
          title={t('workflow')}
          errorText={
            errors.workflowId?.message
              ? t(errors.workflowId.message)
              : undefined
          }
          hasError={!!errors.workflowId?.message}
          hidden={disableFields?.includes('workflowId')}
        >
          <Controller
            name="workflowId"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ...otherFieldProps } }) => (
              <Dropdown
                disabled={disableFields?.includes('workflowId')}
                {...otherFieldProps.onBlur}
                className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                placeholder={t('workflow')}
                options={dropdownWorkflowsOptions}
                onSelect={(val) => onChange(val.value)}
                value={value}
              />
            )}
          />
        </FieldInfo>
        <FieldInfo
          title={t('task_description')}
          errorText={
            errors.taskDescription?.message
              ? t(errors.taskDescription.message, { length: '9' })
              : undefined
          }
          hasError={!!errors.taskDescription?.message}
        >
          <TextArea
            isDisabled={isLoading}
            className="h-24 w-full  bg-brightGray italic"
            placeHolder={t('task_description')}
            register={register}
            name="taskDescription"
          />
        </FieldInfo>
        <FieldInfo
          title={t('task_periodicity')}
          errorText={
            errors.taskPeriodicity?.message
              ? t(errors.taskPeriodicity.message, { length: '9' })
              : undefined
          }
          hasError={!!errors.taskPeriodicity?.message}
        >
          <Controller
            name="taskPeriodicity"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ...otherFieldProps } }) => (
              <Dropdown
                disabled={disableFields?.includes('taskPeriodicity')}
                {...otherFieldProps.onBlur}
                className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                placeholder={t('task_periodicity')}
                options={dropdownPeriodicityOptions}
                onSelect={(val) => onChange(val.value)}
                value={value}
              />
            )}
          />
        </FieldInfo>
        <FieldInfo
          title={t('labour_workload')}
          errorText={
            errors.labourWorkload?.message
              ? t(errors.labourWorkload.message, { length: '9' })
              : undefined
          }
          hasError={!!errors.labourWorkload?.message}
        >
          <Input
            type="text"
            isDisabled={isLoading}
            className="w-full bg-brightGray italic"
            placeHolder={t('labour_workload_minutes')}
            register={register}
            name="labourWorkload"
            step="any"
          />
        </FieldInfo>
        <FieldInfo
          title={t('labour_workload_type')}
          errorText={
            errors.labourWorkloadType?.message
              ? t(errors.labourWorkloadType.message, { length: '9' })
              : undefined
          }
          hasError={!!errors.labourWorkloadType?.message}
        >
          <Controller
            name="labourWorkloadType"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ...otherFieldProps } }) => (
              <Dropdown
                disabled={disableFields?.includes('labourWorkloadType')}
                {...otherFieldProps.onBlur}
                className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                placeholder={t('labour_workload_type')}
                options={dropdownLabourWorkloadTypeOptions}
                onSelect={(val) => onChange(val.value)}
                value={value}
              />
            )}
          />
        </FieldInfo>
        {watch('labourWorkloadType') === LabourWorkloadTypes.PerCase && (
          <FieldInfo
            title={t('average_cases')}
            errorText={
              errors.averageCases?.message
                ? t(errors.averageCases.message)
                : undefined
            }
            hasError={!!errors.averageCases?.message}
          >
            <Input
              type="number"
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('average_cases')}
              register={register}
              name="averageCases"
            />
          </FieldInfo>
        )}
        {type !== 'edit' && (
          <FieldInfo
            title={t('documentation')}
            hasError={!!errors.documentation?.message}
          >
            <div className="relative w-full bg-brightGray capitalize">
              <Controller
                name="documentation"
                control={control}
                render={({ field }) => (
                  <>
                    <input
                      id="file-upload"
                      type="file"
                      accept=".pdf, .doc, .docx"
                      className="absolute inset-0 z-50 w-full opacity-0"
                      onChange={handleFileChange}
                      disabled={isLoading}
                    />
                    <label
                      htmlFor="file-upload"
                      className="flex h-10 items-center justify-center rounded-md border border-dashed border-gray-300 bg-white p-2"
                    >
                      <span className="cursor-pointer text-sm font-bold underline">
                        {field.value ? field.value.name : t('choose_file')}
                      </span>
                    </label>
                  </>
                )}
              />
            </div>
          </FieldInfo>
        )}
      </div>
      <div className="mt-4 flex justify-between">
        <Button
          isDisabled={isLoading}
          onClick={handleClose}
          variant="outline"
          className="h-9 w-24"
        >
          {t('cancel')}
        </Button>
        <Button isDisabled={isLoading} buttonType="submit" className="h-9 w-24">
          {type === 'create' ? t('create') : t('confirm')}
        </Button>
      </div>
    </form>
  );
};
