import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SwitchStatus } from '..';
import { CardContainer } from '../CardContainer/CardContainer';

type StatusCardProps = {
  label?: string;
  status: boolean;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
};

export const StatusCard = ({
  label,
  status,
  handleStatus,
}: StatusCardProps) => {
  const { t } = useTranslation();
  return (
    <CardContainer>
      <span className="text-sm font-black">{label ?? t('status')}</span>
      <div className="flex w-24 items-center justify-end gap-2 text-sm">
        <span className={`${status ? 'text-blueNuit' : 'brightGray'}`}>
          {t(status ? 'active' : 'inactive')}
        </span>
        <SwitchStatus onChange={handleStatus} checked={status} />
      </div>
    </CardContainer>
  );
};
