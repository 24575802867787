import { PerformanceCardsContainer } from '@/components/PerformanceCardsContainer';
import { useGetOrganizationAutomationsPerformance } from '@/modules/Organizations/queries/get-organization-automations-peformance';
import { ReactElement, useState } from 'react';
import { DateRange } from 'react-day-picker';

interface OrganizationPerformanceCardsProps {
  organizationId: number;
}

export const OrganizationPerformanceCards = ({
  organizationId,
}: OrganizationPerformanceCardsProps): ReactElement => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const { data, isLoading } = useGetOrganizationAutomationsPerformance(
    organizationId,
    dateRange,
  );

  return (
    <PerformanceCardsContainer
      predictedManualTime={data?.predictedManualTime || 0}
      automationRunTime={data?.automationRunTime || 0}
      totalInvestment={data?.totalInvestment || 0}
      totalReturn={data?.totalReturn || 0}
      isLoading={isLoading}
      totalRuns={data?.totalExecutions}
      dateRange={dateRange}
      setDateRange={setDateRange}
    />
  );
};
