import { automationsRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { AutomationKey } from './keys';

export const useAutomationPerformance = (
  automationId: number,
  dateRange?: DateRange,
) =>
  useQuery({
    queryKey: AutomationKey.PERFORMANCE(automationId, dateRange),
    queryFn: () =>
      automationsRepo.getAutomationPerformanceById(automationId, dateRange),
  });
