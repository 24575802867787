import { Placement } from '@floating-ui/react';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { Tooltip } from '../Tooltip';

interface OverflowTooltipProps {
  text: string;
  placement?: Placement;
  showArrow?: boolean;
  containerClassName?: string;
  textClassName?: string;
}

export function OverflowTooltip({
  text,
  placement = 'top',
  showArrow = false,
  containerClassName,
  textClassName,
}: OverflowTooltipProps): ReactElement {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflow(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [text]);

  return (
    <Tooltip
      content={text}
      enabled={isOverflow}
      placement={placement}
      showArrow={showArrow}
      containerClassName={containerClassName}
    >
      <p ref={textRef} className={textClassName}>
        {text}
      </p>
    </Tooltip>
  );
}
