import { TenantContext } from '@/components';
import { usersRepo } from '@/constants/providers';
import { QueryOptions } from '@/modules/types';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { UserModel } from '../domain';
import { UserKey } from './types';

export const useGetUser = (
  userId: number,
  options?: QueryOptions<UserModel>,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<UserModel>(
    [...UserKey.BY_ID(userId), currentSelectedTenant],
    () => usersRepo.getUser(userId),
    {
      ...options,
    },
  );
};
