export enum TagType {
  LAST_DAYS = 'last_x_days',
  YEAR_TO_DATE = 'year_to_date',
}

export const WEEK_N_DAYS = 7;
export const CUSTOM_DAYS_SELECT = [
  {
    value: 7,
    type: TagType.LAST_DAYS,
  },
  {
    value: 14,
    type: TagType.LAST_DAYS,
  },
  {
    value: 30,
    type: TagType.LAST_DAYS,
  },
  {
    value: 90,
    type: TagType.LAST_DAYS,
  },
  {
    type: TagType.YEAR_TO_DATE,
  },
];

export const WEEK_DAY_NAME_MAP: Record<number, string> = {
  0: 'S',
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'T',
  5: 'F',
  6: 'S',
};
