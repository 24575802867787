import { ApiResponse, DataWithPagination } from '@/shared/types';
import { WorkflowModel } from '../domain';
import {
  AssociateTaskToWorkflow,
  CreateWorkflowJson,
  DisassociateTaskFromWorkflow,
  OrderWorkflow,
} from '../types';

export abstract class WorkflowRepository {
  abstract getWorkflows(
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<WorkflowModel[]>>;

  abstract createWorkflow(data: CreateWorkflowJson): Promise<WorkflowModel>;
  abstract getWorkflow(id: number): Promise<WorkflowModel>;
  abstract enableWorkflow(id: number): Promise<ApiResponse<unknown>>;
  abstract disableWorkflow(id: number): Promise<ApiResponse<unknown>>;
  abstract orderWorkflow(data: OrderWorkflow): Promise<ApiResponse<unknown>>;
  abstract deleteWorkflow(id: number): Promise<ApiResponse<unknown>>;
  abstract updateWorkflow(data: CreateWorkflowJson): Promise<unknown>;
  abstract getWorkflowsByBusinessAreaId(
    businessAreaId: number,
  ): Promise<WorkflowModel[]>;
  abstract getWorkflowsByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<WorkflowModel[]>>;
  abstract getWorkflowsByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<WorkflowModel[]>>;
  abstract getWorkflowsByProcessIdAutomations(
    processId: number,
  ): Promise<WorkflowModel[]>;
  abstract getWorkflowsByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<WorkflowModel[]>>;

  abstract associateTaskToWorkflow(
    data: AssociateTaskToWorkflow,
  ): Promise<ApiResponse<unknown>>;
  abstract disassociateTaskFromWorkflow(
    data: DisassociateTaskFromWorkflow,
  ): Promise<ApiResponse<unknown>>;
}
