import {
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { Button } from '../Button';
import { Container } from '../Container';

type PageDetailsHeaderRowProps = {
  title: string;
  description?: ReactNode;
  isLoading?: boolean;
  before?: ReactNode;
  actions?: ReactNode;
  details?: ReactNode;
  onEditClick?: MouseEventHandler;
};

export const PageDetailsHeaderRow = ({
  title,
  description,
  isLoading,
  before,
  actions,
  details,
  children,
  onEditClick,
}: PropsWithChildren<PageDetailsHeaderRowProps>) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);

  const handleShowDetailsToggle = useCallback(() => {
    setShowDetails((prevShowDetails) => !prevShowDetails);
  }, []);

  return (
    <div className="flex flex-col gap-1">
      <Container isLoading={isLoading} className="py-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2.5">
            {before}
            <div className="flex flex-col">
              <h2 className="leading-tight">{title}</h2>
              {!!description && <span className="text-sm">{description}</span>}
            </div>
          </div>
          <div className="flex h-10 flex-row gap-3">
            {actions}
            {onEditClick && (
              <Button className="px-5 py-2.5" onClick={onEditClick}>
                {t('edit')}
              </Button>
            )}
            {details && (
              <Button
                variant="outline"
                className={twMerge(
                  'min-w-24 border bg-transparent p-2.5 font-black',
                  !showDetails && 'border-blueNuit text-blueNuit',
                )}
                onClick={handleShowDetailsToggle}
              >
                {showDetails ? t('hide_details') : t('show_details')}
              </Button>
            )}
          </div>
        </div>
      </Container>
      {children}
      {showDetails && details}
    </div>
  );
};
