import { LanguageDropdown, TenantContext, ToolIcon } from '@/components';
import { ChangeTenantModal } from '@/components/ChangeTenantModal';
import { ChangePasswordModal } from '@/components/SideNav/components';
import { Environment } from '@/constants';
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AvatarDropdown } from '../AvatarDropdown';

export const Header = (): ReactElement => {
  const { i18n } = useTranslation();
  const {
    currentSelectedTenant: tenant,
    onTenantIdChange,
    userTenants,
    isLoadingTenantInfo,
  } = useContext(TenantContext);

  const [isChangeTenantModalOpen, setIsChangeTenantModalOpen] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);

  const handleChangeTenantModalClose = useCallback(() => {
    setIsChangeTenantModalOpen(false);
  }, []);

  const handleChangeTenantModalOpen = useCallback(() => {
    setIsChangeTenantModalOpen(true);
  }, []);

  const handleCloseChangePasswordModal = useCallback(
    () => setOpenChangePasswordModal(false),
    [],
  );

  const handleOpenChangePasswordModal = useCallback(
    () => setOpenChangePasswordModal(true),
    [],
  );

  const isDevEnvironment = useMemo(
    () => import.meta.env.MODE.toLowerCase() !== Environment.Production,
    [],
  );

  return (
    <>
      <div className={`sticky top-0 z-10 flex w-full flex-col bg-white`}>
        {isDevEnvironment && (
          <div className="flex w-full items-center gap-2.5 bg-lightPurple px-1 py-1 text-fennelBlue sm:px-12">
            <ToolIcon className="size-4 flex-shrink-0" />
            <p>
              <Trans
                i18n={i18n}
                className="text-sm"
                i18nKey={'testing_message'}
                components={{
                  bold: <span className="font-black" />,
                }}
              />
            </p>
          </div>
        )}
        <div className="flex items-center justify-end py-2 pr-1.5">
          <AvatarDropdown
            handleOpenChangeTenantModal={handleChangeTenantModalOpen}
            handleOpenChangePasswordModal={handleOpenChangePasswordModal}
          />
          <div className="h-full border-1 text-brightGray"></div>
          <LanguageDropdown />
        </div>
      </div>
      <ChangeTenantModal
        isOpen={isChangeTenantModalOpen}
        handleClose={handleChangeTenantModalClose}
        handleSave={onTenantIdChange}
        selectedTenantId={tenant?.id}
        tenants={userTenants}
        isLoading={isLoadingTenantInfo}
      />
      <ChangePasswordModal
        isOpen={openChangePasswordModal}
        handleClose={handleCloseChangePasswordModal}
      />
    </>
  );
};
