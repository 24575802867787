import { AutomationTaskPerformance } from '../domain/associated-tasks-model';
import { AutomationTaskPerformanceJson } from '../types/associated-tasks-json';

export const mapAutomationTaskPerformance = (
  associatedTasksJson: AutomationTaskPerformanceJson,
): AutomationTaskPerformance => {
  return {
    id: associatedTasksJson?.id ?? 0,
    name: associatedTasksJson.name ?? '',
    averageCost: associatedTasksJson.averageCost ?? 0,
    businessAreaName: associatedTasksJson.businessAreaName ?? '',
    companyName: associatedTasksJson.companyName ?? '',
    organizationName: associatedTasksJson.organizationName ?? '',
    predictedManualTimeSeconds:
      associatedTasksJson.predictedManualTimeSeconds ?? 0,
    runTimeSeconds: associatedTasksJson.runTimeSeconds ?? 0,
    totalInvestment: associatedTasksJson.totalInvestment ?? 0,
    totalReturn: associatedTasksJson.totalReturn ?? 0,
    averageManualCost: associatedTasksJson.averageManualCost ?? 0,
    averageManualTimeSeconds:
      associatedTasksJson.averagePredictedTimeSeconds ?? 0,
    averageRunTimeSeconds: associatedTasksJson.averageRunTimeSeconds ?? 0,
    totalExecutions: associatedTasksJson.totalExecutions ?? 0,
    workflowName: associatedTasksJson.workflowName ?? '',
    workflowId: associatedTasksJson.workflowId ?? 0,
  };
};

export const mapAutomationTaskPerformances = (
  associatedTasksJson: Array<AutomationTaskPerformanceJson>,
): Array<AutomationTaskPerformance> =>
  associatedTasksJson.map(mapAutomationTaskPerformance);
