import { RoutePath } from '@/core/router/route-paths';
import { SideNav } from '..';
import {
  AshLogoIcon,
  AutomationsIcon,
  BusinessAreasIcon,
  CompaniesIcon,
  InfrastructureIcon,
  ListCheckIcon,
  OrganizationsIcon,
  ProcessesIcon,
  UserIcon,
  WorkflowsIcon,
} from '../Icons';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const getNavigationItems = (t: TFunction) => [
  /* TODO: once the Dashboard is developed
  {
    to: RoutePath.dashboard(),
    label: t('dashboard'),
    icon: HomeIcon,
  },
  */
  {
    to: RoutePath.organizations(),
    label: t('organizations'),
    icon: OrganizationsIcon,
  },
  {
    to: RoutePath.companies(),
    label: t('companies'),
    icon: CompaniesIcon,
  },
  {
    to: RoutePath.businessAreas(),
    label: t('business_areas'),
    icon: BusinessAreasIcon,
  },
  {
    to: RoutePath.users(),
    label: t('users'),
    icon: UserIcon,
  },
  {
    to: RoutePath.processes(),
    label: t('processes'),
    icon: ProcessesIcon,
  },
  {
    to: RoutePath.workflows(),
    label: t('workflows'),
    icon: WorkflowsIcon,
  },
  {
    to: RoutePath.tasks(),
    label: t('tasks'),
    icon: ListCheckIcon,
  },
  {
    to: RoutePath.automations(),
    label: t('automations'),
    icon: AutomationsIcon,
  },
  {
    to: RoutePath.infrastructureCosts(),
    label: t('infrastructure_costs'),
    icon: InfrastructureIcon,
  },
];

export const SideNavigation = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div>
      <SideNav
        items={getNavigationItems(t)}
        header={<AshLogoIcon style={{ margin: '15px' }} />}
      />
    </div>
  );
};
