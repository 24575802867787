import { TenantContext } from '@/components';
import { workflowsRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { WorkflowModel } from '../domain';
import { WorkflowKey } from './keys';

export const useGetWorkflow = (id: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<WorkflowModel>(
    [...WorkflowKey.BY_ID(id), currentSelectedTenant?.id],
    () => workflowsRepo.getWorkflow(id),
    {
      enabled: !!id,
    },
  );
};
