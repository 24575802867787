import { BusinessAreasKey } from '@/modules/BusinessAreas/queries/keys';
import { CompanyKeys } from '@/modules/Companies/queries';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { ProcessKey } from '@/modules/Processes/queries';
import { TaskKey, useCreateTask } from '@/modules/Tasks/queries';
import { TaskSchemaType } from '@/modules/Tasks/types';
import { UserKey } from '@/modules/Users/queries/types';
import { WorkflowKey } from '@/modules/Workflows/queries';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TaskForm } from '../TaskForm';
import { TaskModal } from '../TaskModal';

interface CreateTaskModalProps {
  isOpen: boolean;
  handleClose: () => void;
  organizationId?: number;
  disableFields?: (keyof TaskSchemaType)[];
  businessAreaId?: number;
  companyId?: number;
  workflowId?: number;
}

export const CreateTaskModal = ({
  isOpen,
  handleClose,
  organizationId,
  disableFields,
  businessAreaId,
  companyId,
  workflowId,
}: CreateTaskModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateTask();

  const handleConfirm = (data: TaskSchemaType) => {
    const convertedData = new FormData();
    convertedData.append('name', data.taskName || '');
    convertedData.append(
      'businessAreaId',
      data.businessAreaId?.toString() || '',
    );
    convertedData.append('description', data.taskDescription || '');
    convertedData.append('period', data.taskPeriodicity || '');
    convertedData.append(
      'workload',
      data.labourWorkload?.toString().replace('.', ',') || '',
    );
    convertedData.append('workflowId', data.workflowId?.toString() || '');
    convertedData.append('taskStageId', '1');
    convertedData.append('status', 'true');
    convertedData.append('workloadTypeId', data.labourWorkloadType || '');
    convertedData.append('averageCases', data.averageCases?.toString() || '');

    if (data.documentation) {
      convertedData.append('documentation', data.documentation);
    }
    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_creating_task'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(TaskKey.ALL);
        queryClient.invalidateQueries(WorkflowKey.ALL);
        queryClient.invalidateQueries(UserKey.ALL);
        queryClient.invalidateQueries(OrganizationKeys.ALL);
        queryClient.invalidateQueries(CompanyKeys.ALL);
        queryClient.invalidateQueries(BusinessAreasKey.ALL);
        queryClient.invalidateQueries(ProcessKey.ALL);

        handleClose();
        toast.success(t('success_creating_task'));
      },
    });
  };

  if (
    location.pathname.includes('workflows') ||
    location.pathname.includes('processes')
  ) {
    return (
      <TaskForm
        handleClose={handleClose}
        type="create"
        handleOnSubmit={handleConfirm}
        title={t('create_task')}
        isOpen={isOpen}
        isLoading={isLoading}
        organizationId={organizationId}
        disableFields={disableFields}
        businessAreaId={businessAreaId}
        companyId={companyId}
        workflowId={workflowId}
      />
    );
  }
  return (
    <TaskModal
      handleClose={handleClose}
      type="create"
      handleOnSubmit={handleConfirm}
      title={t('create_task')}
      isOpen={isOpen}
      isLoading={isLoading}
      organizationId={organizationId}
      disableFields={disableFields}
      businessAreaId={businessAreaId}
      companyId={companyId}
      workflowId={workflowId}
    />
  );
};
