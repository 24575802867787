import { TENANT_ID_SELECTED } from '@/components/TenantProvider';
import keycloakConfig from '@/keycloak';
import { User } from 'oidc-client-ts';
import { RoutePath } from '../router';
import { HttpClient } from './http-client';

function getUser() {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${keycloakConfig.authServerUrl}/realms/${keycloakConfig.realm}:${keycloakConfig.clientId}`,
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}
export class ProtectedHttpClient extends HttpClient {
  constructor(resource: string) {
    super(resource);

    this.setOnRequest((config, headers) => {
      const user = getUser();
      const token = user?.access_token;
      return {
        ...config,
        headers: {
          ...(headers ?? {}),
          Authorization: `Bearer ${token}`,
          'Tenant-Id': sessionStorage.getItem(TENANT_ID_SELECTED),
        },
      };
    });

    this.setOnResponse({
      error(error) {
        if (error.status === 404) {
          window.location.href = RoutePath.notFound();
          return;
        }
        if (error.status === 401) {
          //If keycloak fails and token doesn't update we get this error from backend
          window.location.href = RoutePath.home();
          return error;
        }
        return error;
      },
    });
  }
}
