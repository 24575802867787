import { Card } from '@/components';
import { Container } from '@/components/Container';
import { PageDetailsHeaderRow } from '@/components/PageDetailsHeaderRow/PageDetailsHeaderRow';
import { StatusCard } from '@/components/StatusCard/StatusCard';
import { BusinessAreaModel } from '@/modules/BusinessAreas/domain';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditBusinessAreaModal } from '../../../BusinessAreasPage/components/EditBusinessAreaModal';

interface BusinessAreaHeaderRowProps {
  businessArea?: BusinessAreaModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
  isLoading?: boolean;
}

export const BusinessAreaHeaderRow = ({
  businessArea,
  handleStatus,
  status,
}: BusinessAreaHeaderRowProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <div>
      <PageDetailsHeaderRow
        isLoading={!businessArea}
        title={businessArea?.name ?? ''}
        description={
          <div data-testid="org-comp-section">
            <span className="font-bold">{businessArea?.company}, </span>
            <span>{businessArea?.organizationName}</span>
          </div>
        }
        actions={<StatusCard status={status} handleStatus={handleStatus} />}
        onEditClick={handleModalState}
        details={
          <Container>
            <Card title={t('code')}>{businessArea?.code}</Card>
          </Container>
        }
      />
      {businessArea && (
        <EditBusinessAreaModal
          isOpen={isOpen}
          handleClose={handleModalState}
          businessAreaId={businessArea.id}
        />
      )}
    </div>
  );
};
