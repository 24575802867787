import { TableColumn, TableRow } from '@/components/Table/types';
import {
  amountColumnConfig,
  companyNameColumnConfig,
  costColumnConfig,
  dateColumnConfig,
  endDateColumnConfig,
  recurrenceColumnConfig,
  statusColumnConfig,
  typeColumnConfig,
} from '@/utils';
import { actionsEditRowColumnConfig } from '@/utils/tableColumns/actions-edit-row-column-config';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import { InfrastructureCostModel } from '../../domain';

type InfrastructureCostsColumnsConfig = {
  onClick?: (row?: TableRow) => void;
  disableDeleteMessage?: string;
  onDeleteClick?: (row?: TableRow) => void;
};

export const getInfrastructureCostColumns = ({
  onClick,
}: InfrastructureCostsColumnsConfig): Array<
  TableColumn<InfrastructureCostModel>
> => {
  return [
    costColumnConfig({ onClick }),
    processOrganizationNameColumnConfig(),
    companyNameColumnConfig(),
    typeColumnConfig(),
    amountColumnConfig(),
    recurrenceColumnConfig(),
    statusColumnConfig(),
    //missing actions column
  ];
};

export const getInfrastructureCostColumnsNamedMapped = (columnName: string) => {
  const infrastructureCostColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    businessAreaName: 'BusinessAreaName',
    stage: 'Stage',
    status: 'Status',
    runTime: 'RunTime',
    averageCases: 'AverageCases',
    sucess: 'Sucess',
    organizationName: 'Organization',
    company: 'Company',
  };

  return infrastructureCostColumnsNamedMapped[columnName] ?? 'Name';
};

export const getAmountHistoryColumns = ({
  onClick,
  columnsToEdit,
}: {
  onClick: (row: number) => void;
  columnsToEdit?: number[];
}): Array<TableColumn<TableRow>> => {
  return [
    amountColumnConfig(),
    dateColumnConfig({ key: 'startDate' }, 'start_date'),
    endDateColumnConfig(),
    actionsEditRowColumnConfig({
      onEditClick(row) {
        onClick(row.id as number);
      },
      canEdit: (row) => !!columnsToEdit?.includes(row.id as number),
    }),
  ];
};
