import {
  BracketsIcon,
  Button,
  CurrentUserAvatar,
  PREVIOUS_TENANT_ID,
  TENANT_ID_SELECTED,
  TenantContext,
} from '@/components';
import { RoutePath } from '@/core/router';
import { useOutsideClick } from '@/hooks';
import { useGetCurrentUser } from '@/modules/Users/queries';
import {
  MouseEvent,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { twMerge } from 'tailwind-merge';

interface AvatarProps {
  className?: string;
  handleOpenChangeTenantModal: () => void;
  handleOpenChangePasswordModal: () => void;
}

interface Option {
  id: number;
  label: string;
  action: () => void;
}

export function AvatarDropdown({
  className,
  handleOpenChangeTenantModal,
  handleOpenChangePasswordModal,
}: AvatarProps): ReactElement {
  const { t } = useTranslation();

  const auth = useAuth();

  const { data: user } = useGetCurrentUser();

  const { currentSelectedTenant: tenant } = useContext(TenantContext);

  const { fullName, email } = useMemo(() => {
    let fullName = user?.name ?? '';

    if (!fullName) {
      fullName = user ? `${user.firstName} ${user.lastName}` : '';
    }

    return { fullName, email: user?.email ?? '', userId: user?.id };
  }, [user]);

  const buttonWrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useOutsideClick(buttonWrapperRef, handleOutsideClick, isOpen);

  const handleSelectDropdownButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setIsOpen((prev) => !prev);
  };

  const handleChangeTenantClick = useCallback(() => {
    handleOpenChangeTenantModal();
    setIsOpen(false);
  }, [handleOpenChangeTenantModal]);

  const handleChangePasswordClick = useCallback(() => {
    handleOpenChangePasswordModal();
    setIsOpen(false);
  }, [handleOpenChangePasswordModal]);

  const redirectToProfilePage = useCallback(() => {
    setIsOpen(false);
    window.location.href = RoutePath.userProfile();
  }, []);

  const handleLogoutClick = useCallback(async () => {
    await auth.signoutRedirect({
      post_logout_redirect_uri: window.location.origin,
    });
    sessionStorage.removeItem(TENANT_ID_SELECTED);
    sessionStorage.removeItem(PREVIOUS_TENANT_ID);
    setIsOpen(false);
  }, [auth]);

  const options = useMemo(
    () =>
      [
        {
          id: 1,
          label: t('change_tenant'),
          action: handleChangeTenantClick,
        },
        {
          id: 2,
          label: t('change_password'),
          action: handleChangePasswordClick,
        },
        {
          id: 3,
          label: t('profile_page'),
          action: redirectToProfilePage,
        },
        {
          id: 4,
          label: t('logout'),
          action: handleLogoutClick,
        },
      ] as Option[],
    [
      t,
      handleChangeTenantClick,
      handleChangePasswordClick,
      redirectToProfilePage,
      handleLogoutClick,
    ],
  );

  return (
    <div
      ref={buttonWrapperRef}
      className={twMerge(
        'relative mx-2 inline-block cursor-pointer gap-2',
        className,
      )}
      onClick={handleSelectDropdownButtonClick}
    >
      <div>
        <div className="flex content-center items-center gap-3">
          <div className=" flex flex-col text-right">
            <span className="text-sm font-black text-primaryBlue">
              {fullName}
            </span>
            <span className="text-sm font-normal text-gray-400">
              {tenant
                ? `${tenant.userRole?.concat(',') ?? ''} ${tenant.name}`
                : email}
            </span>
          </div>
          <CurrentUserAvatar className="border-transparent" size="medium" />
          <Button
            className="bg-transparent p-0"
            onClick={handleSelectDropdownButtonClick}
          >
            <BracketsIcon className="size-5" />
          </Button>
        </div>

        <div
          className={twMerge(
            'absolute right-0 z-50 block w-max divide-y divide-white overflow-y-auto rounded-md rounded-t-none border border-t-0 bg-white p-4 text-sm shadow',
            !isOpen && 'hidden',
          )}
        >
          <ul className="relative max-h-48 w-full">
            {options.map((opt: Option) => {
              return (
                <li className="group flex pb-3" key={`${opt.id}`}>
                  <Button
                    variant="text"
                    className="flex w-full flex-grow text-ellipsis whitespace-nowrap bg-white p-0 text-start text-primaryBlue outline-none group-last:rounded-b-md"
                    onClick={opt.action}
                  >
                    <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-right">
                      {opt.label}
                    </span>
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
