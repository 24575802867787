import { useOutsideClick } from '@/hooks';
import { PropsWithChildren, useCallback, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ChevronLeft } from '../Icons';

type DropdownKey = string | number;

export type DropdownOption<TValue extends DropdownKey> = {
  label: string;
  value: TValue;
};

type DropdownArrowButtonProps = PropsWithChildren<{
  placeholder: string;
  selectedLabel?: string;
  className?: string;
}>;

export const DropdownArrowButton = ({
  selectedLabel,
  placeholder,
  className,
  children,
}: DropdownArrowButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const onOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  useOutsideClick(ref, onOutsideClick, isOpen);

  return (
    <button
      ref={ref}
      className={twMerge(
        'relative min-w-32 text-sm font-normal',
        isOpen && 'rounded-b-none',
        className,
      )}
      onClick={() => setIsOpen((prev) => !prev)}
    >
      <div className="flex items-center justify-between gap-1 rounded-md bg-brightGray px-2 py-2">
        <span className={twMerge(!selectedLabel && 'text-spartanBlue')}>
          {selectedLabel || placeholder}
        </span>
        <ChevronLeft
          className={twMerge(
            ' size-3 -rotate-90 text-blueNuit transition-transform',
            isOpen && 'rotate-90',
          )}
        />
      </div>
      {isOpen && children}
    </button>
  );
};
