import { PropsWithChildren, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

interface PageContainerProps {
  className?: string;
}

export const PageContainer = ({
  className,
  children,
}: PropsWithChildren<PageContainerProps>): ReactElement => {
  return (
    <div
      className={twMerge('flex flex-grow flex-col gap-5 p-12 pt-0', className)}
    >
      {children}
    </div>
  );
};
