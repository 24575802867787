import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { organizationsRepo } from '@/constants/providers';
import { OrganizationModel } from '@/modules/Organizations/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { OrganizationKeys } from './keys';

export const useGetOrganizationsByInfrastructureCost = (
  infrastructureCost: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<OrganizationModel[]>,
    any,
    DataWithPagination<OrganizationModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<DataWithPagination<OrganizationModel[]>>(
    [
      ...OrganizationKeys.BY_INFRASTRUCTURE_COST_ID_PAGED(infrastructureCost, {
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      }),
      currentSelectedTenant,
    ],
    () =>
      organizationsRepo.getOrganizationsByInfrastructureCost(
        infrastructureCost,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
};

export const useGetAllOrganizationsByInfrastructureCost = (
  infrastructureCostId?: number,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<OrganizationModel[]>(
    [
      ...OrganizationKeys.BY_INFRASTRUCTURE_COST_ID(infrastructureCostId),
      currentSelectedTenant?.id,
    ],
    () => {
      if (!infrastructureCostId) return [];
      return organizationsRepo.getAllOrganizationsByInfrastructureCost(
        infrastructureCostId,
      );
    },
    {
      enabled: !!infrastructureCostId,
    },
  );
};
