import { useAddAccess } from '@/modules/Users/queries/add-access';
import { useRemoveAccess } from '@/modules/Users/queries/remove-access';
import { UserKey } from '@/modules/Users/queries/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useToggleUserAccess = () => {
  const { mutate: addAccess } = useAddAccess();
  const { mutate: removeAccess } = useRemoveAccess();
  const queryClient = useQueryClient();

  const onChange = useCallback(
    (checked: boolean, userId: number) => {
      if (checked) {
        addAccess(userId, {
          onSuccess: () => {
            queryClient.invalidateQueries(UserKey.ALL);
          },
        });
      } else {
        removeAccess(userId, {
          onSuccess: () => {
            queryClient.invalidateQueries(UserKey.ALL);
          },
        });
      }
    },
    [addAccess, queryClient, removeAccess],
  );

  return {
    onChange,
  };
};
