/**
 * Current Format:
 *
 * "," -> for decimal places
 *
 * "." -> for thousands
 */
export const formatNumber = (nr: number, decimals = 2) => {
  if (!nr) {
    return '<0.00';
  }
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    useGrouping: true,
  }).format(nr);
};
