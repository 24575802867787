import { TenantContext } from '@/components';
import { usersRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { UserModel } from '../domain';
import { UserKey } from './types';

export const useGetUserByAccessTokenAndTenantId = (
  token: string | undefined,
  tenantId: number | undefined,
) =>
  useQuery<UserModel | undefined>(
    [...UserKey.CURRENT_USER, token, tenantId],
    () => {
      if (!token || !tenantId) return;
      return usersRepo.getUserByAccessTokenAndTenantId(token, tenantId);
    },
    {
      enabled: !!token && !!tenantId,
    },
  );

export const useGetCurrentUser = () => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<UserModel | undefined>(
    [...UserKey.CURRENT_USER, currentSelectedTenant?.id],
    () => {
      return usersRepo.getCurrentUser();
    },
  );
};
