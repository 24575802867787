import { UserAvatar } from '@/components';
import { CardContainer } from '@/components/CardContainer/CardContainer';
import { CardSkeleton } from '@/components/CardSkeleton';
import { Container } from '@/components/Container';
import { PageDetailsHeaderRow } from '@/components/PageDetailsHeaderRow/PageDetailsHeaderRow';
import { StatusCard } from '@/components/StatusCard/StatusCard';
import { UserModel } from '@/modules/Users/domain';
import { trunc } from '@/utils';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditUserModal } from '../EditUserModal';

interface UserHeaderRowProps {
  user?: UserModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => void;
  handleAccess: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => void;
  status: boolean;
}

export const UserHeaderRow = ({
  user,
  handleStatus,
  handleAccess,
}: UserHeaderRowProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return !user ? (
    <Container>
      <CardSkeleton />
    </Container>
  ) : (
    <div>
      <PageDetailsHeaderRow
        before={
          <UserAvatar
            size="medium-large"
            name={user.name}
            imageId={user.profileImageFileId}
          />
        }
        title={user.name ?? ''}
        description={user.email}
        actions={
          <>
            <CardContainer className="gap-6 text-sm">
              <span className="font-black">{t('hour_rate')}</span>
              <span>{user.hourRate && trunc(user.hourRate, 2)}€</span>
            </CardContainer>
            <StatusCard
              label={t('access')}
              handleStatus={handleAccess}
              status={user.access}
            />
            <StatusCard handleStatus={handleStatus} status={user.status} />
          </>
        }
        onEditClick={handleModalState}
      />
      {isOpen && (
        <EditUserModal
          isOpen={isOpen}
          handleClose={handleModalState}
          disableFields={['email']}
          user={user}
        />
      )}
    </div>
  );
};
