import { DEFAULT_PAGE_SIZE, SortDirection } from '@/components/Table/types';
import { HttpClient } from '@/core/http/http-client';
import { DeleteProfileImageJson } from '@/modules/types';
import { ApiResponse, DataWithPagination } from '@/shared/types';
import { DateRange } from 'react-day-picker';
import { CompanyModel } from '../domain';
import {
  companiesMapper,
  companiesWithPaginationMapper,
  companyAutomationsPerformanceMapper,
  companyMapper,
} from '../mappers';
import {
  ApiResponseCompanies,
  ApiResponseCompany,
  CompanyAutomationPerformanceJson,
  CompanyJson,
  PagedCompanies,
} from '../types';
import { AttachUserCompanyModel } from '../types/attach-user-company-model';
import { DisassociateCompanyUserModel } from '../types/disassociate-company-user-model';
import { CompanyRepository } from './company.repository';

export class HttpCompanyRepository extends CompanyRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  async getCompanies(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<CompanyModel[]>> {
    const companies = await this.client.get<
      ApiResponseCompanies<PagedCompanies>
    >({
      url: '/me/companies',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return companiesWithPaginationMapper(companies);
  }

  updateCompany = async (data: FormData): Promise<CompanyModel> => {
    const updatedCompany = await this.client.put<CompanyJson>(
      `${data.get('id')}`,
      data,
    );
    return companyMapper(updatedCompany);
  };

  createCompany = async (data: FormData): Promise<CompanyModel> => {
    const newCompany = await this.client.post<CompanyJson>({ body: data });
    return companyMapper(newCompany);
  };

  attachUserCompany = async (data: AttachUserCompanyModel): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `associate-user`,
    });
  };

  uploadProfilePicture = async (data: FormData): Promise<unknown> => {
    return await this.client.post<unknown>({
      url: `${data.get('EntityId')}/profile-picture`,
      body: data,
    });
  };

  deleteProfilePicture = async (
    data: DeleteProfileImageJson,
  ): Promise<unknown> => {
    return await this.client.delete<unknown>(
      `${data.entityId}/profile-picture/${data.fileId}`,
    );
  };

  async getCompany(id: number): Promise<CompanyModel> {
    const company = await this.client.get<ApiResponseCompany<CompanyJson>>({
      url: `${id}`,
    });
    return companyMapper(company.data.company);
  }

  async getCompanyAutomationsPerformanceById(
    companyId: number,
    dateRange?: DateRange,
  ) {
    const companyPerformance = await this.client.get<
      ApiResponse<CompanyAutomationPerformanceJson>
    >({
      url: `${companyId}/automations-performance`,
      query: {
        fromDate:
          dateRange?.from !== dateRange?.to
            ? dateRange?.from?.toISOString()
            : undefined,
        toDate: dateRange?.to?.toISOString(),
      },
    });

    return companyAutomationsPerformanceMapper(companyPerformance.data);
  }

  enableCompany = async (id: number): Promise<ApiResponse<unknown>> => {
    return this.client.patch(`${id}/enable`);
  };

  disableCompany = async (id: number): Promise<ApiResponse<unknown>> => {
    return this.client.patch(`${id}/disable`);
  };

  disassociateUser = async (
    data: DisassociateCompanyUserModel,
  ): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `disassociate-user`,
    });
  };

  async getCompaniesByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<CompanyModel[]>> {
    const companies = await this.client.get<
      ApiResponseCompanies<PagedCompanies>
    >({
      url: `/organizations/${organizationId}/companies`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return companiesWithPaginationMapper(companies);
  }

  async getAllCompaniesByOrganization(
    organizationId: number,
  ): Promise<CompanyModel[]> {
    const companies = await this.client.get<
      ApiResponseCompanies<CompanyJson[]>
    >({
      url: `/organizations/${organizationId}/companies/base-info`,
    });

    return companiesMapper(companies.data.companies);
  }

  async getEligibleCompaniesByInfrastructureCost(
    infrastructureCostId: number,
  ): Promise<CompanyModel[]> {
    const companies = await this.client.get<
      ApiResponseCompanies<CompanyJson[]>
    >({
      url: `/infrastructure-costs/${infrastructureCostId}/eligible-companies`,
    });

    return companiesMapper(companies.data.companies);
  }

  async getCompaniesByUser(
    userId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<CompanyModel[]>> {
    const companies = await this.client.get<
      ApiResponseCompanies<PagedCompanies>
    >({
      url: `/users/${userId}/companies`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return companiesWithPaginationMapper(companies);
  }

  async getCompaniesByInfrastructureCost(
    infrastructureCostId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<CompanyModel[]>> {
    const companies = await this.client.get<
      ApiResponseCompanies<PagedCompanies>
    >({
      url: `/infrastructure-costs/${infrastructureCostId}/companies`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return companiesWithPaginationMapper(companies);
  }

  addRatesFile = async (data: FormData): Promise<unknown> => {
    return await this.client.post<unknown>({
      url: `rates`,
      body: data,
    });
  };

  async deleteCompany(id: number): Promise<ApiResponse<unknown>> {
    return await this.client.delete(`${id}`);
  }
}
