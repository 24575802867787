import { TenantContext } from '@/components';
import { companiesRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { CompanyModel } from '../domain';
import { CompanyKeys } from './keys';

export const useGetCompany = (id: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<CompanyModel>(
    [...CompanyKeys.BY_ID(id), currentSelectedTenant?.id],
    () => companiesRepo.getCompany(id),
    {
      enabled: !!id,
    },
  );
};
