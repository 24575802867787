import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import { TableRow, TableSort } from '@/components/Table/types';
import { queryClient } from '@/constants';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { HttpStatusCode } from 'axios';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useToggleAutomationStatus } from '../../hooks/use-toggle-automation-status';
import { useGetAutomations } from '../../queries';
import { useDeleteAutomation } from '../../queries/delete-automation';
import { AutomationKey } from '../../queries/keys';
import { CreateAutomationModal } from './components/CreateAutomationModal';
import {
  getAutomationColumnsNamedMapped,
  getAutomationsColumns,
} from './utils';

export const AutomationsPage = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const navigate = useNavigate();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const { onChange: onAutomationStatusChange } = useToggleAutomationStatus();

  const handleModalState = () => {
    setIsOpen(!isOpen);
  };

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  const {
    data: automations,
    isFetching: isLoadingAutomations,
    isError,
    error,
  } = useGetAutomations(
    page + 1,
    searchTerm,
    getAutomationColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
  );

  useEffect(() => {
    if (
      isError &&
      error &&
      (error as HttpError<unknown>)?.code === HttpStatusCode.NotFound
    ) {
      navigate(RoutePath.notFound());
    }
  }, [isError, error, navigate]);

  const { mutate: deleteOrganization, isLoading: isLoadingDelete } =
    useDeleteAutomation();

  const handleDelete = (automationId: number) => {
    deleteOrganization(automationId, {
      onError: () => {
        toast.error(t('error_deleting_automation'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(AutomationKey.ALL);
        toast.success(t('success_deleting_automation'));
      },
    });
  };

  const routeChange = (row?: TableRow) => {
    if (!row) {
      return;
    }
    const path = `${row?.id}`;
    navigate(path);
  };

  const handleOnStatusChange = useCallback(
    (checked: boolean, row: TableRow) => {
      onAutomationStatusChange(checked, +row.id);
    },
    [onAutomationStatusChange],
  );

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('automations')} />
      <FilterSearchBar
        total={automations?.totalRows || 0}
        title={t('automations')}
        variant="secondary"
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
        otherActions={
          <Button
            onClick={handleModalState}
            variant="primary"
            className="px-5 py-2.5"
          >
            {t('add')}
          </Button>
        }
      />
      <Table
        columns={getAutomationsColumns({
          onClick: routeChange,
          disableDeleteMessage: t('delete_automation'),
          onDeleteClick(row) {
            handleDelete(row?.id as number);
          },
          onStatusChange: handleOnStatusChange,
        })}
        rows={automations?.items ?? []}
        isLoading={isLoadingAutomations || isLoadingDelete}
        noResultsLabel={t('no_automations_available')}
        withPagination
        totalItems={automations?.totalRows}
        totalPages={automations?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={setTableSort}
      />
      {isOpen && (
        <CreateAutomationModal handleClose={handleModalState} isOpen={isOpen} />
      )}
    </CustomPageContainer>
  );
};
