import { LoggedLayout } from '@/components';
import { TenantProvider } from '@/components/TenantProvider';
import { LoadingPage } from '@/modules/Auth/pages';
import { ReactElement } from 'react';
import { withAuthenticationRequired } from 'react-oidc-context';

interface PrivateRouteProps {
  showSideNav?: boolean;
  children: ReactElement | null;
}

const Route = ({
  children,
  showSideNav = true,
}: PrivateRouteProps): ReactElement => {
  return (
    <TenantProvider>
      <LoggedLayout showSideNav={showSideNav}>{children}</LoggedLayout>
    </TenantProvider>
  );
};

export const PrivateRoute = withAuthenticationRequired(Route, {
  OnRedirecting: () => <LoadingPage></LoadingPage>,
});
