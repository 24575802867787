import {
  Button,
  Collapse,
  CustomPageContainer,
  EditPencil,
  Table,
  UnLink,
  WorkflowsIcon,
} from '@/components';
import { BreadcrumbItem } from '@/components/Breadcrumbs';
import { LabelIcon } from '@/components/LabelIcon/LabelIcon';
import { PageDetailsActions } from '@/components/PageDetailsActions/PageDetailsActions';
import { TableRow } from '@/components/Table/types';
import { queryClient } from '@/constants';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router';
import { TaskModel } from '@/modules/Tasks/domain';
import { EditTaskModal } from '@/modules/Tasks/pages/TasksPage/components/EditTaskModal';
import { useOrderTask } from '@/modules/Tasks/queries';
import {
  automatedColumnConfig,
  businessAreaNameColumnConfig,
  codeColumnConfig,
  estimatedCostColumnConfig,
  nameWithAvatarColumnConfig,
  periodColumnConfig,
  statusColumnConfig,
  usersCountColumnConfig,
  workloadColumnConfig,
} from '@/utils';
import { dividerColumnConfig } from '@/utils/tableColumns/divider-column-config';
import { processCompanyNameColumnConfig } from '@/utils/tableColumns/process-company-name-column-config';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import { DndContext } from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';
import { HttpStatusCode } from 'axios';
import { t } from 'i18next';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useDisableWorkflow,
  useDisassociateTaskFromWorkflow,
  useEnableWorkflow,
  useGetWorkflow,
  WorkflowKey,
} from '../../queries';
import {
  WorkflowPerformanceCards,
  WorkflowPerformanceCharts,
  WorkflowTaskModal,
} from './components';
import { WorkflowHeaderRow } from './components/WorkflowHeaderRow';

export const WorkflowDetailPage = (): ReactElement => {
  const [tasks, setTasks] = useState<TaskModel[]>([]);
  const [status, setStatus] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEditTaskModal, setIsOpenEditTaskModal] =
    useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<number>();
  const navigate = useNavigate();
  const { workflowId } = useParams<{ workflowId: any }>();

  const { data: workflow, isError, error } = useGetWorkflow(workflowId);

  const handleModalState = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isError && error) {
      if ((error as HttpError<unknown>)?.code === HttpStatusCode.NotFound) {
        navigate(RoutePath.notFound());
      } else {
        toast.error(t('error_get_workflow_details'));
      }
    }
  }, [isError, error, navigate]);

  useEffect(() => {
    setStatus(workflow?.status === 'True'); //TODO: Fix status to boolean
  }, [workflow]);

  const { mutate: enableWorkflow } = useEnableWorkflow();
  const { mutate: disableWorkflow } = useDisableWorkflow();
  const { mutate: disassociateTask } = useDisassociateTaskFromWorkflow();
  const { mutate: orderTask } = useOrderTask();

  const handleDisassociateTask = useCallback(
    (id: number) => {
      if (!workflowId) {
        return toast.error(t('generic_errors'));
      }
      disassociateTask(
        {
          workflowId,
          taskId: id,
        },
        {
          onError: () => {
            toast.error(t('error_disassociate_tasks_from_workflow'));
          },
          onSuccess: () => {
            toast.success(t('success_disassociate_tasks_from_workflow'));
            setTasks([]);
            queryClient.invalidateQueries(WorkflowKey.ALL);
          },
        },
      );
    },
    [disassociateTask, workflowId],
  );

  const tempTasks = useMemo(() => {
    return tasks.length === workflow?.tasks.length
      ? tasks
      : workflow?.tasks || [];
  }, [workflow?.tasks, tasks]);

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!workflow?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        enableWorkflow(workflow?.id, {
          onError: () => {
            toast.error(t('error_enable_workflow'));
          },
          onSuccess: () => {
            toast.success(t('success_enable_workflow'));
          },
        });
      } else {
        disableWorkflow(workflow?.id, {
          onError: () => {
            toast.error(t('error_disable_workflow'));
          },
          onSuccess: () => {
            toast.success(t('success_disable_workflow'));
          },
        });
      }
    },
    [workflow, enableWorkflow, disableWorkflow],
  );

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const routeChangeTask = (row?: TableRow) => {
    const path = `/tasks/${row?.id}`;
    navigate(path);
  };

  const getTaskPos = (id: number) =>
    tempTasks.findIndex((task) => task?.id === id);

  const handleDragEnd = async (event: { active: any; over: any }) => {
    const { active, over } = event;
    if (active.id === over.id) return;

    const originalPos = getTaskPos(active.id);
    const newPos = getTaskPos(over.id);
    const newPositions = arrayMove(tempTasks, originalPos, newPos);
    setTasks(newPositions);

    const payload = {
      id: active.id,
      order: newPositions.findIndex((el) => el.id === active.id) + 1,
    };

    return orderTask(payload);
  };

  const breadcrumbs = useMemo<BreadcrumbItem[]>(() => {
    if (!workflow) {
      return [];
    }

    return [
      {
        label: workflow.organization,
        href: RoutePath.organizationDetail(workflow.organizationId.toString()),
      },
      {
        label: workflow.company,
        href: RoutePath.companyDetail(workflow.companyId.toString()),
      },
      {
        label: workflow.businessAreaName,
        href: RoutePath.businessAreaDetail(workflow.businessAreaId.toString()),
      },
      {
        label: workflow.processName,
        href: RoutePath.processDetail(workflow.processId.toString()),
      },
      {
        label: t('workflows'),
        href: RoutePath.workflows(),
      },
      {
        label: workflow.name,
      },
    ];
  }, [workflow]);

  const handleCloseEditTaskModal = () => {
    setIsOpenEditTaskModal(false);
    setSelectedTask(undefined);
  };

  const handleEditTaskModalState = (taskId: number) => {
    setIsOpenEditTaskModal(!isOpen);
    setSelectedTask(taskId);
  };

  return (
    <CustomPageContainer>
      <PageDetailsActions onBack={routeChange} breadcrumbs={breadcrumbs}>
        <LabelIcon
          label={t('workflow')}
          icon={<WorkflowsIcon className="size-5 text-blueNuit" />}
        />
      </PageDetailsActions>
      <WorkflowHeaderRow
        workflow={workflow}
        handleStatus={handleStatus}
        status={status}
      />
      <WorkflowPerformanceCharts workflowPerformance={workflow} />
      <WorkflowPerformanceCards workflowId={workflowId} />
      <div className="flex flex-row justify-between pb-5">
        <h2>{workflow?.processName}</h2>
        <Button onClick={handleModalState} variant="primary" className="h-9">
          {t('add_task')}
        </Button>
      </div>
      <WorkflowTaskModal
        handleClose={handleModalState}
        isOpen={isOpen}
        workflow={workflow}
        disableFields={[
          'businessAreaId',
          'organizationId',
          'companyId',
          'workflowId',
        ]}
      />
      <Collapse
        title={workflow?.name}
        subText={`(${workflow?.tasks.length} Tasks)`}
      >
        <DndContext
          onDragEnd={handleDragEnd}
          modifiers={[
            restrictToVerticalAxis,
            restrictToFirstScrollableAncestor,
          ]}
        >
          <Table
            columns={[
              nameWithAvatarColumnConfig({ onClick: routeChangeTask }),
              codeColumnConfig(),
              processOrganizationNameColumnConfig(),
              processCompanyNameColumnConfig(),
              businessAreaNameColumnConfig(),
              statusColumnConfig(),
              usersCountColumnConfig(),
              dividerColumnConfig({ key: 'divider-1' }),
              periodColumnConfig(),
              workloadColumnConfig(),
              estimatedCostColumnConfig(),
              dividerColumnConfig({ key: 'divider-2' }),
              automatedColumnConfig(),
            ]}
            rows={tempTasks}
            noResultsLabel={t('no_tasks_available')}
            draggable
            actionCallbackDisassociate={handleDisassociateTask}
            actionCallbackEdit={handleEditTaskModalState}
            renderUnlink={() => <UnLink className="text-gray-500 opacity-50" />}
            renderEdit={() => (
              <EditPencil className="text-gray-500 opacity-50" />
            )}
            showDots
          />
        </DndContext>
      </Collapse>
      {selectedTask && (
        <EditTaskModal
          isOpen={isOpenEditTaskModal}
          id={selectedTask}
          handleClose={handleCloseEditTaskModal}
        />
      )}
    </CustomPageContainer>
  );
};
