import { useUpdateUserRate } from '@/modules/Users/queries';
import { UserKey } from '@/modules/Users/queries/types';
import { HourlyRateJson, UserRateSchemaType } from '@/modules/Users/types';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { HourlyRateModal } from '../HourlyRateModal';

interface EditHourlyRateModalProps {
  isOpen: boolean;
  handleClose: () => void;
  userId?: number;
  jiraAccountId?: string;
  rate?: number;
  startDate?: Date;
  endDate?: Date;
  hourlyRateId: number;
}

export const EditHourlyRateModal = ({
  isOpen,
  handleClose,
  userId,
  jiraAccountId,
  rate,
  startDate,
  endDate,
  hourlyRateId,
}: EditHourlyRateModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useUpdateUserRate();

  const handleConfirm = (data: UserRateSchemaType) => {
    const convertedData: HourlyRateJson = {
      userId: userId ?? 0,
      value: Number(data.rate),
      startDate: data.startDate,
      endDate: data.endDate,
      jiraAccountId: data.jiraAccountId,
      hourlyRateId: hourlyRateId,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_updating_hourly_rate'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(UserKey.ALL);
        handleClose();
        toast.success(t('success_updating_hourly_rate'));
      },
    });
  };

  return (
    <HourlyRateModal
      handleOnSubmit={handleConfirm}
      handleClose={handleClose}
      isLoading={isLoading}
      type="edit"
      isOpen={isOpen}
      title={t('edit_hourly_rate')}
      jiraAccountId={jiraAccountId}
      startDate={startDate}
      endDate={endDate}
      value={rate}
    />
  );
};
