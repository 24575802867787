import { TenantContext } from '@/components';
import { organizationsRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { OrganizationModel } from '../domain';
import { OrganizationKeys } from './keys';

export const useGetOrganization = (orgId: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<OrganizationModel>(
    [...OrganizationKeys.BY_ID(orgId), currentSelectedTenant?.id],
    () => organizationsRepo.getOrganization(orgId),
    {
      enabled: !!orgId,
      cacheTime: 1000 * 60 * 5,
    },
  );
};
