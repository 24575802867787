import { PaginationParams } from '@/shared/types';
import { DateRange } from 'react-day-picker';

export abstract class WorkflowKey {
  static ALL = ['workflows'];
  static LIST = [...WorkflowKey.ALL, 'list'];
  static LIST_PAGED = (pagination: PaginationParams) => [
    ...WorkflowKey.LIST,
    pagination,
  ];
  static BY_ID = (workflowId: number) => [
    ...WorkflowKey.ALL,
    'by-id',
    workflowId,
  ];
  static BY_BUSINESS_AREA = (businessAreaId: number, withProcess?: boolean) => [
    ...WorkflowKey.ALL,
    'by-business-area',
    businessAreaId,
    withProcess,
  ];
  static BY_BUSINESS_AREA_PAGED = (
    businesssAreaId: number,
    pagination: PaginationParams,
  ) => [...WorkflowKey.BY_BUSINESS_AREA(businesssAreaId), pagination];
  static BY_PROCESS = (processId: number) => [
    ...WorkflowKey.ALL,
    'by-process',
    processId,
  ];
  static BY_ORGANIZATION = (organizationId: number) => [
    ...WorkflowKey.ALL,
    'by-organization',
    organizationId,
  ];
  static BY_ORGANIZATION_PAGED = (
    organizationId: number,
    pagination: PaginationParams,
  ) => [...WorkflowKey.BY_ORGANIZATION(organizationId), pagination];
  static BY_COMPANY = (companyId: number) => [
    ...WorkflowKey.ALL,
    'by-company',
    companyId,
  ];
  static BY_COMPANY_PAGED = (
    companyId: number,
    pagination: PaginationParams,
  ) => [...WorkflowKey.BY_COMPANY(companyId), pagination];
  static BY_PROCESS_AUTOMATION = (processId: number) => [
    ...WorkflowKey.ALL,
    'by-process-automation',
    processId,
  ];

  static AUTOMATIONS_PERFORMANCE = (
    workflowId: number,
    dateRange?: DateRange,
  ) => [...WorkflowKey.BY_ID(workflowId), 'automations-performance', dateRange];
}
