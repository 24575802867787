import { PropsWithChildren, ReactElement } from 'react';

interface PageHeaderProps {
  title: string;
}

export const PageHeader = ({
  title,
  children,
}: PropsWithChildren<PageHeaderProps>): ReactElement => {
  return (
    <div className="flex items-center justify-between">
      <h1>{title}</h1>
      {children}
    </div>
  );
};
