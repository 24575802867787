import { PerformanceCardsContainer } from '@/components/PerformanceCardsContainer';
import { useAutomationPerformance } from '@/modules/Automations/queries/use-automation-performance';
import { calculateValueDifference } from '@/utils';
import { ReactElement, useState } from 'react';
import { DateRange } from 'react-day-picker';

interface AutomationPerformanceCardsProps {
  automationId: number;
}

export const AutomationPerformanceCards = ({
  automationId,
}: AutomationPerformanceCardsProps): ReactElement => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const { data, isLoading } = useAutomationPerformance(automationId, dateRange);
  const investmentReturnDifference = calculateValueDifference(
    data?.totalReturn || 0,
    data?.totalInvestment || 0,
    'coin',
  );

  return (
    <PerformanceCardsContainer
      predictedManualTime={data?.predictedManualTime || 0}
      automationRunTime={data?.automationRunTime || 0}
      totalInvestment={data?.totalInvestment || 0}
      totalReturn={data?.totalReturn || 0}
      isLoading={isLoading}
      investmentReturnDifference={investmentReturnDifference}
      totalRuns={data?.totalExecutions}
      dateRange={dateRange}
      setDateRange={setDateRange}
    />
  );
};
