import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TagType } from '../../constants';

type TimeTagProps = {
  type: TagType;
  onClick: (type: TagType, value?: number) => void;
  value?: number;
};

export const TimeTag = ({ type, value, onClick }: TimeTagProps) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onClick(type, value);
  }, [onClick, type, value]);

  return (
    <button
      type="button"
      onClick={handleClick}
      className="text-primary-300 h-fit w-fit rounded-md border border-gray-300 bg-gray-200  p-1 text-sm font-semibold"
    >
      {t(type, { value })}
    </button>
  );
};
