import { Spinner } from '@/components/Spinner';
import { ReactElement } from 'react';

export const LoadingPage = (): ReactElement => {
  return (
    <div className="flex h-screen items-center justify-center">
      <Spinner />
    </div>
  );
};
