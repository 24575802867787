import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { workflowsRepo } from '@/constants/providers';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { WorkflowKey } from './keys';

export const useGetWorkflowsByCompany = (
  companyId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<WorkflowModel[]>,
    any,
    DataWithPagination<WorkflowModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<DataWithPagination<WorkflowModel[]>>(
    [
      ...WorkflowKey.BY_COMPANY_PAGED(companyId, {
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      }),
      currentSelectedTenant?.id,
    ],
    () =>
      workflowsRepo.getWorkflowsByCompany(
        companyId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
};
