import { TenantContext } from '@/components';
import { automationsRepo } from '@/constants/providers';
import { QueryOptions } from '@/modules/types';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { AutomationModel } from '../domain';
import { AutomationKey } from './keys';

export const useGetAutomation = (
  id: number,
  options?: QueryOptions<AutomationModel>,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery<AutomationModel>(
    [...AutomationKey.BY_ID(id), currentSelectedTenant?.id],
    () => automationsRepo.getAutomation(id),
    {
      ...options,
      enabled: !!id,
    },
  );
};
