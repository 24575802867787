import { Button, FilterSearchBar, Table } from '@/components';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { CreateWorkflowModal } from '@/modules/Workflows/pages/WorkflowsPage/components/CreateWorkflowModal';
import { getWorkflowsColumns } from '@/modules/Workflows/pages/WorkflowsPage/utils';
import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TabContext } from '../TabContainer';
import { TableRow } from '../Table/types';

interface WorkflowsTabProps {
  canCreate?: boolean;
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  disableFields?: string[];
}

export const WorkflowsTab = ({
  canCreate,
  organizationId,
  disableFields,
  companyId,
  businessAreaId,
}: WorkflowsTabProps): ReactElement => {
  const { t } = useTranslation();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const navigate = useNavigate();
  const {
    data: workflows,
    isLoading: isLoadingWorkflows,
    isOpen,
    page,
    onPageChange,
    onSort,
    handleSearchChange,
    handleModalState,
  } = useContext(TabContext);

  const routeChange = (row?: TableRow) => {
    const path = `/workflows/${row?.id}`;
    navigate(path);
  };

  return (
    <>
      <FilterSearchBar
        total={workflows?.totalRows || 0}
        title={t('workflows')}
        onSearch={handleSearchChange}
        variant={'secondary'}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
        otherActions={
          <>
            {canCreate && (
              <div className="flex items-center">
                <Button onClick={handleModalState} variant="primary">
                  {t('add')}
                </Button>
              </div>
            )}
          </>
        }
      />
      <Table
        onRowClick={routeChange}
        columns={getWorkflowsColumns()}
        rows={workflows?.items ?? []}
        isLoading={isLoadingWorkflows}
        noResultsLabel={t('no_workflows_available')}
        withPagination
        totalItems={workflows?.totalRows}
        totalPages={workflows?.totalPages}
        currentPage={page}
        onSort={onSort}
        onPageChange={onPageChange}
      />
      {isOpen && (
        <CreateWorkflowModal
          isOpen={isOpen}
          handleClose={handleModalState}
          organizationId={organizationId}
          disableFields={disableFields}
          companyId={companyId}
          businessAreaId={businessAreaId}
        />
      )}
    </>
  );
};
