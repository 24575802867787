import { organizationsRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { OrganizationKeys } from './keys';

export const useGetOrganizationAutomationsPerformance = (
  organizationId: number,
  dateRange?: DateRange,
) => {
  return useQuery({
    queryKey: OrganizationKeys.AUTOMATIONS_PERFORMANCE(
      organizationId,
      dateRange,
    ),
    queryFn: () =>
      organizationsRepo.getOrganizationAutomationsPerformanceById(
        organizationId,
        dateRange,
      ),
    cacheTime: 1000 * 60 * 5,
  });
};
