import { TenantContext } from '@/components';
import { automationsRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { AutomationKey } from './keys';

export const useGetAssociatedTasks = (automationId: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery(
    [
      ...AutomationKey.TASK_PERFORMANCE(automationId),
      currentSelectedTenant?.id,
    ],
    () => automationsRepo.getTasksPerformanceByAutomationId(automationId),
    {
      enabled: !!automationId,
    },
  );
};
