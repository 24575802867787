import { companiesRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { CompanyKeys } from './keys';

export const useGetCompanyAutomationsPerformance = (
  companyId: number,
  dateRange?: DateRange,
) => {
  return useQuery({
    queryKey: CompanyKeys.AUTOMATIONS_PERFORMANCE(companyId, dateRange),
    queryFn: () =>
      companiesRepo.getCompanyAutomationsPerformanceById(companyId, dateRange),
    cacheTime: 1000 * 60 * 5,
  });
};
