import { Collapse, LinkBehavior, Table } from '@/components';
import { Checkbox } from '@/components/Checkbox';
import { TableRow } from '@/components/Table/types';
import { queryClient } from '@/constants';
import { RoutePath } from '@/core/router';
import { useAssociateAutomation } from '@/modules/Tasks/queries/associate-automation';
import { useDisassociateAutomation } from '@/modules/Tasks/queries/disassociate-automation';
import {
  useGetAllWorkflowsByProcessIdAutomation,
  WorkflowKey,
} from '@/modules/Workflows/queries';
import {
  automatedColumnConfig,
  businessAreaNameColumnConfig,
  codeColumnConfig,
  estimatedCostColumnConfig,
  nameWithAvatarColumnConfig,
  periodColumnConfig,
  statusColumnConfig,
  usersCountColumnConfig,
  workloadColumnConfig,
} from '@/utils';
import { dividerColumnConfig } from '@/utils/tableColumns/divider-column-config';
import { processCompanyNameColumnConfig } from '@/utils/tableColumns/process-company-name-column-config';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const AssociatedTasksTab = ({
  processId = '',
  automationId,
}: {
  processId?: string;
  automationId: number;
}) => {
  const { data: workflows } = useGetAllWorkflowsByProcessIdAutomation(
    parseInt(processId),
  );
  const { mutate: associateAutomation } = useAssociateAutomation();
  const { mutate: disassociateAutomation } = useDisassociateAutomation();
  const navigate = useNavigate();

  const selectedTasks: number[] = useMemo(
    () =>
      workflows?.flatMap((workflow) =>
        workflow.tasks
          .filter(
            (task) => task.automationId && task.automationId === automationId,
          )
          .map((task) => task.id),
      ) ?? [],
    [workflows, automationId],
  );

  const handleSelectTasks = useCallback(
    (taskId?: number) => {
      if (!taskId) return;
      if (!selectedTasks.includes(taskId)) {
        associateAutomation(
          { taskId, automationId },
          {
            onError: () => {
              toast.error(t('error_associating_automation'));
            },
            onSuccess: () => {
              queryClient.invalidateQueries(WorkflowKey.ALL);
              toast.success(t('success_associating_automation'));
            },
          },
        );
        return;
      }

      disassociateAutomation(
        { taskId, automationId },
        {
          onError: () => {
            toast.error(t('error_disassociating_automation'));
          },
          onSuccess: () => {
            queryClient.invalidateQueries(WorkflowKey.ALL);
            toast.success(t('success_disassociating_automation'));
          },
        },
      );
    },
    [selectedTasks, associateAutomation, disassociateAutomation, automationId],
  );

  const routeChange = (row?: TableRow) => {
    const path = `/tasks/${row?.id}`;
    navigate(path);
  };

  return (
    <>
      {!!workflows?.length && (
        <h1 className="mb-3 py-7">
          <LinkBehavior
            href={RoutePath.processDetail(workflows[0].processId.toString())}
          >
            {workflows[0].processName}
          </LinkBehavior>
        </h1>
      )}
      {workflows?.map((workflow, index) => (
        <Collapse
          key={index}
          title={workflow?.name}
          titleHref={RoutePath.workflowDetail(workflow?.id.toString())}
          subText={`(${workflow?.tasks.length} Tasks)`}
        >
          <Table
            columns={[
              nameWithAvatarColumnConfig({
                onClick: routeChange,
                className: 'min-w-[20vw] max-w-[20vw] w-[20vw]',
              }),
              codeColumnConfig(),
              processOrganizationNameColumnConfig(),
              processCompanyNameColumnConfig(),
              businessAreaNameColumnConfig(),
              statusColumnConfig(),
              usersCountColumnConfig(),
              dividerColumnConfig({ key: 'divider-1' }),
              periodColumnConfig(),
              workloadColumnConfig(),
              estimatedCostColumnConfig(),
              dividerColumnConfig({ key: 'divider-2' }),
              automatedColumnConfig(),
            ]}
            rows={workflow?.tasks || []}
            noResultsLabel={t('no_tasks_available')}
            renderUnlink={(id, disabled) => (
              <Checkbox
                checked={id ? selectedTasks.includes(id) : false}
                disabled={disabled}
                onClick={() => handleSelectTasks(id)}
              />
            )}
            highlightRows={selectedTasks}
            checkPropertyTodisable={'automationId'}
            checkValueToDisable={automationId}
            showDots
          />
        </Collapse>
      ))}
    </>
  );
};
