import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { workflowsRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { WorkflowModel } from '../domain';
import { WorkflowKey } from './keys';

export const useGetWorkflows = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<WorkflowModel[]>,
    any,
    DataWithPagination<WorkflowModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<DataWithPagination<WorkflowModel[]>>(
    [
      ...WorkflowKey.LIST_PAGED({ pageNumber, filter, sortBy, sortOrder }),
      currentSelectedTenant?.id,
    ],
    () => workflowsRepo.getWorkflows(pageNumber, filter, sortBy, sortOrder),
    { ...options, cacheTime: 1000 * 60 * 5 },
  );
};
