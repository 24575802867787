import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import { TableRow, TableSort } from '@/components/Table/types';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { getBusinessAreasChildRows } from '@/utils';
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useInvalidateBusinessAreaQueries } from '../../hooks/use-invalidate-business-areas-queries';
import { useToggleBusinessAreaStatus } from '../../hooks/use-toggle-business-area-status';
import { useDeleteBusinessArea, useGetBusinessAreas } from '../../queries';
import { CreateBusinessAreaModal } from './components/CreateBusinessAreaModal/CreateBusinessAreaModal';
import {
  getBusinessAreaColumnsNamedMapped,
  getBusinessAreasColumns,
} from './utils';

export const BusinessAreasPage = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const { onChange: onBusinessAreaStatusChange } =
    useToggleBusinessAreaStatus();
  const invalidateBusinessAreaQueries = useInvalidateBusinessAreaQueries();

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  const getAllBusinessAreasOptions = useMemo(
    () => ({
      onError: () => {
        toast.error(t('error_getting_business_areas'));
      },
    }),
    [t],
  );

  const { data: businessAreas, isFetching: isLoadingBusinessAreas } =
    useGetBusinessAreas(
      page + 1,
      searchTerm,
      getBusinessAreaColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
      tableSort?.[0]?.direction ?? 'ascending',
      getAllBusinessAreasOptions,
    );

  const { mutate: deleteBusinessArea, isLoading: isLoadingDelete } =
    useDeleteBusinessArea();

  const handleDelete = (businessAreaId: number) => {
    deleteBusinessArea(businessAreaId, {
      onError: () => {
        toast.error(t('error_deleting_ba'));
      },
      onSuccess: () => {
        invalidateBusinessAreaQueries();
        toast.success(t('success_deleting_ba'));
      },
    });
  };

  const routeChange = (row?: TableRow) => {
    const path = `${row?.id}`;
    navigate(path);
  };

  const onCreateBusinessArea = () => {
    handleSearchChange('');
    handleInputSearchChange('');
  };
  const onSort = (data: TableSort[]) => {
    setTableSort(data);
  };

  const rows = useMemo(
    () => getBusinessAreasChildRows(businessAreas?.items ?? []),
    [businessAreas?.items],
  );

  const handleOnStatusChange = useCallback(
    (checked: boolean, row: TableRow) => {
      onBusinessAreaStatusChange(checked, +row.id);
    },
    [onBusinessAreaStatusChange],
  );

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('business_areas')} />
      {isOpen && (
        <CreateBusinessAreaModal
          onSubmit={onCreateBusinessArea}
          handleClose={handleModalState}
          isOpen={isOpen}
        />
      )}
      <FilterSearchBar
        total={businessAreas?.totalBusinessAreas || 0}
        title={t('business_areas')}
        onSearch={handleSearchChange}
        variant="secondary"
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
        otherActions={
          <Button
            onClick={handleModalState}
            variant="primary"
            className="px-5 py-2.5"
          >
            {t('add')}
          </Button>
        }
      />
      {
        <Table
          columns={getBusinessAreasColumns({
            onClick: routeChange,
            disableDeleteMessage: t('delete_ba'),
            onDeleteClick(row) {
              handleDelete(row?.id as number);
            },
            onStatusChange: handleOnStatusChange,
          })}
          rows={rows}
          isLoading={isLoadingBusinessAreas || isLoadingDelete}
          noResultsLabel={t('no_business_areas_available')}
          withPagination
          totalItems={businessAreas?.totalRows}
          totalPages={businessAreas?.totalPages}
          currentPage={page}
          onPageChange={onPageChange}
          onSort={onSort}
          isTree
        />
      }
    </CustomPageContainer>
  );
};
