import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import { TableRow, TableSort } from '@/components/Table/types';
import { queryClient } from '@/constants';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { ReactElement, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useToggleOrganizationStatus } from '../../hooks/useToggleOrganizationStatus';
import { useDeleteOrganization, useGetOrganizations } from '../../queries';
import { OrganizationKeys } from '../../queries/keys';
import { CreateOrganizationModal } from './components/CreateOrganizationModal';
import {
  getOrganizationsColumns,
  getOrganizationsColumnsNamedMapped,
} from './utils';

export const OrganizationsPage = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const navigate = useNavigate();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const { onChange: onOrganizationStatusChange } =
    useToggleOrganizationStatus();

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  const { data: organizations, isFetching: isLoadingOrganizations } =
    useGetOrganizations(
      page + 1,
      searchTerm,
      getOrganizationsColumnsNamedMapped(tableSort[0]?.columnId ?? 'name'),
      tableSort[0]?.direction ?? 'ascending',
    );

  const { mutate: deleteOrganization, isLoading: isLoadingDelete } =
    useDeleteOrganization();

  const handleDelete = (organizationId: number) => {
    deleteOrganization(organizationId, {
      onError: () => {
        toast.error(t('error_deleting_organization'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(OrganizationKeys.ALL);
        toast.success(t('success_deleting_organization'));
      },
    });
  };

  const routeChange = (row?: TableRow) => {
    navigate(`${row?.id}`);
  };

  const handleOnStatusChange = useCallback(
    (checked: boolean, row: TableRow) => {
      onOrganizationStatusChange(checked, +row.id);
    },
    [onOrganizationStatusChange],
  );

  return (
    <CustomPageContainer className="gap-5">
      <CustomPageContainer.Header title={t('organizations')} />
      <FilterSearchBar
        total={organizations?.totalRows || 0}
        title={t('organizations')}
        variant="secondary"
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
        otherActions={
          <Button
            onClick={handleModalState}
            variant="primary"
            className="px-5 py-2.5"
          >
            {t('add')}
          </Button>
        }
      />
      <Table
        columns={getOrganizationsColumns({
          onClick: routeChange,
          disableDeleteMessage: t('delete_org'),
          onDeleteClick(row) {
            handleDelete(row?.id as number);
          },
          onStatusChange: handleOnStatusChange,
        })}
        rows={organizations?.items ?? []}
        isLoading={isLoadingOrganizations || isLoadingDelete}
        noResultsLabel={t('no_organizations_available')}
        withPagination
        totalItems={organizations?.totalRows}
        totalPages={organizations?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={setTableSort}
      />
      {isOpen && (
        <CreateOrganizationModal
          handleClose={handleModalState}
          isOpen={isOpen}
        />
      )}
    </CustomPageContainer>
  );
};
