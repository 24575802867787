import { PieChartCard } from '@/components/PieChartCard';
import { Dev, SuccessRate } from '@/core/domain/global-performance-model';
import { useGetChartColorsAndLabel } from '@/hooks';
import { AutomationOverallPerformanceModel } from '@/modules/Automations/domain';
import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface AutomationPerformanceChartsProps {
  automationPerformance?: AutomationOverallPerformanceModel;
}

export const AutomationPerformanceCharts = ({
  automationPerformance,
}: AutomationPerformanceChartsProps): ReactElement => {
  const { t } = useTranslation();
  const { rateChart, rateDevInvestChart } = useGetChartColorsAndLabel();

  const lastRunSuccessRateData = useMemo(
    () =>
      rateChart.map((chartData) => ({
        ...chartData,
        value:
          automationPerformance?.lastRunSuccessRate[
            chartData.id as keyof SuccessRate
          ] ?? 0,
      })),
    [automationPerformance, rateChart],
  );

  const overallSuccessRateData = useMemo(
    () =>
      rateChart.map((chartData) => ({
        ...chartData,
        value:
          automationPerformance?.overallSuccessRate[
            chartData.id as keyof SuccessRate
          ] ?? 0,
      })),
    [automationPerformance, rateChart],
  );

  const devTimeData = useCallback(() => {
    const data = rateDevInvestChart.map((chartData) => ({
      ...chartData,
      value: automationPerformance?.devTime[chartData.id as keyof Dev] ?? 0,
    }));

    const elementIndex = data.findIndex((item) => item.id === 'infrastructure');
    data.splice(elementIndex, 1);

    return data;
  }, [automationPerformance?.devTime, rateDevInvestChart]);

  const investmentData = useMemo(
    () =>
      rateDevInvestChart.map((chartData) => ({
        ...chartData,
        value:
          automationPerformance?.investment[chartData.id as keyof Dev] ?? 0,
      })),
    [automationPerformance?.investment, rateDevInvestChart],
  );

  return (
    <div className="flex flex-col gap-2 py-2">
      <div className="flex flex-1 gap-2">
        <PieChartCard
          data={lastRunSuccessRateData}
          chartTitle={t('last_run_performance')}
          valueType="percentage"
          subtitle={t('cases')}
          tooltip={t('last_run_performance_tooltip_description')}
        />
        <PieChartCard
          data={overallSuccessRateData}
          chartTitle={t('overall_performance')}
          valueType="percentage"
          subtitle={t('cases')}
          tooltip={t('overall_performance_tooltip_description')}
        />
      </div>
      <div className="flex gap-2">
        <PieChartCard
          data={devTimeData()}
          chartTitle={t('team_effort')}
          valueType="hours"
          subtitle={t('total_hours')}
          tooltip={t('team_effort_tooltip_description')}
        />
        <PieChartCard
          data={investmentData}
          chartTitle={t('investment')}
          valueType="coin"
          subtitle={t('total_eur')}
          tooltip={t('investment_tooltip_description')}
        />
      </div>
    </div>
  );
};
