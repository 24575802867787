import { ReactElement } from 'react';
import { AvatarSize, UserAvatar } from '@/components';
import { useGetCurrentUser } from '@/modules/Users/queries';

export interface CurrentUserAvatarProps {
  size: AvatarSize;
  isLoading?: boolean;
  className?: string;
}

export const CurrentUserAvatar = ({
  size = 'medium',
  isLoading,
  className,
}: CurrentUserAvatarProps): ReactElement => {
  const { data: user } = useGetCurrentUser();

  return (
    <UserAvatar
      className={className}
      size={size}
      name={user?.name}
      imageId={user?.profileImageFileId}
      isLoading={isLoading}
    />
  );
};
