import { ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { CardSkeleton } from '../CardSkeleton';

export interface CardProps {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  footer?: ReactNode;
  className?: string;
  isLoading?: boolean;
}

export const Card = ({
  title,
  subtitle,
  children,
  footer,
  className,
  isLoading,
}: CardProps): ReactElement => {
  return !isLoading ? (
    <span
      className={twMerge(
        'flex flex-1 flex-col gap-1.25 rounded-md border border-transparent bg-white p-2',
        className,
      )}
    >
      <div>
        <h6 className="whitespace-nowrap text-left font-black">{title}</h6>
        {subtitle && <p className="text-xs font-medium">{subtitle}</p>}
      </div>
      <div className="text-sm font-medium">{children}</div>
      {footer && <div className="mt-auto text-sm font-medium">{footer}</div>}
    </span>
  ) : (
    <CardSkeleton />
  );
};
