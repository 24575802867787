import { Container } from '@/components/Container';
import { AutomationModel } from '@/modules/Automations/domain';
import { t } from 'i18next';
import { FileCard } from './components/FileCard';

interface FilesRowProps {
  automation?: AutomationModel;
  isLoading?: boolean;
}
export const AutomationFilesRow = ({
  automation,
  isLoading,
}: FilesRowProps) => {
  return (
    <Container title={t('files')}>
      <span className="flex flex-row gap-2.5">
        <FileCard
          title={t('pdd')}
          files={automation?.pdds}
          automation={automation}
          isLoading={isLoading}
          category="1"
        ></FileCard>
        <FileCard
          title={t('sdd')}
          files={automation?.sdds}
          automation={automation}
          isLoading={isLoading}
          category="2"
        ></FileCard>
        <FileCard
          title={t('maintenance_manual')}
          files={automation?.manuals}
          automation={automation}
          isLoading={isLoading}
          category="3"
        ></FileCard>
        <FileCard
          title={t('support_docs')}
          files={automation?.supportDocuments}
          automation={automation}
          isLoading={isLoading}
          category="4"
        ></FileCard>
      </span>
    </Container>
  );
};
