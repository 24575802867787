import { workflowsRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { WorkflowKey } from './keys';

export const useWorkflowAutomationsPerformance = (
  workflowId: number,
  dateRange?: DateRange,
) =>
  useQuery({
    queryKey: WorkflowKey.AUTOMATIONS_PERFORMANCE(workflowId, dateRange),
    queryFn: () =>
      workflowsRepo.getAutomationsPerformanceById(workflowId, dateRange),
    cacheTime: 1000 * 60 * 5,
  });
