import { useOutsideClick } from '@/hooks';
import { MouseEvent, useCallback, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { DropdownArrowButton } from '../DropdownArrowButton/DropdownArrowButton';

type DropdownKey = string | number;

export type DropdownOption<TValue extends DropdownKey> = {
  label: string;
  value: TValue;
};

type DropdownArrowProps<TValue extends DropdownKey> = {
  placeholder: string;
  selectedValue?: TValue;
  options: DropdownOption<TValue>[];
  onSelect: (value: TValue) => void;
  className?: string;
};

export const DropdownArrow = <TValue extends DropdownKey>({
  selectedValue,
  options,
  placeholder,
  onSelect,
  className,
}: DropdownArrowProps<TValue>) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const onOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  useOutsideClick(ref, onOutsideClick, isOpen);

  const handleOnSelect = useCallback(
    (event: MouseEvent, value: TValue) => {
      event.stopPropagation();
      setIsOpen(false);
      onSelect(value);
    },
    [onSelect],
  );

  return (
    <DropdownArrowButton
      placeholder={placeholder}
      selectedLabel={
        options.find((option) => option.value === selectedValue)?.label
      }
      className={className}
    >
      {isOpen && (
        <ul className="absolute flex max-h-36 w-full flex-1 flex-col overflow-auto rounded-b-md bg-brightGray">
          {options.map((option) => (
            <li
              key={option.value}
              className={twMerge(
                'p-2 text-left text-spartanBlue transition-colors hover:bg-rinseGray active:bg-trappedDarkness active:text-white',
                option.value === selectedValue &&
                  'bg-trappedDarkness text-white',
              )}
              onClick={(event) => handleOnSelect(event, option.value)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </DropdownArrowButton>
  );
};
