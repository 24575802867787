import { Button, FileUploadIcon, TrashIcon, UserAvatar } from '@/components';
import { FILE_TYPES_ACCEPTED } from '@/utils';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

interface ProfileAvatarWithActionsProps {
  handleOnFileChange: (file: File) => void;
  handleOnFileRemove: () => void;
  avatarName?: string;
  avatarImageId?: number;
  avatarImageSrc?: string;
}

export const ProfileAvatarWithActions = ({
  handleOnFileChange,
  handleOnFileRemove,
  avatarName,
  avatarImageId,
  avatarImageSrc,
}: ProfileAvatarWithActionsProps): ReactElement => {
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  });

  const handleButtonUploadPictureClick = useCallback(
    () => fileInputRef.current?.click(),
    [],
  );

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];
      handleOnFileChange(file);
    }
  };

  const handleDeletePictureClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    handleOnFileRemove();
  }, [handleOnFileRemove]);

  return (
    <div className="flex items-center gap-5">
      <UserAvatar
        size="large"
        imageId={avatarImageId}
        imageSrc={avatarImageSrc}
        name={avatarName}
      />
      <Button
        className="flex h-6 justify-between gap-2 border-blueNuit p-3 font-black text-blueNuit"
        variant="outline"
        onClick={handleButtonUploadPictureClick}
      >
        <FileUploadIcon className="text-blueNuit" />
        {t('upload_profile_picture_short')}
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileChange}
          className="display-none"
          accept={FILE_TYPES_ACCEPTED}
        />
      </Button>
      <Button
        className="flex h-6 justify-between gap-2 border-black p-3 font-black text-black"
        variant="outline"
        onClick={handleDeletePictureClick}
        isDisabled={!avatarImageSrc && !avatarImageId}
      >
        <TrashIcon />
        {t('delete_profile_picture_short')}
      </Button>
    </div>
  );
};
