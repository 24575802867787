import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import { TableRow, TableSort } from '@/components/Table/types';
import { queryClient } from '@/constants';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useToggleCompanyStatus } from '../../hooks/useToggleCompanyStatus';
import { CompanyKeys, useDeleteCompany, useGetCompanies } from '../../queries';
import { CreateCompanyModal } from './components/CreateCompanyModal';
import { getCompaniesColumns, getCompaniesColumnsNamedMapped } from './utils';

export const CompaniesPage = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = () => {
    setIsOpen(!isOpen);
  };
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const navigate = useNavigate();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const { onChange: onCompanyStatusChange } = useToggleCompanyStatus();

  const getAllCompaniesOptions = useMemo(
    () => ({
      onError: () => {
        toast.error(t('error_getting_companies'));
      },
    }),
    [t],
  );

  const onSort = (data: TableSort[]) => {
    setTableSort(data);
  };

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  const { data: companies, isFetching: isLoadingCompanies } = useGetCompanies(
    page + 1,
    searchTerm,
    getCompaniesColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
    getAllCompaniesOptions,
  );

  const { mutate: deleteOrganization, isLoading: isLoadingDelete } =
    useDeleteCompany();

  const handleDelete = (organizationId: number) => {
    deleteOrganization(organizationId, {
      onError: () => {
        toast.error(t('error_deleting_company'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(CompanyKeys.ALL);
        toast.success(t('success_deleting_company'));
      },
    });
  };

  const routeChange = useCallback(
    (row?: TableRow) => {
      const path = `${row?.id}`;
      navigate(path);
    },
    [navigate],
  );

  const handleOnStatusChange = useCallback(
    (checked: boolean, row: TableRow) => {
      onCompanyStatusChange(checked, +row.id);
    },
    [onCompanyStatusChange],
  );

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('companies')} />
      <FilterSearchBar
        total={companies?.totalRows || 0}
        title={t('companies')}
        variant="secondary"
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
        otherActions={
          <Button
            onClick={handleModalState}
            variant="primary"
            className="px-5 py-2.5"
          >
            {t('add')}
          </Button>
        }
      />
      <Table
        columns={getCompaniesColumns({
          onClick: routeChange,
          disableDeleteMessage: t('delete_company'),
          onDeleteClick(row) {
            handleDelete(row?.id as number);
          },
          onStatusChange: handleOnStatusChange,
        })}
        rows={companies?.items ?? []}
        isLoading={isLoadingCompanies || isLoadingDelete}
        noResultsLabel={t('no_companies_available')}
        withPagination
        totalItems={companies?.totalRows}
        totalPages={companies?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
      {isOpen && (
        <CreateCompanyModal handleClose={handleModalState} isOpen={isOpen} />
      )}
    </CustomPageContainer>
  );
};
