import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { businessAreasRepo } from '@/constants/providers';
import { BusinessAreaPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { BusinessAreaModel } from '../domain';
import { BusinessAreasKey } from './keys';

export const useGetBusinessAreas = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    BusinessAreaPagination<BusinessAreaModel[]>,
    any,
    BusinessAreaPagination<BusinessAreaModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery<BusinessAreaPagination<BusinessAreaModel[]>>(
    [
      ...BusinessAreasKey.PAGED({ pageNumber, filter, sortBy, sortOrder }),
      currentSelectedTenant?.id,
    ],
    () =>
      businessAreasRepo.getAllBusinessAreas(
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    { ...options, cacheTime: 1000 * 60 * 5 },
  );
};
