import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { businessAreasRepo } from '@/constants/providers';
import { BusinessAreaModel } from '@/modules/BusinessAreas/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { BusinessAreasKey } from './keys';

export const useGetBusinessAreasByUser = (
  userId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<BusinessAreaModel[]>,
    any,
    DataWithPagination<BusinessAreaModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery<DataWithPagination<BusinessAreaModel[]>>(
    [
      ...BusinessAreasKey.BY_USER_PAGED(userId, {
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      }),
      currentSelectedTenant?.id,
    ],
    () =>
      businessAreasRepo.getBusinessAreasByUser(
        userId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
};
