import { Button } from '@/components';
import { PropsWithChildren } from 'react';
import { useDownloadFile } from '../../hooks/useDownloadFile';

type DownloadButtonProps = PropsWithChildren<{
  id: string;
  name: string;
}>;

export const DownloadButton = ({ id, name }: DownloadButtonProps) => {
  const { onFileSelect, isLoading, selectedFile } = useDownloadFile();
  return (
    <Button
      variant="text"
      className="p-0 font-black underline"
      onClick={() => onFileSelect({ id: id, name: name })}
      isDisabled={selectedFile && isLoading}
    >
      {name}
    </Button>
  );
};
