import {
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, BracketsIcon, SelectDropdownOption } from '..';
import { useTranslation } from 'react-i18next';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { twMerge } from 'tailwind-merge';
import { useOutsideClick } from '@/hooks';

interface DropdownProps {
  className?: string;
}

export function LanguageDropdown({ className }: DropdownProps): ReactElement {
  const { t, i18n } = useTranslation();

  const languageOptions = useMemo(
    () =>
      getDropdownArray(
        Object.keys(i18n.services.resourceStore.data).map((language) => ({
          id: language,
          name: t(`lang_${language}`),
        })),
      ),
    [i18n.services.resourceStore.data, t],
  );

  const buttonWrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useOutsideClick(buttonWrapperRef, handleOutsideClick, isOpen);

  const selectedOptionLabel = useMemo(() => {
    return languageOptions?.find((option) => option.value === i18n.language)
      ?.value;
  }, [i18n.language, languageOptions]);

  const handleSelectDropdownButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = useCallback(
    (option: SelectDropdownOption<string>) => (event: MouseEvent) => {
      document.cookie = `KEYCLOAK_LOCALE=${option.value};path=/realms/automation-sphere/`;
      i18n.changeLanguage(option.value);
      setIsOpen(false);

      event.preventDefault();
      event.stopPropagation();
    },
    [i18n],
  );

  return (
    <div
      ref={buttonWrapperRef}
      className={twMerge('relative mx-2 inline-block gap-2', className)}
    >
      <Button
        variant="text"
        onClick={handleSelectDropdownButtonClick}
        className="flex h-10 w-full justify-center gap-2 rounded-md p-0 outline-none"
      >
        <div className="size-10 content-center rounded-full bg-brightGray">
          <span className=" text-[15px] font-black uppercase text-blueNuit">
            {selectedOptionLabel}
          </span>
        </div>
        <div>
          <BracketsIcon className="size-5" />
        </div>
      </Button>
      <div
        className={twMerge(
          'rounded-t-none absolute right-0 z-50 block w-fit divide-y divide-white overflow-y-auto rounded-md border border-t-0 text-sm shadow',
          !isOpen && 'hidden',
        )}
      >
        <ul className="relative max-h-48 w-fit">
          {languageOptions.map((opt: SelectDropdownOption<string>) => {
            return (
              <li className="group flex" key={`${opt.value}`}>
                <Button
                  variant="text"
                  className="flex w-full flex-grow justify-start text-ellipsis whitespace-nowrap bg-white p-2 text-start text-primaryBlue outline-none hover:bg-spartanBlue hover:text-white focus:bg-spartanBlue focus:text-white group-last:rounded-b-md"
                  onClick={handleOptionClick(opt)}
                >
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {opt.label}
                  </span>
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
