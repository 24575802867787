import { PerformanceCardsContainer } from '@/components/PerformanceCardsContainer';
import { useBusinessAreaAutomationsPerformance } from '@/modules/BusinessAreas/queries/use-business-area-automations-perforrmance';
import { ReactElement, useState } from 'react';
import { DateRange } from 'react-day-picker';

interface BusinessAreaPerformanceCardsProps {
  businessAreaId: number;
}

export const BusinessAreaPerformanceCards = ({
  businessAreaId,
}: BusinessAreaPerformanceCardsProps): ReactElement => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const { data, isLoading } = useBusinessAreaAutomationsPerformance(
    businessAreaId,
    dateRange,
  );
  return (
    <PerformanceCardsContainer
      isLoading={isLoading}
      totalRuns={data?.totalExecutions || 0}
      predictedManualTime={data?.predictedManualTime || 0}
      automationRunTime={data?.automationRunTime || 0}
      totalInvestment={data?.totalInvestment || 0}
      totalReturn={data?.totalReturn || 0}
      dateRange={dateRange}
      setDateRange={setDateRange}
    />
  );
};
