import {
  BusinessAreasTab,
  CompaniesTab,
  CustomPageContainer,
  OrganizationsTab,
  ProcessesTab,
  TabItemProps,
  TableWithFilter,
  TasksTab,
  UserIcon,
} from '@/components';
import { LabelIcon } from '@/components/LabelIcon/LabelIcon';
import { PageDetailsActions } from '@/components/PageDetailsActions/PageDetailsActions';
import { TabContainer } from '@/components/TabContainer';
import { TabsWrapper } from '@/components/TabsWrapper';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router/route-paths';
import { useToggleUserAccess } from '@/hooks/use-toggle-user-access';
import { useToggleUserStatus } from '@/hooks/use-toggle-user-status';
import { BusinessAreaModel } from '@/modules/BusinessAreas/domain';
import { getBusinessAreaColumnsNamedMapped } from '@/modules/BusinessAreas/pages/BusinessAreasPage/utils';
import { useGetBusinessAreasByUser } from '@/modules/BusinessAreas/queries';
import { CompanyModel } from '@/modules/Companies/domain';
import { getCompaniesColumnsNamedMapped } from '@/modules/Companies/pages/CompaniesPage/utils';
import { useGetCompaniesByUser } from '@/modules/Companies/queries';
import { OrganizationModel } from '@/modules/Organizations/domain';
import { getOrganizationsColumnsNamedMapped } from '@/modules/Organizations/pages/OrganizationsPage/utils';
import { useGetOrganizationsByUser } from '@/modules/Organizations/queries';
import { getProcessesColumnsNamedMapped } from '@/modules/Processes';
import { ProcessModel } from '@/modules/Processes/domain';
import { useGetProcessesByUser } from '@/modules/Processes/queries';
import { TaskModel } from '@/modules/Tasks/domain';
import { getTasksColumnsNamedMapped } from '@/modules/Tasks/pages/TasksPage';
import { useGetTasksByUser } from '@/modules/Tasks/queries/get-tasks-by-user';
import { HttpStatusCode } from 'axios';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserRateModel } from '../../domain/user-rate-model';
import { useGetUser, useGetUserRates } from '../../queries';
import { UserHeaderRow } from './components/HeaderRow';
import { HourlyRatesTab } from './components/HourlyRatesTab';

export const UserDetailPage = (): ReactElement => {
  const { userId: userIdParam } = useParams<{ userId: string }>();
  const userId = +userIdParam!;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onChange: onStatusChange } = useToggleUserStatus();
  const { onChange: onAccessChange } = useToggleUserAccess();
  const { data: user, isError, error } = useGetUser(userId);

  useEffect(() => {
    if (isError && error) {
      if ((error as HttpError<unknown>)?.code === HttpStatusCode.NotFound) {
        navigate(RoutePath.notFound());
      } else {
        toast.error(t('error_get_user_details'));
      }
    }
  }, [isError, error, navigate, t]);

  const [status, setStatus] = useState<boolean>(false);

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!user?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      onStatusChange(checked, user.id);
    },
    [user, onStatusChange, t],
  );

  const handleAccess = useCallback(
    (checked: boolean) => {
      if (!user?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      onAccessChange(checked, user.id);
    },
    [user, onAccessChange, t],
  );

  const tabs: Array<TabItemProps> = useMemo(
    () => [
      { label: t('organizations'), badgeNumber: user?.organizationsCount },
      {
        label: t('companies'),
        badgeNumber: user?.companiesCount,
      },
      {
        label: t('business-areas'),
        badgeNumber: user?.businessAreasCount,
      },
      { label: t('processes'), badgeNumber: user?.processesCount },
      { label: t('tasks'), badgeNumber: user?.tasksCount },
      { label: t('hourly_rates') },
    ],
    [t, user],
  );

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const tabList = useMemo(() => {
    if (!userId) {
      return [];
    }
    return [
      {
        tab: (
          <TabContainer<OrganizationModel>
            parentId={userId}
            useGetData={useGetOrganizationsByUser}
            getColumnsNamedMapped={getOrganizationsColumnsNamedMapped}
          >
            <OrganizationsTab
              canAttachUser
              canCreate={false}
              userId={userId}
              context="userDetails"
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<CompanyModel>
            parentId={userId}
            useGetData={useGetCompaniesByUser}
            getColumnsNamedMapped={getCompaniesColumnsNamedMapped}
          >
            <CompaniesTab
              canAttachUser
              canCreate={false}
              userId={userId}
              context="userDetails"
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<BusinessAreaModel>
            parentId={userId}
            useGetData={useGetBusinessAreasByUser}
            getColumnsNamedMapped={getBusinessAreaColumnsNamedMapped}
          >
            <BusinessAreasTab
              canAttachUser
              canCreate={false}
              userId={userId}
              context="userDetails"
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<ProcessModel>
            parentId={userId}
            useGetData={useGetProcessesByUser}
            getColumnsNamedMapped={getProcessesColumnsNamedMapped}
          >
            <ProcessesTab
              canAttachUser
              canCreate={false}
              userId={userId}
              context="userDetails"
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<TaskModel>
            parentId={userId}
            useGetData={useGetTasksByUser}
            getColumnsNamedMapped={getTasksColumnsNamedMapped}
          >
            <TasksTab canCreate context="userDetails" />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<UserRateModel>
            parentId={userId}
            useGetData={useGetUserRates}
          >
            <HourlyRatesTab canCreate userId={userId} />
          </TabContainer>
        ),
      },
    ];
  }, [userId]);

  const breadcrumbs = useMemo(() => {
    if (!user) return [];
    return [
      { label: t('users'), href: RoutePath.users() },
      {
        label: `${user.firstName} ${user.lastName}`,
      },
    ];
  }, [user, t]);

  return (
    <CustomPageContainer>
      <PageDetailsActions onBack={routeChange} breadcrumbs={breadcrumbs}>
        <LabelIcon
          label={t('user')}
          icon={<UserIcon className="size-5 text-blueNuit" />}
        />
      </PageDetailsActions>
      <UserHeaderRow
        user={user}
        handleStatus={handleStatus}
        handleAccess={handleAccess}
        status={status}
      />
      <TableWithFilter>
        <TabsWrapper tabs={tabs} tabList={tabList} />
      </TableWithFilter>
    </CustomPageContainer>
  );
};
