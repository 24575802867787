import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { LabelCard } from '@/components/LabelCard/LabelCard';
import { PageDetailsHeaderRow } from '@/components/PageDetailsHeaderRow/PageDetailsHeaderRow';
import { StatusCard } from '@/components/StatusCard/StatusCard';
import { ProcessModel } from '@/modules/Processes/domain';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditProcessModal } from '../../../ProcessesPage/components';

interface HeaderRowProps {
  process?: ProcessModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
  isLoading: boolean;
}

export const ProcessHeaderRow = ({
  process,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <div>
      <PageDetailsHeaderRow
        isLoading={!process}
        title={process?.name ?? ''}
        description={
          <div className="text-sm">
            <span className="font-bold" data-testid="org-comp-section">
              {`${process?.organization}, ${process?.company}, `}
            </span>
            <span data-testid="ba-section">{process?.businessAreaName}</span>
          </div>
        }
        actions={
          <>
            <LabelCard label={t('owner')} value={process?.owner?.name} />
            <StatusCard status={status} handleStatus={handleStatus} />
          </>
        }
        onEditClick={handleModalState}
        details={
          <Container className="flex-row">
            <Card title={t('process_description')}>
              {<p data-testid="description-section">{process?.description}</p>}
            </Card>
            <Card title={t('code')}>{<p>{process?.code}</p>}</Card>
          </Container>
        }
      />
      {isOpen && process?.id && (
        <EditProcessModal
          isOpen={isOpen}
          id={process.id}
          handleClose={handleModalState}
        />
      )}
    </div>
  );
};
