import { useCreateUserRate } from '@/modules/Users/queries/create-user-rate';
import { UserKey } from '@/modules/Users/queries/types';
import { HourlyRateJson, UserRateSchemaType } from '@/modules/Users/types';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { HourlyRateModal } from '../HourlyRateModal';

interface CreateHourlyRateModalProps {
  isOpen: boolean;
  handleClose: () => void;
  userId?: number;
  minDate?: Date;
  jiraAccountId?: string;
}

export const CreateHourlyRateModal = ({
  isOpen,
  handleClose,
  userId,
  minDate,
  jiraAccountId,
}: CreateHourlyRateModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateUserRate();

  const handleConfirm = (data: UserRateSchemaType) => {
    const convertedData: HourlyRateJson = {
      userId: userId ?? 0,
      value: Number(data.rate),
      startDate: data.startDate,
      jiraAccountId: data.jiraAccountId,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_creating_hourly_rate'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(UserKey.ALL);
        handleClose();
        toast.success(t('success_creating_hourly_rate'));
      },
    });
  };

  return (
    <HourlyRateModal
      handleOnSubmit={handleConfirm}
      handleClose={handleClose}
      isLoading={isLoading}
      type="create"
      isOpen={isOpen}
      title={t('create_hourly_rate')}
      minDate={minDate}
      jiraAccountId={jiraAccountId}
    />
  );
};
