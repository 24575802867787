import { PerformanceCardsContainer } from '@/components/PerformanceCardsContainer';
import { useProcessAutomationsPerformance } from '@/modules/Processes/queries/use-process-automations-performance';
import { ReactElement, useState } from 'react';
import { DateRange } from 'react-day-picker';

interface ProcessPerformanceCardsProps {
  processId: number;
}

export const ProcessPerformanceCards = ({
  processId,
}: ProcessPerformanceCardsProps): ReactElement => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const { data, isLoading } = useProcessAutomationsPerformance(
    processId,
    dateRange,
  );

  return (
    <PerformanceCardsContainer
      predictedManualTime={data?.predictedManualTime || 0}
      automationRunTime={data?.automationRunTime || 0}
      totalInvestment={data?.totalInvestment || 0}
      totalReturn={data?.totalReturn || 0}
      isLoading={isLoading}
      totalRuns={data?.totalExecutions}
      dateRange={dateRange}
      setDateRange={setDateRange}
    />
  );
};
