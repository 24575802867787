import { TenantContext } from '@/components';
import { usersRepo } from '@/constants/providers';
import { QueryOptions } from '@/modules/types';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { UserProfilesModel } from '../domain/user-profiles-model';
import { UserKey } from './types';

export const useGetUserProfiles = (
  options?: QueryOptions<UserProfilesModel[]>,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery<UserProfilesModel[]>(
    [...UserKey.USERS_PROFILES, currentSelectedTenant?.id],
    () => usersRepo.getUserProfiles(),
    {
      ...options,
    },
  );
};
