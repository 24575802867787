import { ReactElement, ReactNode } from 'react';
import { SideNavItem, SideNavItemProps } from '..';

interface SideNavProps {
  header: ReactNode;
  items: SideNavItemProps[];
  footer?: ReactNode;
}

export const SideNav = ({
  items,
  footer,
  header,
}: SideNavProps): ReactElement => {
  return (
    <div>
      <div className="flex h-screen w-20 flex-col justify-between bg-primaryBlue text-white">
        <div className="flex flex-1 flex-col">
          <div className="flex h-28 items-center justify-center border-b-1 border-plumBlue">
            {header}
          </div>
          <ul className="mt-4 flex flex-1 flex-col bg-primaryBlue">
            {items.map((item: SideNavItemProps) => (
              <SideNavItem
                key={item.label}
                to={item.to}
                label={item.label}
                icon={item.icon}
              />
            ))}
          </ul>
        </div>
        {footer && (
          <div className="flex items-center justify-center border-t border-t-plumBlue bg-primaryBlue">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};
