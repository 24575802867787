import { Container } from '@/components';
import {
  calculateValueDifference,
  convertTimeSecondsToString,
  formatNumber,
  ValueDifference,
} from '@/utils';
import { ReactElement, useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { DateRangeDropdown } from '../DateRangeDropdown';
import { PerformanceCard, PerformanceContent } from '../PerformanceCard';

export interface PerformanceCardsContainerProps {
  isLoading: boolean;
  predictedManualTime?: number;
  automationRunTime?: number;
  totalInvestment?: number;
  totalReturn?: number;
  investmentReturnDifference?: ValueDifference;
  totalRuns?: number;
  dateRange?: DateRange;
  setDateRange: (dateRange?: DateRange) => void;
}

export const PerformanceCardsContainer = ({
  isLoading,
  predictedManualTime,
  automationRunTime,
  totalInvestment,
  totalReturn,
  investmentReturnDifference,
  totalRuns,
  dateRange,
  setDateRange,
}: PerformanceCardsContainerProps): ReactElement => {
  const { t } = useTranslation();
  const {
    performance: performanceTotalTime,
    timeDifference: timeDifferenceTotalTime,
    efficiency: timeEfficiency,
  } = calculateValueDifference(
    Math.round(predictedManualTime ?? 0),
    Math.round(automationRunTime ?? 0),
  );

  const {
    performance: performanceTotalReturn,
    timeDifference: timeDifferenceTotalReturn,
    efficiency: returnEfficiency,
  } = investmentReturnDifference ??
  calculateValueDifference(totalInvestment || 0, totalReturn || 0, 'coin');

  const timeContent: PerformanceContent[] = useMemo(
    () => [
      {
        title: convertTimeSecondsToString(Number(predictedManualTime) || 0),
      },
      {
        title: convertTimeSecondsToString(Number(automationRunTime || 0)),
        subtitle: t('automation_run_time'),
      },
      {
        title: formatNumber(totalRuns ?? 0, 0),
        subtitle: t('automation_runs'),
      },
      {
        title: `${timeEfficiency}%`,
        extraTitle: `(${timeDifferenceTotalTime})`,
        subtitle: t('time_efficiency'),
        performance: performanceTotalTime,
      },
    ],
    [
      automationRunTime,
      performanceTotalTime,
      predictedManualTime,
      t,
      timeDifferenceTotalTime,
      timeEfficiency,
      totalRuns,
    ],
  );

  const investmentContent: PerformanceContent[] = useMemo(
    () => [
      {
        title: `${formatNumber(totalReturn || 0)}€`,
      },
      {
        title: `${formatNumber(totalRuns ? (totalReturn ?? 0) / totalRuns : 0)}€`,
        subtitle: t('average_return_per_run'),
      },
      {
        title: `${formatNumber(totalInvestment || 0)}€`,
        subtitle: t('total_investment'),
      },
      {
        title: `${formatNumber((timeDifferenceTotalReturn as number) ?? 0)}€`,
        extraTitle: `${returnEfficiency}%`,
        subtitle: t('financial_outcome'),
        performance: performanceTotalReturn,
      },
    ],
    [
      performanceTotalReturn,
      returnEfficiency,
      t,
      timeDifferenceTotalReturn,
      totalInvestment,
      totalReturn,
      totalRuns,
    ],
  );

  return (
    <div className="flex flex-wrap gap-2">
      <Container
        title={t('auto_and_invest_performance')}
        className="flex-1"
        actions={
          <DateRangeDropdown
            range={dateRange}
            onChange={setDateRange}
            placement="bottom-end"
          />
        }
      >
        <div className="flex gap-3">
          <PerformanceCard
            key={t('labour_time_automated')}
            title={t('labour_time_automated')}
            content={timeContent}
            isLoading={isLoading}
          />
          <PerformanceCard
            key={t('total_return')}
            title={t('total_return')}
            content={investmentContent}
            isLoading={isLoading}
          />
        </div>
      </Container>
    </div>
  );
};
