import { TenantModel } from '@/modules/Tenants/domain';
import { createContext } from 'react';

export const TenantContext = createContext<{
  userTenants: TenantModel[];
  currentSelectedTenant?: TenantModel;
  isLoadingTenantInfo: boolean;
  isLoadingUserTenants: boolean;
  onTenantIdChange: (tenantId: number) => void;
  hasMultipleTenants: boolean;
}>({
  userTenants: [],
  isLoadingTenantInfo: true,
  isLoadingUserTenants: true,
  onTenantIdChange: () => {},
  hasMultipleTenants: false,
});
