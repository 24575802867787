import { DateUtility } from '@/utils/date.utils';
import { Placement } from '@floating-ui/react';
import { useCallback, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { Button } from '../Button';
import { Dropdown, TimeTag } from './components';
import { CUSTOM_DAYS_SELECT, TagType } from './constants';
import { useDatesPlaceholder } from './hooks';

type DateRangeDropdownProps = {
  label?: string;
  range?: DateRange;
  className?: string;
  onChange?: (range?: DateRange) => void;
  isDisabled?: boolean;
  placement?: Placement;
  containerClassName?: string;
};

const getTagFromDate = (type: TagType, days?: number) => {
  const currentDate = DateUtility.today();

  switch (type) {
    case TagType.YEAR_TO_DATE:
      return DateUtility.firstDayOfYear();
    case TagType.LAST_DAYS:
    default:
      return DateUtility.subtract(currentDate, {
        days: days || 7,
      });
  }
};

export const DateRangeDropdown = ({
  label,
  range,
  className,
  onChange,
  isDisabled,
  containerClassName,
  placement,
}: DateRangeDropdownProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<TagType>();
  const [appliedTag, setAppliedTag] = useState<TagType>();
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(
    range,
  );
  const datesPlaceholder = useDatesPlaceholder(selectedRange, selectedTag);
  const weeklyDays = t('date_picker.weekly_days', {
    returnObjects: true,
  }) as string[];

  const monthNames = t('date_picker.month_names', {
    returnObjects: true,
  }) as string[];

  const placeholder = t(datesPlaceholder.label, {
    ...datesPlaceholder.opt,
    interpolation: { escapeValue: false },
  });

  const handleApply = useCallback(() => {
    setIsOpen(false);
    onChange?.(selectedRange);
    setAppliedTag(selectedTag);
  }, [onChange, selectedRange, selectedTag]);

  const handleTagsSelect = useCallback((type: TagType, days?: number) => {
    const currentDate = DateUtility.today();

    const from = getTagFromDate(type, days);
    setSelectedTag(type);
    setSelectedRange({
      from: from,
      to: currentDate,
    });
  }, []);

  const handleDateChange = useCallback((dateRange: DateRange | undefined) => {
    setSelectedTag(undefined);
    setSelectedRange({
      from: dateRange?.from,
      to: dateRange?.to,
    });
  }, []);

  const onCancelClick = useCallback(() => {
    setSelectedRange(range);
    setSelectedTag(appliedTag);
    setIsOpen(false);
  }, [appliedTag, range]);

  return (
    <div
      className={twMerge(
        'flex items-center space-x-2 text-nowrap',
        containerClassName,
      )}
    >
      {!!label && (
        <div className="bg- border-white text-md font-semibold">{label}</div>
      )}
      <Dropdown
        isOpen={isOpen}
        placeholder={placeholder}
        hiddenClassName="border-t p-2 rounded-md"
        buttonClassMame={twMerge(
          'border-white focus:border-white active:border-white rounded-md',
        )}
        containerClassName={className}
        placement={placement}
        setIsOpen={setIsOpen}
        disabled={isDisabled}
      >
        <div className="flex w-min flex-col gap-2 p-1">
          <div className="flex w-fit flex-row flex-wrap gap-1">
            {CUSTOM_DAYS_SELECT.map((dateSelection) => (
              <TimeTag
                onClick={handleTagsSelect}
                key={`custom-days-${dateSelection.type}-${dateSelection.value}`}
                type={dateSelection.type}
                value={dateSelection.value}
              />
            ))}
          </div>
          <DayPicker
            modifiersClassNames={{
              today: 'selected:text-white font-black',
              selected: 'text-sm',
              range_middle: 'bg-lightBlue',
              range_start: 'bg-blueNuit rounded-l-full text-white',
              range_end: 'bg-blueNuit rounded-r-full text-white',
            }}
            mode="range"
            selected={selectedRange}
            onSelect={handleDateChange}
            ISOWeek
            showOutsideDays
            formatters={{
              formatCaption(date) {
                return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
              },
              formatWeekdayName: (weekday) => {
                return weeklyDays[weekday.getDay()];
              },
            }}
          />
          <div className="mr-2 flex flex-1 justify-end gap-1">
            <Button
              className="flex-1 capitalize"
              onClick={onCancelClick}
              variant="outline"
            >
              {t('cancel')}
            </Button>
            <Button
              className="flex-1 capitalize"
              onClick={handleApply}
              variant="primary"
            >
              {t('apply')}
            </Button>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
