import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { PageDetailsHeaderRow } from '@/components/PageDetailsHeaderRow/PageDetailsHeaderRow';
import { StatusCard } from '@/components/StatusCard/StatusCard';
import { InfrastructureCostModel } from '@/modules/Infrastructure/domain';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditInfrastructureCostModal } from '../../../InfrastructureCostPage/components/EditInfrastructureCostModal';

interface HeaderRowProps {
  infrastructureCost?: InfrastructureCostModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
  isLoading: boolean;
}

export const InfrastructureCostHeaderRow = ({
  infrastructureCost,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <div>
      <PageDetailsHeaderRow
        isLoading={!infrastructureCost}
        title={infrastructureCost?.name ?? ''}
        description={infrastructureCost?.costType}
        actions={<StatusCard status={status} handleStatus={handleStatus} />}
        onEditClick={handleModalState}
        details={
          <Container>
            <Card title={t('description')}>
              {infrastructureCost?.description}
            </Card>
          </Container>
        }
      >
        <Container className="flex-row flex-wrap">
          <Card title={t('recurrence')}>
            {t(infrastructureCost?.amountRecurrence ?? '')}
          </Card>
          <Card title={t('amount')}>
            {<p>{infrastructureCost?.amount.toFixed(2)} €</p>}
          </Card>
        </Container>
      </PageDetailsHeaderRow>
      {isOpen && !!infrastructureCost?.id && (
        <EditInfrastructureCostModal
          isOpen={isOpen}
          id={infrastructureCost.id}
          handleClose={handleModalState}
        />
      )}
    </div>
  );
};
