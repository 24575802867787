import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { usersRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { UserModel } from '../domain';
import { UserKey } from './types';

export const useGetUsers = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<UserModel[]>,
    any,
    DataWithPagination<UserModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery<DataWithPagination<UserModel[]>>(
    [
      ...UserKey.LIST_PAGED({
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      }),
      currentSelectedTenant?.id,
    ],
    () => usersRepo.getAllUsers(pageNumber, filter, sortBy, sortOrder),
    { ...options, cacheTime: 1000 * 60 * 5 },
  );
};

export const useGetTenantUsers = (
  data: {
    pageNumber?: number;
    filter?: string;
    sortBy?: string;
    sortOrder?: SortDirection;
    isAccountAdmin?: boolean;
  },
  options?: UseQueryOptions<
    DataWithPagination<UserModel[]>,
    any,
    DataWithPagination<UserModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery<DataWithPagination<UserModel[]>>(
    [
      ...UserKey.ADMIN_USERS_PAGED(
        {
          pageNumber: data.pageNumber,
          filter: data.filter,
          sortBy: data.sortBy,
          sortOrder: data.sortOrder,
        },
        data.isAccountAdmin,
      ),
      currentSelectedTenant?.id,
    ],
    () => usersRepo.getTenantUsers(data),
    options,
  );
};
