import { DEFAULT_PAGE_SIZE, SortDirection } from '@/components/Table/types';
import { HttpClient } from '@/core/http/http-client';
import { ApiResponse, DataWithPagination } from '@/shared/types';
import { DateRange } from 'react-day-picker';
import { WorkflowModel } from '../domain';
import {
  workflowAutomationsPerformanceMapper,
  workflowMapper,
  workflowsMapper,
  workflowsWithPaginationMapper,
} from '../mappers';
import {
  ApiResponseWorkflow,
  ApiResponseWorkflows,
  AssociateTaskToWorkflow,
  CreateWorkflowJson,
  DisassociateTaskFromWorkflow,
  OrderWorkflow,
  PagedWorkflows,
  WorkflowAutomationsPerformanceJson,
  WorkflowJson,
} from '../types';
import { WorkflowRepository } from './workflow.repository';

export class HttpWorkflowRepository extends WorkflowRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  async getWorkflows(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<WorkflowModel[]>> {
    const response = await this.client.get<
      ApiResponseWorkflows<PagedWorkflows>
    >({
      url: '/me/workflows',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });
    return workflowsWithPaginationMapper(response);
  }

  createWorkflow = async (data: CreateWorkflowJson): Promise<WorkflowModel> => {
    const newWorkflow = await this.client.post<WorkflowJson>({ body: data });
    return workflowMapper(newWorkflow);
  };

  getWorkflow = async (id: number): Promise<WorkflowModel> => {
    const workflow = await this.client.get<ApiResponseWorkflow<WorkflowJson>>({
      url: `${id}`,
    });

    return workflowMapper(workflow.data.workflow);
  };

  async getAutomationsPerformanceById(
    workflowId: number,
    dateRange?: DateRange,
  ) {
    const response = await this.client.get<
      ApiResponse<WorkflowAutomationsPerformanceJson>
    >({
      url: `${workflowId}/automations-performance`,
      query: {
        fromDate:
          dateRange?.from !== dateRange?.to
            ? dateRange?.from?.toISOString()
            : undefined,
        toDate: dateRange?.to?.toISOString(),
      },
    });

    return workflowAutomationsPerformanceMapper(response.data);
  }

  enableWorkflow = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/enable`,
    });
  };

  disableWorkflow = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/disable`,
    });
  };

  orderWorkflow = async (
    data: OrderWorkflow,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.patch(`${data.id}`, data);
  };

  deleteWorkflow = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.delete(`${id}`);
  };

  updateWorkflow = async (data: CreateWorkflowJson): Promise<unknown> => {
    return await this.client.put<unknown>(`${data.id}`, data);
  };

  async getWorkflowsByBusinessAreaId(
    businessAreaId: number,
    withProcess?: boolean,
  ): Promise<WorkflowModel[]> {
    const workflows = await this.client.get<
      ApiResponseWorkflows<WorkflowJson[]>
    >({
      url: `/business-areas/${businessAreaId}/workflows/base-info?withProcess=${withProcess}`,
    });

    return workflowsMapper(workflows.data.workflows);
  }

  async getWorkflowsByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<WorkflowModel[]>> {
    const workflows = await this.client.get<
      ApiResponseWorkflows<PagedWorkflows>
    >({
      url: `/business-areas/${businessAreaId}/workflows`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return workflowsWithPaginationMapper(workflows);
  }

  async getWorkflowsByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<WorkflowModel[]>> {
    const workflows = await this.client.get<
      ApiResponseWorkflows<PagedWorkflows>
    >({
      url: `/organizations/${organizationId}/workflows`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return workflowsWithPaginationMapper(workflows);
  }

  async getWorkflowsByProcessIdAutomations(
    processId: number,
  ): Promise<WorkflowModel[]> {
    const workflows = await this.client.get<
      ApiResponseWorkflows<WorkflowJson[]>
    >({
      url: `/processes/${processId}/workflows`,
    });

    return workflowsMapper(workflows.data.workflows);
  }

  async getWorkflowsByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<WorkflowModel[]>> {
    const workflows = await this.client.get<
      ApiResponseWorkflows<PagedWorkflows>
    >({
      url: `/companies/${companyId}/workflows`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return workflowsWithPaginationMapper(workflows);
  }

  associateTaskToWorkflow = async (
    data: AssociateTaskToWorkflow,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `associate-task`,
      body: data,
    });
  };

  disassociateTaskFromWorkflow = async (
    data: DisassociateTaskFromWorkflow,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `disassociate-task`,
      body: data,
    });
  };
}
