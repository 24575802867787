import { TenantContext } from '@/components';
import { businessAreasRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { BusinessAreaModel } from '../domain';
import { BusinessAreasKey } from './keys';

export const useGetBusinessArea = (businessAreaId: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery<BusinessAreaModel>(
    [...BusinessAreasKey.BY_ID(businessAreaId), currentSelectedTenant?.id],
    () => businessAreasRepo.getBusinessArea(businessAreaId),
  );
};
