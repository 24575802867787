import { ApiResponse, DataWithPagination } from '@/shared/types';
import { CompanyModel } from '../domain';

export abstract class CompanyRepository {
  abstract getCompanies(
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<CompanyModel[]>>;
  abstract updateCompany(data: FormData): Promise<CompanyModel>;
  abstract createCompany(data: FormData): Promise<CompanyModel>;
  abstract getCompany(id: number): Promise<CompanyModel>;
  abstract enableCompany(id: number): Promise<ApiResponse<unknown>>;
  abstract disableCompany(id: number): Promise<ApiResponse<unknown>>;
  abstract getCompaniesByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<CompanyModel[]>>;
  abstract getAllCompaniesByOrganization(
    organizationId: number,
  ): Promise<CompanyModel[]>;
  abstract getEligibleCompaniesByInfrastructureCost(
    infrastructureCostId: number,
  ): Promise<CompanyModel[]>;
  abstract getCompaniesByUser(
    userId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<CompanyModel[]>>;
  abstract getCompaniesByInfrastructureCost(
    infrastructureCostId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<CompanyModel[]>>;
  abstract addRatesFile(data: FormData): Promise<unknown>;
  abstract deleteCompany(Id: number): Promise<ApiResponse<unknown>>;
}
